import React from 'react';

export enum MainContainerInputsState {
    AVAILABLE = 'available',
    BLOCKED = 'blocked',
    HIDE = 'hide',
}

export interface MainContainerProps {
    Name?: string;
    Visible?: boolean;
    Top: React.ReactNode[];
    Main: React.ReactNode[];
    Bottom: React.ReactNode[];
}

/** Bar is container that provider a simplified way to positioning other elements */
export class MainContainer extends React.Component<MainContainerProps, MainContainerProps> {
    override componentDidUpdate(prevProps: Readonly<MainContainerProps>) {
        if (prevProps !== this.props) this.setState({ ...prevProps, ...this.props });
    }

    constructor(props: MainContainerProps) {
        super(props);
        this.state = {
            Name: props.Name || '',
            Visible: props.Visible || false,
            Top: props.Top || [],
            Main: props.Main || [],
            Bottom: props.Bottom || [],
        };
    }

    override render() {
        const { Top = [], Main = [], Bottom = [] } = this.state;
        return (
            <div className={`mainContainer ${this.state.Name} ${this.state.Visible ? 'visible' : 'hide'} `}>
                <div className="top">
                    {Top.map((component, index) => (
                        <React.Fragment key={`top-${index}`} children={component} />
                    ))}
                </div>
                <div className="main">
                    {Main.map((component, index) => (
                        <React.Fragment key={`main-${index}`} children={component} />
                    ))}
                </div>
                <div className="bottom">
                    {Bottom.map((component, index) => (
                        <React.Fragment key={`bottom-${index}`} children={component} />
                    ))}
                </div>
            </div>
        );
    }
}
