import * as enums from '@Application/entities/enums'
import { AbstractElement } from '@Entities/abstractions'
import { ISpeakableElement } from '@Entities/interfaces'
import { Expose } from 'class-transformer'
import type * as types from '@Entities/interfaces'

export default class ButtonElement extends AbstractElement implements ISpeakableElement, types.ILinkElement {
    @Expose()
    name: string = ''

    @Expose()
    isPlayableEntity: boolean = true

    @Expose()
    addPause: boolean = false

    @Expose()
    override id: string = ''

    @Expose()
    override mute: boolean = true

    @Expose()
    speech: string = ''

    @Expose()
    override state: enums.EPlayableState = enums.EPlayableState.PENDING

    @Expose()
    duration: number = 0

    @Expose()
    label: string = ''

    @Expose()
    icon: string = ''

    @Expose()
    iconURL: string = ''

    @Expose()
    event: string = ''

    @Expose()
    elementType: enums.EElementType = enums.EElementType.BUTTON

    @Expose()
    type: string = enums.EElementType.BUTTON

    @Expose()
    href: string = ''

    @Expose()
    target: string = ''
}
