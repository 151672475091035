import { EButtonState, Icon, IconVector } from '@Components';
import React, { MouseEventHandler, PropsWithRef, RefObject } from 'react';

export interface IconButtonProps {
    Name?: string;
    Ref?: React.RefObject<HTMLButtonElement>;
    State?: EButtonState;
    IconVector: IconVector;
    OnClick?: MouseEventHandler<HTMLButtonElement>;
}

export default class IconButton extends React.Component<
    PropsWithRef<IconButtonProps>,
    {
        PreviousState: EButtonState;
        State: EButtonState;
        HtmlElement: RefObject<HTMLButtonElement>;
    }
> {
    constructor(props: IconButtonProps) {
        super(props);
        this.state = {
            State: this.props.State || EButtonState.DEFAULT,
            PreviousState: this.props.State || EButtonState.DEFAULT,
            HtmlElement: React.createRef(),
        };
    }

    override componentDidUpdate(prevProps: Readonly<IconButtonProps>) {
        if (this.props !== prevProps && this.props.State && this.props.State !== prevProps.State) {
            this.setState({
                PreviousState: this.props.State,
                State: this.props.State,
            });
        }
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            PreviousState: this.state.State,
            State: EButtonState.HOVER,
        });
    };
    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            State: this.state.PreviousState,
        });
    };

    override render() {
        return (
            <button
                ref={this.state.HtmlElement}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
                className={`iconButton ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={
                    this.state.State === EButtonState.BLOCKED
                        ? () => console.info('Button is blocked')
                        : this.props.OnClick
                }
            >
                <div className="container marginTop">
                    <div className="asset">
                        <Icon vector={this.props.IconVector} />
                    </div>
                </div>
            </button>
        );
    }
}
