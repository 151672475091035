import type { Key } from 'react'
import { Subject } from 'rxjs'
import type { MediaElement, Message } from '@Entities'
import type { Subscription } from 'rxjs'

export default class HistoryManager {
    private messages: Message[] = []
    private messageStartObservable = new Subject<Message>()
    private messageStopObservable = new Subject<Message>()

    public static getInstance(): HistoryManager {
        if (!HistoryManager.instance) HistoryManager.instance = new HistoryManager()
        return HistoryManager.instance
    }

    public getLastGroupIdByLastMessage(): string | undefined {
        return this.getLastNoIgnoreMessage()?.getGroupId()
    }

    public getLastMessage(): Message | null {
        return this.messages[this.messages.length - 1] ?? null
    }

    public getLastNoIgnoreMessage(index?: number): Message | null {
        const messageIndex = index ?? this.messages.length - 1
        const message = this.messages[messageIndex]

        if (!message) {
            return null
        }

        if (!message.id ?? message.element?.noUserActionDelay) {
            return this.getLastNoIgnoreMessage(messageIndex - 1)
        }

        return message
    }

    private static instance: HistoryManager

    onMessageStart(callback: (message: Message) => void): Subscription {
        return this.messageStartObservable.subscribe(callback)
    }

    onMessageStop(callback: (message: Message) => void): Subscription {
        return this.messageStopObservable.subscribe(callback)
    }

    public pushMessage(message: Message): void {
        this.messages.push(message)
    }

    public stopMessage(): void {
        if (!this.messages || this.messages.length === 0) return
        this.messageStopObservable.next(this.getLastMessage()!)
    }

    public startMessage(): void {
        if (!this.messages || this.messages.length === 0) return
        this.messageStartObservable.next(this.getLastMessage()!)
    }

    public allMessagesByGroupId(groupId: string): Message[] {
        return this.messages.filter((message) => message.getGroupId() === groupId)
    }

    // If has button or incrementer has extracontent
    public hasExtraContentByGroupId(groupId: string): boolean {
        const messages = this.allMessagesByGroupId(groupId)
        return messages.some(
            (message) =>
                message.element &&
                (message.element.type === 'button' ||
                    message.element.type === 'incrementer' ||
                    message.element.type === 'media' ||
                    message.element.type === 'humanizer'),
        )
    }

    public isLastMedia(source: string): boolean {
        const groupId = this.getLastGroupIdByLastMessage()
        const messages = this.allMessagesByGroupId(groupId!)
        const mediaMessages = messages.filter((message) => message.element && message.element.type === 'media')
        const lastMedia = mediaMessages[mediaMessages.length - 1]?.element as MediaElement
        return lastMedia?.media.source === source
    }

    public hasMediaContentByLastGroupId(): boolean {
        const groupId = this.getLastGroupIdByLastMessage()
        const messages = this.allMessagesByGroupId(groupId!)
        return messages.some((message) => message.element && message.element.type === 'media')
    }

    public removeMessageById(id: string): void {
        this.messages = this.messages.filter((message) => message.id !== id)
    }

    public hasExtraContentByLastGroupId(): boolean {
        const groupId = this.getLastGroupIdByLastMessage()
        return this.hasExtraContentByGroupId(groupId!)
    }

    // Remove all messages by groupIds
    public removeMessagesByGroupId(groupId: Key[]): void {
        this.messages = this.messages.filter((message) => !groupId.includes(message.getGroupId()))
    }
}
