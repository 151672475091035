import { useState } from 'react';
import { IMediaElement } from '@Entities';
import * as enums from '@Application/entities/enums';
import { InterfaceManager } from '@Services';
import { MediaGallery } from '@Components';

function MediaGalleryController() {
    const [currentImage, setCurrentImage] = useState<IMediaElement | null>(null);
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState<number>(-1);
    const interfaceManager = InterfaceManager.getInstance();
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [currentState, setCurrentState] = useState<enums.EDigitalHumanState>(interfaceManager.DigitalHumanState[1]!);
    interfaceManager.handleDigitalHumanState((state) => {
        setCurrentState(state.current);
    });
    interfaceManager.onGalleryChange((gallery) => {
        const { open, media, currentIndex } = gallery;
        if (open !== galleryOpen) {
            setGalleryOpen(open);
        }
        if (currentGalleryIndex !== currentIndex) {
            setCurrentGalleryIndex(currentIndex);
            setCurrentImage(media[currentIndex]!);
        }
    });

    if (currentGalleryIndex === -1 || !currentImage) return null;
    return <MediaGallery currentImage={currentImage} mode={currentState} controlsEnabled={galleryOpen} />;
}

export default MediaGalleryController;
