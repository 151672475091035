import React from 'react'
import * as enums from '@Application/entities/enums'
import { Label, Highlight } from '@Components'
import MediaPreview from './MediaPreview'
import { IMediaElement } from '@Application/entities'

export enum EBubbleState {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface BubbleProps {
    Label: string
    State: EBubbleState
    key?: string
    Actor: enums.EActor
    id?: number
    Highlighted?: boolean
    Media?: IMediaElement
    Persist?: boolean
}

export class Bubble extends React.Component<
    BubbleProps,
    {
        State: EBubbleState
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>
    }
> {
    constructor(props: BubbleProps) {
        super(props)
        this.state = { State: this.props.State, Highlighted: this.props.Highlighted || false, Ref: React.createRef() }
    }

    override componentDidUpdate(prevProps: BubbleProps) {
        if (prevProps !== this.props) {
            this.setState({ ...this.state, ...this.props })
        }
    }

    override render(): React.ReactNode {
        if (this.props.State === EBubbleState.DISABLED) return null
        // MediaBubble
        if (this.props.Media) {
            // MediaBubble with Icon
            if (this.props.Media.type === enums.EMediaType.ICON) {
                return (
                    <div className={`media bubble digitalHuman ${this.props.Persist ? ' persist' : ''}`}>
                        <MediaPreview Media={this.props.Media} Highlighted={this.state.Highlighted} />
                        <div ref={this.state.Ref} className={`bubble digitalHuman`}>
                            <Label Text={this.props.Label} />
                        </div>
                        {/* {this.props.Label && (
                            <Highlight
                                Highlighted={this.state.Highlighted}
                                Name="box"
                                Child={
                                    <div ref={this.state.Ref} className={`bubble digitalHuman`}>
                                        <Label Text={this.props.Label} />
                                    </div>
                                }
                            />
                        )} */}
                    </div>
                )
            }
            // MediaBubble with Image/Video
            else
                return (
                    // <Highlight
                    //     Highlighted={this.state.Highlighted}
                    //     Name={`box`}
                    //     Persist={this.props.Persist}
                    //     Child={
                    //         <div ref={this.state.Ref} className={`media bubble digitalHuman`}>
                    //             <MediaPreview Media={this.props.Media} id={this.props.id} />
                    //             {this.props.Label && (
                    //                 <div className={`bubble digitalHuman`}>
                    //                     <Label Text={this.props.Label} />
                    //                 </div>
                    //             )}
                    //         </div>
                    //     }
                    // />
                    <div ref={this.state.Ref} className={`media bubble digitalHuman`}>
                        <MediaPreview Media={this.props.Media} id={this.props.id} />
                        {this.props.Label && (
                            <div className={`bubble digitalHuman`}>
                                <Label Text={this.props.Label} />
                            </div>
                        )}
                    </div>
                )
            // Add undefined handler?
        }

        // Regular Bubble
        return (
            this.props.Label && (
                <Highlight
                    Highlighted={this.state.Highlighted}
                    Name="box"
                    Child={
                        <div ref={this.state.Ref} className={`bubble ${this.props.Actor}`}>
                            <Label Text={this.props.Label} />
                        </div>
                    }
                />
            )
        )
    }
}
