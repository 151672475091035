import React from 'react';
import { IconButton, IconVector, Label } from '@Components';

export interface InputSliderItem {
    Label: string;
    Value: number;
    IsActive?: boolean;
}

export interface InputSliderProps {
    Items: InputSliderItem[];
    Name?: string;
    Step?: number;
    OnChangeHandler?: (activeItem: InputSliderItem, e: MouseEvent) => void;
    OnClickPrevHandler?: (activeItem: InputSliderItem, e: MouseEvent) => void;
    OnClickNextHandler?: (activeItem: InputSliderItem, e: MouseEvent) => void;
}

export interface InputSliderStates {
    ActiveIndex: number;
    ActiveItem: InputSliderItem;
    Items: InputSliderItem[];
}

export class InputSlider extends React.Component<InputSliderProps, InputSliderStates> {
    MinIndex: number;
    MaxIndex: number;

    constructor(props) {
        super(props);

        const activeIndex = this.props.Items.findIndex((row) => row.IsActive === true) || 0;

        this.MinIndex = 0;
        this.MaxIndex = this.props.Items.length - 1;

        this.state = {
            ActiveIndex: activeIndex,
            ActiveItem: this.props.Items[activeIndex]!,
            Items: this.props.Items,
        };
    }

    onChangeHandler = (e) => {
        const index = this.props.Items.findIndex((row) => row.Value.toString() === e.target.value);
        const active = this.props.Items[index];

        this.setState({
            ActiveIndex: index,
            ActiveItem: active!,
        });

        this.props.OnChangeHandler && this.props.OnChangeHandler(active!, e);
    };

    onClickPrevHandler = (e) => {
        const prevIndex = this.state.ActiveIndex - 1;

        if (prevIndex >= this.MinIndex) {
            const prevItem = this.props.Items[prevIndex];

            this.setState({
                ActiveIndex: prevIndex,
                ActiveItem: prevItem!,
            });

            this.props.OnClickPrevHandler && this.props.OnClickPrevHandler(prevItem!, e);
        }
    };

    onClickNextHandler = (e) => {
        const nextIndex = this.state.ActiveIndex + 1;

        if (nextIndex <= this.MaxIndex) {
            const nextItem = this.props.Items[nextIndex];

            this.setState({
                ActiveIndex: nextIndex,
                ActiveItem: nextItem!,
            });

            this.props.OnClickNextHandler && this.props.OnClickNextHandler(nextItem!, e);
        }
    };

    override render() {
        const step = this.props.Step ? +this.props.Step : 1;
        const minValue = this.props.Items[this.MinIndex]!.Value;
        const maxValue = this.props.Items[this.MaxIndex]!.Value;

        return (
            <div className={`input-slider-component`}>
                <input
                    type="range"
                    className={`input-slider-element ${this.props.Name ? this.props.Name : ''}`}
                    min={minValue}
                    max={maxValue}
                    step={step}
                    value={this.state.ActiveItem?.Value}
                    onChange={this.onChangeHandler}
                />
                <div className={`input-slider-dots`}>
                    {this.props.Items?.map((_item, index) => <div key={index} className={`input-slider-dot`} />)}
                </div>
                <div className={`input-slider-legend`}>
                    <div className={`input-slider-legend-item input-slider-legend-start`}>
                        <IconButton
                            IconVector={IconVector.CIRCLE_MINUS}
                            Name="InputsliderControlBtn"
                            OnClick={this.onClickPrevHandler}
                        />
                    </div>
                    <div className={`input-slider-legend-item input-slider-legend-middle`}>
                        <Label Text={this.state.ActiveItem?.Label} />
                    </div>
                    <div className={`input-slider-legend-item input-slider-legend-end`}>
                        <IconButton
                            IconVector={IconVector.CIRCLE_PLUS}
                            Name="InputsliderControlBtn"
                            OnClick={this.onClickNextHandler}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
