import { Label, EButtonState } from '@Components';
import * as enums from '@Application/entities/enums';
import React from 'react';

export interface RadioButtonProps {
    State: EButtonState;
    Label?: enums.ETranslation | string;
    Checked: boolean;
    OnClick?: () => void;
    Name?: string;
}

export default class RadioButton extends React.Component<RadioButtonProps, {}> {
    override state = {
        checked: this.props.Checked,
    };

    override componentDidUpdate(prevProps: Readonly<RadioButtonProps>): void {
        if (prevProps.Checked !== this.props.Checked) {
            this.setState({
                ...this.state,
                checked: this.props.Checked,
            });
        }
    }

    override render() {
        return (
            <div
                className={`radioButton ${this.props.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={() => {
                    this.props.OnClick && this.props.OnClick();
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    });
                }}
            >
                <div className="container">
                    <Label Text={this.props.Label} />
                    <div className="content">
                        <input type="radio" onChange={() => {}} checked={this.state.checked || false} />
                        <div className="radiobox">
                            <div className="radiomark" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
