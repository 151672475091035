import * as enums from '@Application/entities/enums'
import { AbstractElement } from '@Entities/abstractions'
import { Expose } from 'class-transformer'
import type * as types from '@Entities/interfaces'

export default class TextElement extends AbstractElement implements types.ISpeakableElement {
    @Expose()
    name: string = ''

    @Expose()
    elementType: enums.EElementType = enums.EElementType.TEXT

    @Expose()
    type: enums.EElementType | string = enums.EElementType.TEXT

    @Expose()
    label: string = ''

    @Expose()
    speech: string = ''

    @Expose()
    extraContent: string = ''

    @Expose()
    chat: string = ''

    @Expose()
    override id: string = ''

    @Expose()
    override mute: boolean = false

    @Expose()
    override isFirst: boolean = false

    @Expose()
    override isLast: boolean = false

    @Expose()
    override state: enums.EPlayableState = enums.EPlayableState.PENDING
}
