import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarReadyObserver extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Ready to Start Observer - executed')

    error = (err: Error): void => console.error('Avatar Ready to Start Observer', err)

    next(): void {
        this.services.UneeqManager.loadingPercentage = 25
    }
}
