import { useState, useRef } from 'react';
import type * as types from '@Entities/interfaces';
import {
    EButtonState,
    Caption,
    CaptionState,
    Carousel,
    Dropdown,
    DropdownAlignment,
    DropdownState,
    IconButton,
    IconVector,
    InterfaceSize,
    Label,
    LabelButton,
    LanguageGroup,
    Bar,
} from '@Components';
import I18n from '@Translation';
import * as enums from '@Application/entities/enums';

export enum DropdownTemplate {
    HOME = 'home',
    SETTINGS = 'settings',
    SETTINGS_NO_BAR = 'settingsNoBar',
    INTERFACE_SIZE = 'interfaceSize',
    INTERFACE_SIZE_NO_BAR = 'interfaceSizeNoBar',
    CAPTION_STYLE = 'captionStyle',
    PRIVACY_POLICY = 'privacyPlicy',
    LANGUAGE_SELECTION = 'languageGroup',
}
export interface DrodownControllerProps {
    Template: DropdownTemplate;
    Icon: IconVector;
    Services: types.IServices;
    Alignment?: DropdownAlignment;
    IconName?: string;
    Name?: string;
}

enum Languages {
    'en' = 'English',
    'de' = 'Deutsch',
    'fr' = 'Français',
    'it' = 'Italiano',
}

function DropdownController(props: DrodownControllerProps) {
    const [dropdownContentActive, setDropdownContentActive] = useState(props.Template);
    const Services = props.Services;
    const dropdownRef: any = useRef();
    const handleLanguageChange = (Language: string) => {
        I18n.changeLanguage(Language);
        dropdownRef.current.changeState(DropdownState.CLOSE);
    };

    const config = Services.ConfigManager.config;
    const page = config.modules.dialogFlow?.page;
    const translations = config.modules.i18n.translations;
    const langs = Object.keys(translations);
    const languages: { Label: string; IsActive: boolean; Icon: IconVector | undefined; OnClick: () => void }[] = [];
    const langIconsEnabled = config.components.dropdown?.languageSelection?.icons?.enabled;
    for (let language in langs) {
        let current = langs[language];
        languages.push({
            Label: Languages[current as keyof typeof Languages],
            IsActive: I18n.language === current,
            Icon: langIconsEnabled ? IconVector[('flag_' + current).toUpperCase()] : undefined,
            OnClick: () => handleLanguageChange(current!),
        });
    }

    const dropdownItems: any = [];

    const hamburgerOptions = config.components?.dropdown?.hamburger?.options;
    if (hamburgerOptions)
        for (const item of hamburgerOptions) {
            dropdownItems.push({
                Label: enums.ETranslation[item.label] || item.label,
                Icon: IconVector[item.icon.toUpperCase()],
                OnClick: (_e) => {
                    props.Services.BackendManager.handleUserMessage({
                        messageType: enums.EMessageType.EVENT,
                        eventName: item.event,
                        page: page,
                        sessionId: props.Services.UneeqManager.getSessionId() as string,
                        actor: 'simulated',
                    });
                    dropdownRef.current.changeState(DropdownState.CLOSE);
                },
            });
        }

    config.components.dropdown?.settings?.enabled &&
        dropdownItems.push({
            Icon: IconVector.GEAR,
            Label: enums.ETranslation.DROPDOWN_CONTROLLER_SETTINGS,
            OnClick: (_e) => setDropdownContentActive(DropdownTemplate.SETTINGS),
        });

    const settingsContent: any = [];
    if (config.components?.dropdown?.settings?.enabled) {
        for (let item of config.components.dropdown.settings.content) {
            settingsContent.push({
                Icon: IconVector[item.icon.toUpperCase()],
                Label: enums.ETranslation[item.label] || item.label,
                OnClick: (_e) => setDropdownContentActive(DropdownTemplate[item.event.toUpperCase()]),
            });
        }
    }
    return (
        <Dropdown
            Icon={props.Icon}
            IconName={props.IconName}
            Alignment={props.Alignment}
            Name={props.Name}
            OnClickBeforeOpen={(_) => setDropdownContentActive(props.Template)}
            ref={dropdownRef}
        >
            {dropdownContentActive === DropdownTemplate.HOME && (
                <>
                    <LanguageGroup key="HomeGroupText" Languages={dropdownItems} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.SETTINGS && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(_) => setDropdownContentActive(DropdownTemplate.HOME)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={enums.ETranslation.DROPDOWN_CONTROLLER_SETTINGS} Name="topbar-label" />,
                        ]}
                    />
                    <LanguageGroup key="LanguageGroupText" Languages={settingsContent} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.SETTINGS_NO_BAR && (
                <>
                    <LanguageGroup key="LanguageGroupText" Languages={settingsContent} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.LANGUAGE_SELECTION && (
                <>
                    <LanguageGroup key="LanguageGroupFlags" Languages={languages} className="flags" />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(_) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={enums.ETranslation.DROPDOWN_CONTROLLER_INTERFACE_SIZE} Name="topbar-label" />,
                        ]}
                    />
                    <InterfaceSize SettingManager={Services.SettingManager} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE_NO_BAR && (
                <>
                    <InterfaceSize SettingManager={Services.SettingManager} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.CAPTION_STYLE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(_) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={enums.ETranslation.DROPDOWN_CONTROLLER_CAPTION_STYLE} Name="topbar-label" />,
                        ]}
                    />
                    <Carousel
                        onSlideChange={(slideActive) => (Services.SettingManager.captionStyle = slideActive.value)}
                        slides={[
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.DISABLED}
                                        State={CaptionState.OFF}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.DISABLED,
                                isActive: Services.SettingManager.captionStyle === CaptionState.DISABLED,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.TRANSPARENT}
                                        State={CaptionState.TRANSPARENT}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.TRANSPARENT,
                                isActive: Services.SettingManager.captionStyle === CaptionState.TRANSPARENT,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.SOLID}
                                        State={CaptionState.SOLID}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.SOLID,
                                isActive: Services.SettingManager.captionStyle === CaptionState.SOLID,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.ACCESSIBILITY}
                                        State={CaptionState.ACCESSIBILITY}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.ACCESSIBILITY,
                                isActive: Services.SettingManager.captionStyle === CaptionState.ACCESSIBILITY,
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.PRIVACY_POLICY && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(_) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={enums.ETranslation.DROPDOWN_CONTROLLER_PRIVACY_POLICY} Name="topbar-label" />,
                        ]}
                    />
                    <div className={`dropdown-content`}>
                        <LabelButton
                            Label={enums.ETranslation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_WITHDRAW}
                            State={EButtonState.DEFAULT}
                            Name="editorial"
                            OnClick={(_) => window.location.reload()}
                        />
                        <Label
                            Text={enums.ETranslation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_SUBTEXT}
                            Name="privacyPolicySubtext"
                        />
                    </div>
                </>
            )}
        </Dropdown>
    );
}

export default DropdownController;
