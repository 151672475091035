import * as enums from '@Application/entities/enums'
import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarSessionLive extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Session Live Observer - executed')

    error = (err: Error): void => console.error('Avatar Session Live Observer', err)

    next(): void {
        this.services.UneeqManager.loadingPercentage = 100

        if (this.services.ConfigManager.config.content.WelcomeEvent)
            this.services.BackendManager.handleUserMessage({
                messageType: enums.EMessageType.EVENT,
                eventName: this.services.ConfigManager.config.content.WelcomeEvent,
                sessionId: this.services.UneeqManager.getSessionId() as string,
            })
        // To allow the animation to play
        setTimeout(() => {
            this.services.InterfaceManager.Stage = enums.EStage.DIGITAL_HUMAN
        }, 1000)
    }
}
