import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarSessionEndedObserver extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Session Ended Observer - executed')

    error = (err: Error): void => console.error('Avatar Session Ended Observer', err)

    next(_value: unknown): void {
        window.location.reload()
    }
}
