import { AudioRecordManager } from '@Application/services'
import { Subject } from 'rxjs'
import type { Subscription } from 'rxjs'

export default class AudioMonitorManager {
    audioContext?: AudioContext
    outputAnalyzer?: AnalyserNode
    inputAnalyzer?: AnalyserNode
    outputStream?: MediaStream
    outputSource?: MediaStreamAudioSourceNode
    outputElement?: HTMLAudioElement
    inputSource?: MediaStreamAudioSourceNode
    outputBufferLength: number = 0
    outputDataArray: Uint8Array = new Uint8Array(0)
    inputDataArray: Uint8Array = new Uint8Array(0)
    audioRecordManager = AudioRecordManager.getInstance()

    private outputAudioLevelObservable = new Subject<number>()
    private inputAudioLevelObservable = new Subject<number>()

    static getInstance(): AudioMonitorManager {
        if (!AudioMonitorManager.instance) AudioMonitorManager.instance = new AudioMonitorManager()

        return AudioMonitorManager.instance
    }

    private static instance: AudioMonitorManager

    static startInputMonitor(): void {
        const monitor = AudioMonitorManager.getInstance()

        if (!(monitor.audioContext && monitor.inputAnalyzer && monitor.inputDataArray)) {
            console.error(
                `Failed to start input monitor: ${monitor.audioContext}, ${monitor.inputAnalyzer}, ${monitor.inputDataArray}`,
            )

            return
        }

        monitor.processMonitorData(
            monitor.inputAnalyzer,
            monitor.inputDataArray,
            monitor.inputAudioLevelObservable,
            AudioMonitorManager.startInputMonitor,
        )
    }

    static startOutputMonitor(): void {
        const monitor = AudioMonitorManager.getInstance()

        if (!(monitor.audioContext && monitor.outputAnalyzer && monitor.outputDataArray)) {
            console.error(
                `Failed to start output monitor: ${monitor.audioContext}, ${monitor.outputAnalyzer}, ${monitor.outputDataArray}`,
            )

            return
        }

        monitor.processMonitorData(
            monitor.outputAnalyzer,
            monitor.outputDataArray,
            monitor.outputAudioLevelObservable,
            AudioMonitorManager.startOutputMonitor,
        )
    }

    start(): AudioMonitorManager {
        // @ts-ignore
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)()

        if (!this.audioContext) {
            console.error('Failed to create audio context')
        }

        return this
    }

    createOutputSource(videoElement: HTMLVideoElement): void {
        if (!this.audioContext) {
            console.error('Failed to create audio context or output analyzer')

            return
        }

        // @ts-ignore
        if (!(videoElement && videoElement.captureStream)) {
            console.error('Video element not found or does not support capturing audio')

            return
        }

        this.outputAnalyzer = this.audioContext.createAnalyser()
        // @ts-ignore
        this.outputStream = videoElement.captureStream()

        if (!this.outputStream) {
            console.error('Failed to capture audio stream')

            return
        }

        this.outputSource = this.audioContext.createMediaStreamSource(this.outputStream)
        this.outputSource.connect(this.outputAnalyzer)
        this.outputAnalyzer.fftSize = 1024
        this.outputBufferLength = this.outputAnalyzer.frequencyBinCount
        this.outputDataArray = new Uint8Array(this.outputBufferLength)

        AudioMonitorManager.startOutputMonitor()
    }

    createInputSource(): void {
        // Initialize the Mic to get the stream
        this.audioRecordManager.getDevice().then(() => {
            if (!this.audioContext) {
                console.error('Failed to create audio context')

                return
            }

            if (!this.audioRecordManager.mediaStream) {
                console.error('Failed to capture the input stream')

                return
            }

            this.inputAnalyzer = this.audioContext.createAnalyser()
            this.inputSource = this.audioContext.createMediaStreamSource(this.audioRecordManager.mediaStream)
            this.inputSource.connect(this.inputAnalyzer)
            this.inputDataArray = new Uint8Array(this.inputAnalyzer.fftSize)

            AudioMonitorManager.startInputMonitor()
        })
    }

    onOutputLevelChange(callback: (level: number) => void): Subscription {
        return this.outputAudioLevelObservable.subscribe(callback)
    }

    onInputLevelChange(callback: (level: number) => void): Subscription {
        return this.inputAudioLevelObservable.subscribe(callback)
    }

    private processMonitorData(
        analyzer: AnalyserNode,
        dataArray: Uint8Array,
        observable: Subject<number>,
        callback: () => void,
    ): void {
        if (dataArray && dataArray.length > 0) {
            analyzer.getByteFrequencyData(dataArray)

            let sum = 0

            for (let i = 0; i < dataArray.length; i++) {
                sum += dataArray[i] ?? 0
            }

            const average = sum / dataArray.length

            observable.next(average)

            requestAnimationFrame(callback)
        }
    }
}
