import { EButtonState } from '@Components';
import { facialEmotions } from '../data/constants';

export class FacialEmotion {
    public code: string;
    public label: string;
    public state: EButtonState;

    constructor(code: string, label: string, state: EButtonState = EButtonState.DEFAULT) {
        this.code = code;
        this.label = label;
        this.state = state;
    }
}

export class FacialEmotions {
    public getAll(): Array<FacialEmotion> {
        const arrayOfFacialEmotions: Array<FacialEmotion> = Object.keys(facialEmotions).map((code: string) => {
            const label: string = facialEmotions[code];
            return new FacialEmotion(code, label);
        });
        return arrayOfFacialEmotions;
    }
}
