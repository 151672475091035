import * as enums from '@Application/entities/enums'
import { AbstractElement } from '@Entities/abstractions'
import { Expose } from 'class-transformer'

export default class IncrementerElement extends AbstractElement {
    @Expose()
    defaultValue: string = ''

    @Expose()
    label: string = ''

    @Expose()
    interval: string = ''

    @Expose()
    from: string = ''

    @Expose()
    to: string = ''

    @Expose()
    name: string = ''

    @Expose()
    override mute: boolean = true

    public elementType: enums.EElementType = enums.EElementType.INCREMENTER
    public type: enums.EElementType | string = enums.EElementType.INCREMENTER
}
