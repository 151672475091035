import { InterfaceManager } from '@Services';
import { IModal } from '@Entities/interfaces';
import { ContentArea, ContentAreaMode } from '../contentArea/ContentArea';
import * as enums from '@Application/entities/enums';
import {
    Bar,
    EButtonState,
    Group,
    GroupAlignment,
    IconButton,
    IconLabelButton,
    IconVector,
    Label,
    LabelButton,
} from '@Components';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import ModalContent from './ModalContent';

export function ModalButton(props: {
    label: enums.ETranslation | string;
    onClick: () => void;
    state?: EButtonState;
    iconVector?: IconVector | null;
}) {
    props.state = props.state || EButtonState.DEFAULT;
    return props.iconVector == null ? (
        <LabelButton State={EButtonState.DEFAULT} Label={props.label} OnClick={props.onClick} />
    ) : (
        <IconLabelButton
            State={EButtonState.DEFAULT}
            Label={props.label}
            IconVector={props.iconVector}
            OnClick={props.onClick}
        />
    );
}

export function Modal(props: { modal?: IModal; onClose?: () => void }) {
    const interfaceManager = InterfaceManager.getInstance();
    const [modal, setModal] = useState<IModal>(props.modal ? props.modal : interfaceManager.Modal);

    useEffect(() => {
        if (props.modal) setModal(props.modal);
    }, [props]);

    interfaceManager.onModalChange((modal) => {
        setModal(modal);
    });

    if (!modal.template || modal.template.length === 0) return null;
    return (
        <div className={`modal ${modal.open ? 'visible' : 'hidden'}`}>
            {!Object.values<string>(enums.EModalTemplates).includes(modal.template) && (
                <div className="modalContainer">
                    <Bar
                        Right={[
                            <IconButton
                                IconVector={IconVector.CLOSE}
                                OnClick={() => {
                                    props.onClose && props.onClose();
                                    interfaceManager.setModal({
                                        ...modal,
                                        open: false,
                                    });
                                }}
                            />,
                        ]}
                    />
                    <ContentArea
                        Mode={ContentAreaMode.default}
                        Content={[
                            <Group
                                alignment={GroupAlignment.center}
                                expands={false}
                                hasMargins={true}
                                isWrapped={false}
                                HtmlRef={React.createRef<HTMLInputElement>()}
                            >
                                <Label Text={'Unrecognized modal template'} />
                            </Group>,
                        ]}
                    />
                </div>
            )}
            {modal.template === enums.EModalTemplates.RESTART && (
                <div className="modalContainer">
                    <Bar
                        Right={[
                            <IconButton
                                IconVector={IconVector.CLOSE}
                                OnClick={() => {
                                    props.onClose && props.onClose();
                                    interfaceManager.setModal({
                                        ...modal,
                                        open: false,
                                    });
                                }}
                            />,
                        ]}
                    />
                    <ContentArea
                        Mode={ContentAreaMode.default}
                        Content={[
                            <Group
                                alignment={GroupAlignment.center}
                                expands={false}
                                hasMargins={true}
                                isWrapped={false}
                                HtmlRef={React.createRef<HTMLInputElement>()}
                            >
                                <Label Text={t(enums.ETranslation.MODAL_RESTART_PROMPT)} />
                            </Group>,
                        ]}
                    />
                    <Bar
                        Name="bottomModalBar"
                        Right={[
                            <Group
                                alignment={GroupAlignment.center}
                                expands={false}
                                hasMargins={false}
                                isWrapped={true}
                                HtmlRef={React.createRef<HTMLInputElement>()}
                            >
                                <IconLabelButton
                                    State={EButtonState.DEFAULT}
                                    IconVector={IconVector.ARROW_LEFT}
                                    Label={t(enums.ETranslation.MODAL_RESTART_DENY)}
                                    OnClick={() => {
                                        props.onClose && props.onClose();
                                        interfaceManager.setModal({
                                            ...modal,
                                            open: false,
                                        });
                                    }}
                                />
                                <IconLabelButton
                                    State={EButtonState.DEFAULT}
                                    IconVector={IconVector.ARROW_RIGHT}
                                    Label={t(enums.ETranslation.MODAL_RESTART_CONFIRM)}
                                    OnClick={() => {
                                        window.location.reload();
                                    }}
                                />
                            </Group>,
                        ]}
                    />
                </div>
            )}
            {modal.template === enums.EModalTemplates.CUSTOM && (
                <div className="modalContainer">
                    <Bar
                        Right={[
                            <IconButton
                                IconVector={IconVector.CLOSE}
                                OnClick={() => {
                                    props.onClose && props.onClose();
                                    interfaceManager.setModal({
                                        ...modal,
                                        open: false,
                                    });
                                }}
                            />,
                        ]}
                    />
                    <ModalContent>{modal.content || <Label Text="No content provided" />}</ModalContent>
                    <Bar
                        Name="bottomModalBar"
                        Right={[
                            modal.actionButtonsLeft?.map((element: JSX.Element) => element),
                            modal.actionButtonsRight?.map((element: JSX.Element) => element),
                        ]}
                    />
                </div>
            )}
            <div
                className="modalBackground"
                onClick={() => {
                    if (modal.closeWithBackground) {
                        props.onClose && props.onClose();
                        interfaceManager.setModal({ ...modal, open: false });
                    }
                }}
            />
        </div>
    );
}
