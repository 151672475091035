import * as enums from '@Application/entities/enums'
import { Expose } from 'class-transformer'
import TextElement from './textElement'

interface IMedia {
    mediaType: enums.EMediaType
    source: string
    alt?: string
}

export default class MediaElement extends TextElement {
    @Expose()
    media: IMedia = {
        mediaType: enums.EMediaType.UNDEFINED,
        source: '',
        alt: '',
    }

    @Expose()
    override type: string = enums.EElementType.MEDIA

    @Expose()
    override elementType: enums.EElementType = enums.EElementType.MEDIA

    @Expose()
    persist: boolean = false
}
