import React, { useRef, useState, useEffect } from 'react'
import { IconButton, IconVector } from '@Components'
import { BackendManager, HistoryManager, InterfaceManager, UneeqManager } from '@Application/services'
import * as enums from '@Application/entities/enums'

interface PlayerProps {
    source: string
    id: number
    orientation?: enums.EMediaOrientation
}

const Player: React.FC<PlayerProps> = ({ source, id, orientation }) => {
    const interfaceManager = InterfaceManager.getInstance()
    const backendManager = BackendManager.getInstance()
    const historyManager = HistoryManager.getInstance()
    const uneeqManager = UneeqManager.getInstance()
    const videoRef = useRef<HTMLVideoElement>(null)
    const progressRef = useRef<HTMLProgressElement>(null)
    const [progress, setProgress] = useState(0)
    const [ready, setReady] = useState(false)
    const [played, setPlayed] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isCurrentVideo, setIsCurrentVideo] = useState(true)
    const [currentDigitalHumanState, setCurrentDigitalHumanState] = useState(
        interfaceManager.getCurrentDigitalHumanState(),
    )
    const handleVideoEnd = () => {
        const vidRef = videoRef.current
        setIsPlaying(false)
        if (isCurrentVideo && historyManager.isLastMedia(source)) {
            if (interfaceManager) setPlayed(false)
            if (vidRef && currentDigitalHumanState === enums.EDigitalHumanState.CHAT) vidRef.currentTime = 0
            if (interfaceManager.inputBlocked || interfaceManager.inputBlockQueued) {
                const delayedEventHandler = interfaceManager.onInputBlockedChange((value) => {
                    if (!value) {
                        backendManager.handleUserMessage({
                            messageType: enums.EMessageType.EVENT,
                            eventName: 'videoend',
                            sessionId: uneeqManager.getSessionId() as string,
                        })
                    }
                    return delayedEventHandler.unsubscribe()
                })
            } else {
                backendManager.handleUserMessage({
                    messageType: enums.EMessageType.EVENT,
                    eventName: 'videoend',
                    sessionId: uneeqManager.getSessionId() as string,
                })
            }
        }
    }

    useEffect(() => {
        const digitalHumanStateHandler = interfaceManager.handleDigitalHumanState((state) => {
            setCurrentDigitalHumanState(state.current)
        })
        const videoIndexChangeHandler = interfaceManager.onVideoIndexChange((index) => {
            setIsCurrentVideo(index === id)
        })
        return () => {
            digitalHumanStateHandler.unsubscribe()
            videoIndexChangeHandler.unsubscribe()
        }
    }, [])
    const handleProgress = () => {
        if (videoRef?.current) {
            const duration = videoRef.current?.duration
            const currentTime = videoRef.current?.currentTime
            const progress = (currentTime / duration) * 100
            setProgress(progress)
        }
    }

    const togglePlay = () => {
        isPlaying ? videoRef?.current?.pause() : videoRef?.current?.play()
        setIsPlaying((isPlaying) => !isPlaying)
    }

    const toggleFullScreen = () => {
        interfaceManager.setVideoIndex(id)
        interfaceManager.setDigitalHumanState(
            currentDigitalHumanState === enums.EDigitalHumanState.FULLSCREEN_VIDEO
                ? interfaceManager.getPreviousDigitalHumanState()!
                : enums.EDigitalHumanState.FULLSCREEN_VIDEO,
        )
    }

    const handleClick = (e: React.MouseEvent<HTMLProgressElement, MouseEvent>) => {
        if (videoRef?.current) {
            let rect = e.currentTarget.getBoundingClientRect()
            let clickX = e.clientX
            let boundLeft = Math.floor(rect?.left)
            let boundRight = Math.floor(rect?.right)
            let ratio = (clickX - boundLeft) / (boundRight - boundLeft)
            let duration = videoRef.current.duration
            videoRef.current.currentTime = duration * ratio
        }
    }

    return (
        <div
            className={`videoPlayer${isCurrentVideo && currentDigitalHumanState === enums.EDigitalHumanState.FULLSCREEN_VIDEO ? ' fullscreen' : ''}${isCurrentVideo || currentDigitalHumanState !== enums.EDigitalHumanState.FULLSCREEN_VIDEO ? ' visible' : ' hidden'} `}
        >
            {isCurrentVideo && currentDigitalHumanState === enums.EDigitalHumanState.FULLSCREEN_VIDEO && (
                <div className={`overlay`} />
            )}

            <div className="playerContainer">
                {ready && !played && !isPlaying && currentDigitalHumanState === enums.EDigitalHumanState.CHAT && (
                    <>
                        <div className={`overlay`} />
                        <IconButton IconVector={IconVector.PLAY} OnClick={togglePlay} />
                    </>
                )}
                <video
                    ref={videoRef}
                    onTimeUpdate={handleProgress}
                    className={`${ready ? 'visible' : 'hidden'}${orientation !== undefined && ' ' + orientation}`}
                    onCanPlay={() => {
                        setReady(true)
                    }}
                    autoPlay={true}
                    onEnded={handleVideoEnd}
                    onClick={togglePlay}
                    onDoubleClickCapture={toggleFullScreen}
                >
                    <source src={source} />
                </video>
            </div>
            <div
                className={`controlsContainer${
                    (isPlaying || played || currentDigitalHumanState !== enums.EDigitalHumanState.CHAT) && ready
                        ? ' visible'
                        : ' hidden'
                }`}
            >
                <progress
                    id="progress"
                    ref={progressRef}
                    value={`${progress}`}
                    onClick={(e) => handleClick(e)}
                    max="100"
                >
                    <span id="progress-bar" />
                </progress>
                <div className="controls">
                    <IconButton IconVector={IconVector.RESET} OnClick={toggleFullScreen} />
                </div>
            </div>
        </div>
    )
}

export default Player
