import React, { HTMLAttributeAnchorTarget } from 'react';
export interface LinkProps {
    Name: string;
    Target?: HTMLAttributeAnchorTarget;
    Href?: string;
    Child: React.ReactNode;
}

export class LinkContainer extends React.Component<LinkProps, LinkProps> {
    override componentDidUpdate(prevProps: Readonly<LinkProps>) {
        if (prevProps !== this.props) this.setState({ ...prevProps, ...this.props });
    }

    constructor(props: LinkProps) {
        super(props);
        this.state = {
            Name: props.Name || '',
            Target: props.Target,
            Href: props.Href,
            Child: props.Child || null,
        };
    }

    override render() {
        return (
            <a
                {...(this.state.Href ? { href: this.state.Href } : {})}
                {...(this.state.Target ? { target: this.state.Target } : {})}
                className={`linkContainer editorial ${this.state.Name}`}
            >
                {this.state.Child}
            </a>
        );
    }
}
