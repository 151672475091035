import { Page, Text, Document, StyleSheet, Image, Font, BlobProvider } from '@react-pdf/renderer'
import I18n from '@Translation'
import { t } from 'i18next';
import { IPollItem } from '@Application/entities';
import { ReactNode } from 'react';
import * as enums from '@Application/entities/enums';

Font.register({
    family: 'Roche',
    fonts: [
        {
            src: 'assets/fonts/RocheSans-Regular.ttf',
        },
        {
            src: 'assets/fonts/RocheSans-Bold.ttf',
            fontWeight: 'bold',
        },
    ],
});

const styles = StyleSheet.create({
    body: {
        fontFamily: 'Roche',
        fontSize: 10,
        lineHeight: '1.5',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    pageNumber: {
        backgroundColor: '#EDEFCA',
        bottom: 0,
        color: '#080',
        left: 0,
        lineHeight: 0,
        paddingBottom: 10,
        paddingRight: 16,
        paddingTop: 10,
        position: 'absolute',
        right: 0,
        textAlign: 'right',
    },
    avatar: {
        color: '#080',
        fontWeight: 'bold',
        paddingLeft: 16,
        paddingTop: 24,
    },
    question: {
        color: '#080',
        fontSize: 14,
        fontWeight: 'bold',
        paddingTop: 24,
    },
    answer: {
        color: '#666',
        paddingLeft: 16,
        paddingTop: 4,
    },
    logo: {
        marginBottom: 30,
        marginLeft: 'auto',
        width: 150,
    },
    banner: {
        marginHorizontal: -35,
    },
    lead: {
        backgroundColor: '#EDEFCA',
        color: '#666',
        marginHorizontal: -35,
        paddingBottom: 16,
        paddingHorizontal: 35,
        paddingTop: 20,
    },
    accent: {
        color: '#080',
        fontWeight: 'bold',
    },
});

const Quixote = (props: { items?: IPollItem[] }) => (
    <Document>
        <Page style={styles.body}>
            <Image src="assets/img/chliver-check-logo.png" style={styles.logo} />
            <Image src={`assets/img/${I18n.language}/chliver-check-banner.jpg`} style={styles.banner} />
            <Text style={styles.lead}>
                {t(enums.ETranslation.PDF_LEADING_TEXT)}
            </Text>
            {props.items && props.items.map((item, index) => {
                return (
                    <Text style={styles[item.type]} key={index}>
                        {item.value}
                    </Text>
                );
            })}
            <Text style={styles.pageNumber} render={({ pageNumber }) => pageNumber} fixed />
        </Page>
    </Document>
);

export function Pdf(props: { items?: IPollItem[]; cb?: (url: string, loading: boolean) => ReactNode }) {
    return (
        <BlobProvider document={<Quixote items={props.items} />}>
            {({ url, loading }) => (props.cb && props.cb(url as string, loading as boolean)) as ReactNode}
        </BlobProvider>
    );
}
