import type * as types from '@Entities/interfaces'
import { useEffect, useRef, useState } from 'react'
import {
    EButtonState,
    ContentArea,
    ContentAreaMode,
    GroupReference,
    HumanizerProps,
    IconLabelButton,
    Incrementer,
    LabelButton,
    Pdf,
    TextInput,
    TextInputState,
} from '@Components'
import { ButtonElement, IncrementerElement, TextElement } from '@Entities'
import * as enums from '@Application/entities/enums'
import { filter, fromEvent } from 'rxjs'
import ContentElementFactory from '@Entities/models/elements/contentElementFactory'
import MediaElement from '@Entities/models/elements/mediaElement'
import { t } from 'i18next'
import HumanizerElement from '@Application/entities/models/elements/humanizerElement'

const ContentAreaController = (props: { Services: types.IServices, timerRef, startAvatarProcessing }) => {
    const [digitalHumanState, setDigitalHumanState] = useState(
        props.Services.InterfaceManager.getCurrentDigitalHumanState(),
    )
    const [inputEnabled, setInputEnabled] = useState(props.Services.InterfaceManager.inputEnabled)
    const [pollItems, setPollItems] = useState<types.IPollItem[]>([])
    const contentAreaRef = useRef<ContentArea>(null)
    const textInputRef = useRef<TextInput>(null)
    const pdfLinkRef = useRef<HTMLAnchorElement>(null)
    const [isPdfDownload, setIsPdfDownload] = useState(false)

    const getContentAreaRef = () => {
        if (!contentAreaRef.current) throw new Error('contentAreaRef.current is null')

        return contentAreaRef.current
    }

    // Filter record when DigitalHumanState change between chat and extra content
    const filterRecords = (shouldFilter: boolean = false) => {
        getContentAreaRef().setState({
            GroupSelected: shouldFilter ? props.Services.HistoryManager.getLastMessage()?.getGroupId() : undefined,
        })
    }

    const showInput = (shouldShow: boolean = false) => {
        if (!textInputRef.current) return
        textInputRef.current.setState((prevState) => {
            return {
                ...prevState,
                State: shouldShow ? TextInputState.ACTIVE : TextInputState.HIDDEN,
            }
        })
    }

    const addPollItem = (item: types.IPollItem) => {
        setPollItems((prevPollItems) => {
            const lastPollItem = prevPollItems.pop()

            if (lastPollItem && lastPollItem.type === item.type) {
                lastPollItem.value += `${'\n'}${item.value}`

                prevPollItems.push(lastPollItem)
            } else {
                if (lastPollItem) {
                    prevPollItems.push(lastPollItem)
                }

                prevPollItems.push(item)
            }

            return prevPollItems
        })
    }

    const buttonClicked = async (element: types.IElement, contentGroupRef: GroupReference | undefined) => {
        props.startAvatarProcessing()

        if (!element.id) return new Error('elementKey is null')

        if (!contentGroupRef) return new Error('contentGroupRef is null')

        if (!contentGroupRef.ref || !contentGroupRef.ref.current) return

        if (props.Services.InterfaceManager.inputBlocked) return

        if (props.Services.ConfigManager.config.components.poll.enabled && element.poll?.answer && element.label) {
            addPollItem({
                type: 'answer',
                value: element.label,
            })
        }

        if (props.Services.ConfigManager.config.components.poll.enabled && element.poll?.download) {
            pdfLinkRef?.current?.click()
        }

        // ToDo: EVERYTHING COMMENTED OUT IS USELESS, TO BE REWORKED

        const sessionId = props.Services.UneeqManager.getSessionId() as string
        // const currentGroup = contentGroupRef.ref.current
        const currentReactElement = contentGroupRef.ref.current.getElement(element)

        if (!currentReactElement?.getRef()) return

        const currentModelElement = element as ButtonElement | IncrementerElement
        const allGroupsReference = getContentAreaRef().getAllGroupsRef()
        const lastContentGroupReference = getContentAreaRef().getLastGroupRef()

        // //Get all buttons from the clicked group. We need to set all buttons as visited, since we have a new active button
        // currentGroup.getAllElementByType(ElementType.BUTTON).forEach((elementReference) => {
        //     const button=elementReference.getRef() as LabelButton||IconLabelButton
        //     if (button.state.State !== ButtonState.ACTIVE && button.state.State !== ButtonState.ACTIVATED){
        //         button.setState({
        //             PreviousState: ButtonState.VISITED,
        //             State: ButtonState.VISITED,
        //         })
        //     }

        // })

        // // Set as activated all buttons before the trigger that was active to activated, since we will have a new active button
        // allGroupsReference
        //     .filter((group) => group.key !== contentGroupRef.key)
        //     .forEach((ContentGroupReference) => {
        //         if(!ContentGroupReference.ref||!ContentGroupReference.ref.current) return
        //         ContentGroupReference.ref.current
        //             .getAllElementByType(ElementType.BUTTON)
        //             .forEach((elementReference) => {
        //                 const button=elementReference.getRef() as LabelButton||IconLabelButton
        //                 // If the button is active we need to let this as activated
        //                 if (button.state.State === ButtonState.ACTIVE){
        //                     button.setState({
        //                         PreviousState: ButtonState.ACTIVATED,
        //                         State: ButtonState.ACTIVATED,
        //                     })
        //                 }
        //             })
        //     })

        //If clicked came from a group before the last groups it means
        // that we need delete the flow after the clicked
        if (contentGroupRef.idx < (lastContentGroupReference?.idx || 0)) {
            const groupsToRemove = allGroupsReference
                .filter((group) => group.idx > contentGroupRef.idx)
                .flatMap((ContentGroupReference) => ContentGroupReference.key)

            await getContentAreaRef().removeContentGroupsReferenceByKey(groupsToRemove)
            props.Services.HistoryManager.removeMessagesByGroupId(groupsToRemove)
        }

        const buttonReact = (currentReactElement.getRef() as LabelButton) || IconLabelButton
        const buttonModel = currentModelElement as ButtonElement

        buttonReact.setState({
            State: EButtonState.ACTIVE,
        })

        if (buttonModel.event && buttonModel.event !== 'capture_params') {
            await props.Services.BackendManager.handleUserMessage({
                messageType: enums.EMessageType.EVENT,
                eventName: buttonModel.event,
                sessionId,
                page: contentGroupRef.message.page,
            })
        } else if (buttonModel.event === 'capture_params') {
            // When a button don't have an event it will submit the form elements
            const values = contentGroupRef.ref.current.getAllElementByType(enums.EElementType.INCREMENTER).map((e) => {
                return {
                    key: e.frameElement.name,
                    value: (e.getRef() as Incrementer).state.Value.toString(),
                }
            })

            values.map((item) => {
                return addPollItem({
                    type: 'answer',
                    // @ts-ignore
                    value: `${item.key}: ${item.value}`,
                })
            })

            await props.Services.BackendManager.handleUserMessage({
                messageType: enums.EMessageType.PARAMS,
                keyValuePairs: values,
                sessionId,
                page: contentGroupRef.message.page,
            })
        }

        return
    }

    const shouldGoBottom = (shouldGoBottom: boolean = false) => {
        if (shouldGoBottom) {
            getContentAreaRef().scrollRef.current?.lastElementChild?.lastElementChild?.scrollIntoView(false)
        }
    }

    const registerHandlers = () => {
        const loadMediaBubble = (element: MediaElement) => {
            return new Promise((resolve, reject) => {
                if (element.media.mediaType !== enums.EMediaType.VIDEO) {
                    let imageElement = new Image()
                    imageElement.onload = () => {
                        console.log('Media loaded')
                        const ratio = imageElement.naturalWidth / imageElement.naturalHeight
                        let orientation =
                            ratio > 1
                                ? enums.EMediaOrientation.HORIZONTAL
                                : ratio < 1
                                  ? enums.EMediaOrientation.VERTICAL
                                  : enums.EMediaOrientation.SQUARE
                        const loadedImage = {
                            source: element.media.source,
                            naturalDimensions: { width: imageElement.naturalWidth, height: imageElement.naturalHeight },
                            alt: 'gallery content',
                            orientation,
                            type: element.media.mediaType,
                        }
                        props.Services.InterfaceManager.addNewMediaGalleryElement(loadedImage)
                        resolve(loadedImage)
                    }
                    imageElement.onerror = (error) => {
                        console.log('Error loading image')
                        reject(error)
                    }
                    imageElement.src = element.media.source
                } else {
                    let videoElement = document.createElement('video')
                    videoElement.oncanplay = () => {
                        console.log('Video loaded')
                        props.Services.InterfaceManager.addNewVideo()
                        const ratio = videoElement.videoWidth / videoElement.videoHeight
                        let orientation =
                            ratio > 1
                                ? enums.EMediaOrientation.HORIZONTAL
                                : ratio < 1
                                  ? enums.EMediaOrientation.VERTICAL
                                  : enums.EMediaOrientation.SQUARE
                        const loadedVideo = {
                            source: element.media.source,
                            naturalDimensions: { width: videoElement.videoWidth, height: videoElement.videoHeight },
                            alt: 'gallery content',
                            orientation,
                            type: element.media.mediaType,
                        }
                        resolve(loadedVideo)
                    }
                    videoElement.onerror = (error) => {
                        console.log('Error loading video')
                        reject(error)
                    }
                    videoElement.src = element.media.source
                }
            })
        }

        const handleDigitalHumanStateSubscription = props.Services.InterfaceManager.handleDigitalHumanState(async (state) => setDigitalHumanState(state.current))

        const onMessageStartSubscription = props.Services.HistoryManager.onMessageStart(async message => {
            const messageElement = message.element

            if (!messageElement) {
                return console.warn(
                    '[ContentAreaController] [registerHandlers] [elementMessage not exists]',
                    messageElement,
                )
            }

            if (messageElement.state !== enums.EPlayableState.PENDING) {
                return console.warn(
                    '[ContentAreaController] [registerHandlers] [element.state !== PlayableState.PENDING]',
                    messageElement.state,
                )
            }

            if (!messageElement.noUserActionDelay) {
                props.timerRef.current?.stop()

                //Verify if group it is already in the contentArea
                const controlGroup = getContentAreaRef().getGroupByRecordId(message.getGroupId() + message.id!)
                const contentGroup = controlGroup ? controlGroup : await getContentAreaRef().createContentGroup(message)

                if (!contentGroup.ref || !contentGroup.ref.current) return

                //Verify if element it is already in the group
                const renderedElement = contentGroup.ref.current.getElement(messageElement)

                if (renderedElement) {
                    return console.info('[ContentAreaController] [registerHandlers] [renderedElement]', renderedElement)
                }

                if (messageElement.poll?.download) {
                    setIsPdfDownload(true)
                }

                if (message.userTranscript) {
                    const messageElementUser = JSON.parse(JSON.stringify(messageElement))

                    messageElementUser.id = `${messageElement.id}-ut`
                    messageElementUser.label = message.userTranscript

                    await contentGroup.ref.current.addElement(
                        ContentElementFactory.createBubble(messageElementUser, enums.EActor.USER),
                    )
                }

                switch ((messageElement as types.IElement).elementType.toString()) {
                    case enums.EElementType.MEDIA:
                        const mediaElement = messageElement as MediaElement

                        await loadMediaBubble(mediaElement)
                            .then((loadedMedia) => {
                                const id =
                                    mediaElement.media.mediaType === enums.EMediaType.VIDEO
                                        ? props.Services.InterfaceManager.getVideoCount()
                                        : undefined
                                const newMedia = ContentElementFactory.createMediaBubble(
                                    mediaElement,
                                    loadedMedia as types.IMediaElement,
                                    id,
                                )
                                contentGroup.ref.current?.addElement(newMedia)
                            })
                            .catch((error) => {
                                console.error('Error loading media', error)
                            })

                        break

                    case enums.EElementType.INCREMENTER:
                        await contentGroup.ref.current.addElement(
                            ContentElementFactory.createIncrementer(messageElement as IncrementerElement),
                        )

                        break

                    case enums.EElementType.TEXT:
                        const textElement = messageElement as TextElement

                        switch (textElement.chat?.toString()?.toLowerCase()) {
                            case 'bubble':
                                setTimeout(async () => {
                                    await contentGroup.ref.current?.addElement(
                                        ContentElementFactory.createBubble(
                                            messageElement as TextElement,
                                            enums.EActor.AVATAR,
                                        ),
                                    )
                                }, textElement.delay || 0)

                                break
                        }

                        switch (textElement.extraContent?.toString()?.toLowerCase()) {
                            case 'title':
                                setTimeout(async () => {
                                    await contentGroup.ref.current?.addElement(
                                        ContentElementFactory.createTitle(
                                            messageElement as TextElement,
                                            'ExtraContent',
                                        ),
                                    )
                                }, textElement.delay || 0)

                                break

                            case 'subtitle':
                                setTimeout(async () => {
                                    await contentGroup.ref.current?.addElement(
                                        ContentElementFactory.createSubTitle(
                                            messageElement as TextElement,
                                            'ExtraContent',
                                        ),
                                    )
                                }, textElement.delay || 0)

                                break
                        }

                        if (props.Services.ConfigManager.config.components.poll.enabled && textElement.poll?.desc) {
                            addPollItem({
                                type: 'avatar',
                                value: textElement.label,
                            })
                        }

                        if (props.Services.ConfigManager.config.components.poll.enabled && textElement.poll?.question) {
                            addPollItem({
                                type: 'question',
                                value: textElement.label,
                            })
                        }

                        break

                    case enums.EElementType.BUTTON:
                        const button = messageElement as ButtonElement

                        setTimeout(async () => {
                            await contentGroup.ref.current?.addElement(
                                ContentElementFactory.createButton(messageElement as ButtonElement, () =>
                                    buttonClicked(messageElement as ButtonElement, contentGroup),
                                ),
                            )
                        }, button.delay || 0)

                        break

                    case enums.EElementType.HUMANIZER:
                        let defaultValues = props.Services.ConfigManager.config.components.humanizer

                        await contentGroup.ref.current.addElement(
                            ContentElementFactory.createHumanizer(
                                messageElement as HumanizerElement,
                                defaultValues as HumanizerProps,
                            ),
                        )

                        break
                }

                const lastMessage = props.Services.HistoryManager.getLastMessage()

                if (lastMessage === null || lastMessage.element?.isLast) {
                    props.Services.InterfaceManager.inputBlockQueued = true
                }

                setTimeout(() => {
                    getContentAreaRef().scrollRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({
                        behavior: 'smooth',
                    })
                }, 250)
            } else {
                props.timerRef.current?.pause()
            }
        })

        const onMessageStopSubscription = props.Services.HistoryManager.onMessageStop(_ => props.timerRef.current?.start())

        return {
            handleDigitalHumanStateSubscription,
            onMessageStartSubscription,
            onMessageStopSubscription,
        }
    }

    const registerInputHandlers = () => {
        const onInputEnabledChangeSubscription = props.Services.InterfaceManager.onInputEnabledChange(enabled => setInputEnabled(enabled))

        const onInputBlockedChangeSubscription = props.Services.InterfaceManager.onInputBlockedChange((blocked) => {
            const allGroupsReference = getContentAreaRef().getAllGroupsRef()

            if (textInputRef.current) {
                textInputRef.current.setState((prevState, props) => {
                    return {
                        ...prevState,
                        placeholder: blocked ?
                            t(enums.ETranslation.TEXTINPUT_BLOCKED) :
                            props.Placeholder ?? t(enums.ETranslation.TEXTINPUT_ACTIVE),
                        State: digitalHumanState !== enums.EDigitalHumanState.CHAT ?
                            TextInputState.HIDDEN :
                            (
                                blocked ?
                                TextInputState.BLOCKED :
                                TextInputState.ACTIVE
                            ),
                        disabled: blocked,
                    }
                })
            }

            allGroupsReference.forEach((groupReference) => {
                if (!groupReference.ref || !groupReference.ref.current) return
                groupReference.ref.current
                    .getAllElementByType(enums.EElementType.BUTTON)
                    .forEach((elementReference) => {
                        const button = (elementReference.getRef() as LabelButton) || IconLabelButton
                        blocked
                            ? button.setState({
                                  PreviousState:
                                      button.state.State !== EButtonState.BLOCKED
                                          ? button.state.State
                                          : button.state.PreviousState,
                                  State: EButtonState.BLOCKED,
                              })
                            : button.setState({
                                  State: button.state.PreviousState,
                              })
                    })
                groupReference.ref.current
                    .getAllElementByType(enums.EElementType.INCREMENTER)
                    .forEach((elementReference) => {
                        const incrementer = elementReference.getRef() as Incrementer
                        incrementer.setState({
                            State: blocked ? EButtonState.BLOCKED : EButtonState.DEFAULT,
                        })
                    })
            })
        })

        return {
            onInputBlockedChangeSubscription,
            onInputEnabledChangeSubscription,
        }
    }

    useEffect(() => {
        filterRecords(digitalHumanState !== enums.EDigitalHumanState.CHAT)
        showInput(digitalHumanState === enums.EDigitalHumanState.CHAT)
        shouldGoBottom(digitalHumanState === enums.EDigitalHumanState.CHAT)
        
        const {
            onInputBlockedChangeSubscription,
            onInputEnabledChangeSubscription
        } = registerInputHandlers()

        const {
            handleDigitalHumanStateSubscription,
            onMessageStartSubscription,
            onMessageStopSubscription
        } = registerHandlers()

        return () => {
            onInputBlockedChangeSubscription.unsubscribe()
            onInputEnabledChangeSubscription.unsubscribe()
            handleDigitalHumanStateSubscription.unsubscribe()
            onMessageStartSubscription.unsubscribe()
            onMessageStopSubscription.unsubscribe()
        }
    }, [digitalHumanState])

    useEffect(() => {
        const registerInputHandler = () => {
            if (!textInputRef.current || !textInputRef.current.state.HtmlRef.current) return
            const subscription = fromEvent<KeyboardEvent>(textInputRef.current.state.HtmlRef.current, 'keyup')
                .pipe(filter((event) => event.key === 'Enter'))
                .subscribe((event) => {
                    const input = event.target as HTMLInputElement
                    if (textInputRef.current) {
                        textInputRef.current.setState((prevState) => {
                            return {
                                ...prevState,
                                Value: '',
                            }
                        })
                    }
                    props.Services.BackendManager.handleUserMessage({
                        messageType: enums.EMessageType.TEXT,
                        text: input.value,
                        sessionId: props.Services.UneeqManager.getSessionId() as string,
                    })
                })

            return () => {
                // Clean up the subscription when the component is unmounted
                subscription.unsubscribe()
            }
        }
        if (inputEnabled) registerInputHandler()
    }, [inputEnabled])

    return (
        <>
            <ContentArea
                Mode={
                    digitalHumanState === enums.EDigitalHumanState.EXTRA_CONTENT
                        ? ContentAreaMode.extra
                        : ContentAreaMode.default
                }
                ref={contentAreaRef}
                key={'contentArea'}
                BottomBar={
                    props.Services.ConfigManager.config.components.userChatTextbox.enabled &&
                    inputEnabled && (
                        <TextInput
                            Name={'chatInput'}
                            State={
                                props.Services.InterfaceManager.inputBlocked
                                    ? TextInputState.BLOCKED
                                    : TextInputState.ACTIVE
                            }
                            ref={textInputRef}
                            AutoFocus={true}
                        />
                    )
                }
            />
            {isPdfDownload && (
                <Pdf
                    items={pollItems}
                    cb={(url: string, loading: boolean) => {
                        return !loading ? (
                            <a ref={pdfLinkRef} href={url} download="digital-human.pdf" style={{ display: 'none' }}>
                                Download PDF
                            </a>
                        ) : (
                            <></>
                        )
                    }}
                />
            )}
        </>
    )
}

export default ContentAreaController
