import * as enums from '@Application/entities/enums';
import { ConfigManager, InterfaceManager } from '@Services';
import { useEffect, useState } from 'react';

export function Background(props: { stage?: enums.EStage }) {
    const interfaceManager = InterfaceManager.getInstance();
    const configManager = ConfigManager.getInstance();
    const [stage, setStage] = useState(props.stage ? props.stage : enums.EStage.INITIAL);
    interfaceManager.onStageChanged((stage) => {
        setStage(stage);
    });
    useEffect(() => {
        if (props.stage !== undefined) {
            setStage(props.stage);
        }
    }, [props.stage]);
    if (
        configManager.config.components?.initialController?.mode === enums.ELandingPage.VIDEO &&
        stage === enums.EStage.INITIAL
    )
        return null;
    return (
        <div
            className={`background ${stage === enums.EStage.INITIAL ? 'initial' : stage === enums.EStage.LOADING ? 'loading' : 'default'}`}
        >
            <div className="videoContainerOverlay" />
            <div className="img" />
        </div>
    );
}
