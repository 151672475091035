import React from 'react';

export interface QuotesShuffleProps {
    Quotes: string[];
    Interval: number;
}

export class QuotesShuffle extends React.Component<
    QuotesShuffleProps,
    {
        currentQuote: string;
        currentInterval: any;
    }
> {
    constructor(props: QuotesShuffleProps) {
        super(props);
        this.state = {
            currentQuote: this.props.Quotes[0]!,
            currentInterval: null,
        };
    }

    override componentDidMount() {
        const currentInterval = setInterval(() => {
            const currentQuote = this.props.Quotes[Math.floor(Math.random() * this.props.Quotes.length)];

            this.setState({ currentQuote: currentQuote as string });
        }, this.props.Interval);

        this.setState({ currentInterval });
    }

    override componentWillUnmount() {
        clearInterval(this.state.currentInterval);
    }

    override render() {
        return (
            <div className="quotes">
                <div className="quote">{this.state.currentQuote}</div>
            </div>
        );
    }
}
