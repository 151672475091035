import React from 'react';
import { IconVector, IconButton } from '@Components';

export interface CarouselSlideProps {
    content: any;
    value?: any;
    isActive?: boolean;
}

export interface CarouselProps {
    slides: CarouselSlideProps[];
    onSlideChange?: (param: CarouselSlideProps) => any;
}

export interface CarouselStates {
    slideIndexActive: number;
}

export class Carousel extends React.Component<CarouselProps, CarouselStates> {
    constructor(props: CarouselProps) {
        super(props);

        const slideIndexActive = this.props.slides.findIndex((slide) => slide.isActive);

        this.state = {
            slideIndexActive: slideIndexActive > -1 ? slideIndexActive : 0,
        };
    }

    public getSlide(index: number) {
        return this.props.slides.find((_slide, i) => i === index);
    }

    private setActiveSlide(e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, index: number) {
        e.preventDefault();

        this.setState({
            slideIndexActive: index,
        });

        this.props.onSlideChange && this.props.onSlideChange(this.getSlide(index)!);
    }

    override render(): React.ReactNode {
        const slidesLength = this.props.slides.length;
        const prevIndex = this.state.slideIndexActive - 1 < 0 ? slidesLength - 1 : this.state.slideIndexActive - 1;
        const nextIndex = this.state.slideIndexActive + 1 > slidesLength - 1 ? 0 : this.state.slideIndexActive + 1;

        return (
            this.props.slides && (
                <div className={`carousel`}>
                    <div className={`carousel-controls`}>
                        <IconButton
                            Name={'carousel-control-chevron'}
                            IconVector={IconVector.CHEVRON_LEFT}
                            OnClick={(e) => this.setActiveSlide(e, prevIndex)}
                        />
                        <div className={`carousel-control carousel-control-middle`}>
                            <div className={`carousel-slides`}>
                                {this.props.slides.map((slide: CarouselSlideProps, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-slide`}
                                        data-active={index === this.state.slideIndexActive ? '' : undefined}
                                    >
                                        {slide.content}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <IconButton
                            Name={'carousel-control-chevron'}
                            IconVector={IconVector.CHEVRON_RIGHT}
                            OnClick={(e) => this.setActiveSlide(e, nextIndex)}
                        />
                    </div>
                    <div className={`carousel-dots`}>
                        {this.props.slides.map((_slide: CarouselSlideProps, index) => (
                            <div
                                key={index}
                                className={`carousel-dot`}
                                data-active={index === this.state.slideIndexActive ? '' : undefined}
                                onClick={(e) => this.setActiveSlide(e, index)}
                            ></div>
                        ))}
                    </div>
                </div>
            )
        );
    }
}
