// eslint-disable-next-line max-classes-per-file
import type { ContentMode } from 'src/visual/components/humanizer/Humanizer'
import type { InputSliderItem } from 'src/visual/components/input/inputSlider/InputSlider'

export abstract class EnableConfigAbstract {
    enabled: boolean = false
}

export class ApplicationConfig {
    logging: boolean = true
}

export class BackendConfig {
    Url: string = ''
    PersonaId: string = ''
    Env: IEnvironment = {}
}

export class ContentConfig {
    // This event will be called as soon as the user open the webpage
    WelcomeEvent?: string
    loadingMessages: string[] = []
    AvatarName: string = ''
}

export interface IEnvironment {
    name?: string
    id?: string
}

export class InputConfig extends EnableConfigAbstract {
    override enabled: boolean = true
}

export class FeatureConfig {
    input: InputConfig = new InputConfig()
}

export class I18nConfig extends EnableConfigAbstract {
    default: string = 'en'
    translations: {} = {}
}

export class TimerConfig extends EnableConfigAbstract {
    countdownTimeInSecondsTotal: number = 0
    countdownTimeInSecondsWarning: number = 0
}

export class BackgroundConfig {
    url: string = ''
}

export class BugherdConfig extends EnableConfigAbstract {
    key: string = ''
}

export class DialogFlowConfig {
    page: string = ''
}

export class MicrophoneConfig extends EnableConfigAbstract {}

export class UserChatTextboxConfig extends EnableConfigAbstract {}

export class ButtonWantToTryConfig extends EnableConfigAbstract {}

export class LogoConfig extends EnableConfigAbstract {}

export class PollConfig extends EnableConfigAbstract {}

export class SoundVisualizatorConfig extends EnableConfigAbstract {}

export class AvatarProcessingConfig extends EnableConfigAbstract {
    delay: number = 0
}

export class DropdownLanguageSelectionConfig extends EnableConfigAbstract {
    icons: { enabled: boolean } = { enabled: false }
}

export class ModuleConfig {
    bugherd: BugherdConfig = new BugherdConfig()
    dialogFlow: DialogFlowConfig = new DialogFlowConfig()
    i18n: I18nConfig = new I18nConfig()
    timer: TimerConfig = new TimerConfig()
}

export class InitialControllerConfig extends EnableConfigAbstract {
    mode: string = ''
    video: {
        language: {}
    } = {
        language: {},
    }
}

export class HamburgerOptionConfig extends EnableConfigAbstract {
    label: string = ''
    icon: string = ''
    event: string = ''
}

export class DropdownConfig extends EnableConfigAbstract {
    hamburger: {
        options: HamburgerOptionConfig[]
    } = {
        options: [],
    }
    icon: string = ''
    template: string = ''
    settings: {
        enabled: boolean
        content: HamburgerOptionConfig[]
    } = {
        enabled: true,
        content: [],
    }
    languageSelection: {
        trigger: {
            icon: string
        }
        icons: { enabled: boolean }
    } = {
        trigger: {
            icon: 'union',
        },
        icons: {
            enabled: false,
        },
    }
}

export class ThemeConfig {
    name: string = ''
    rootSize: {
        normal: number
        large: number
    } = {
        normal: 1,
        large: 1,
    }
}

export class HumanizerConfig extends EnableConfigAbstract {
    inputEnabled?: boolean
    defaultVoiceStyleCode?: string
    defaultLanguageCode?: string
    defaultGenderCode?: string
    defaultVocalCharacterCode?: string
    defaultAvailableLanguageQty?: number
    defaultAvailableGendersQty?: number
    defaultAvailableVoiceStylesQty?: number
    defaultAvailableVocalCharactersQty?: number
    defaultCameraCode?: string
    defaultContentMode?: ContentMode
    defaultVoiceStyleIntensity?: InputSliderItem
    defaultFacialEmotionIntensity?: InputSliderItem
}

export class ComponentConfig {
    background: BackgroundConfig = new BackgroundConfig()
    buttonWantToTry: ButtonWantToTryConfig = new ButtonWantToTryConfig()
    dropdown: DropdownConfig = new DropdownConfig()
    initialController: InitialControllerConfig = new InitialControllerConfig()
    microphone: MicrophoneConfig = new MicrophoneConfig()
    userChatTextbox: UserChatTextboxConfig = new UserChatTextboxConfig()
    humanizer: HumanizerConfig = new HumanizerConfig()
    logo: LogoConfig = new LogoConfig()
    poll: PollConfig = new PollConfig()
    soundVisualizator: SoundVisualizatorConfig = new SoundVisualizatorConfig()
    avatarProcessing: AvatarProcessingConfig = new AvatarProcessingConfig()
}

export class DigitalHumanConfig {
    application: ApplicationConfig = new ApplicationConfig()
    features: FeatureConfig = new FeatureConfig()
    backend: BackendConfig = new BackendConfig()
    content: ContentConfig = new ContentConfig()
    components: ComponentConfig = new ComponentConfig()
    modules: ModuleConfig = new ModuleConfig()
    theme: ThemeConfig = new ThemeConfig()
}
