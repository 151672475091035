// eslint-disable-next-line import/prefer-default-export
export enum EButtonState {
    PLACEHOLDER = 'placeholder',
    DEFAULT = 'default',
    BLOCKED = 'blocked',
    ACTIVE = 'active',
    ACTIVATED = 'activated',
    VISITED = 'visited',
    HOVER = 'hover',
    HIDE = 'hide',
}
