import { Expose } from 'class-transformer'

export default class UneeqSession {
    @Expose({ name: 'server' })
    public server: string = ''

    @Expose({ name: 'avatarId' })
    public avatarId: string = ''

    @Expose({ name: 'token' })
    public token: string = ''

    @Expose({ name: 'sessionId' })
    public sessionId: string = ''
}
