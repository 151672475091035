import * as enums from '@Application/entities/enums';
import { Label, EButtonState } from '@Components';
import React from 'react';

export interface CheckButtonProps {
    Name?: string;
    State: EButtonState;
    Label: enums.ETranslation | string;
    OnClick?: () => void;
    Checked: boolean;
}

export default class CheckButton extends React.Component<CheckButtonProps, {}> {
    override state = {
        checked: this.props.Checked,
    };

    override componentDidUpdate(prevProps: Readonly<CheckButtonProps>): void {
        if (prevProps.Checked !== this.props.Checked) {
            this.setState({
                ...this.state,
                checked: this.props.Checked,
            });
        }
    }

    override render() {
        return (
            <div
                className={`checkButton ${this.props.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={() => {
                    this.props.OnClick && this.props.OnClick();
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    });
                }}
            >
                <div className="container">
                    <Label Text={this.props.Label} />
                    <div className="content">
                        <input type="checkbox" checked={this.state.checked || false} onChange={() => {}} />
                        <div className="checkbox">
                            <div className="checkmark" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
