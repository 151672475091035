import 'reflect-metadata'
import { useState } from 'react'
import { IServices } from '@Entities/interfaces/services'
import { LoadingController, DigitalHumanController, InitialController } from './controllers'
import * as enums from '@Application/entities/enums'
import I18n from '@Translation'
import { Background, Modal } from '@Components'
import { Helmet } from 'react-helmet'
import {
    AvatarAnswerObserver,
    AvatarFinishSpeakingObserver,
    AvatarSessionEndedObserver,
    AvatarStartedSpeakingObserver,
    ClientMediaStreamUpdateObserver,
    AvatarReadyObserver,
    AvatarSessionLive,
    SpeechTranscriptionObserver,
} from '@Observers'
import {
    AudioRecordManager,
    AudioMonitorManager,
    BackendManager,
    ConfigManager,
    HistoryManager,
    InterfaceManager,
    SettingManager,
    UneeqManager,
} from '@Services'
import '../visual/themeFactory/index.scss'

function Application() {
    const configManager = ConfigManager.getInstance()
    const interfaceManager = InterfaceManager.getInstance()
    const historyManager = HistoryManager.getInstance()
    const audioRecordManager = AudioRecordManager.getInstance()
    const uneeqManager = UneeqManager.getInstance()
    const backendManager = BackendManager.getInstance()
    const settingsManager = SettingManager.getInstance({ lang: I18n.language })
    const audioMonitorManager = AudioMonitorManager.getInstance()

    const [services] = useState<IServices>({
        InterfaceManager: interfaceManager,
        BackendManager: backendManager,
        HistoryManager: historyManager,
        AudioRecordManager: audioRecordManager,
        UneeqManager: uneeqManager,
        ConfigManager: configManager,
        SettingManager: settingsManager,
        AudioMonitorManager: audioMonitorManager,
    })

    const bugherdSource = `https://www.bugherd.com/sidebarv2.js?apikey=${configManager.config.modules.bugherd?.key}`
    const bugherdEnabled = configManager.config.modules.bugherd?.enabled
    const translations = configManager.config.modules.i18n.translations
    const defaultLanguage = configManager.config.modules.i18n.default
    const languages = Object.keys(translations)
    const urlSearchParams = new URLSearchParams(window.location.search)

    I18n.init({
        defaultNS: 'common',
        resources: translations as {},
    })

    I18n.on('languageChanged', lang => services.SettingManager.lang = lang)

    I18n.changeLanguage(
        languages.find((l) => l === urlSearchParams.get('lang')) ||
        languages.find((l) => l === settingsManager.lang) ||
        defaultLanguage,
    )

    // Monitor Messages from the Uneeq
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.READY, new AvatarReadyObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.CLIENT_MEDIA_STREAM_UPDATE, new ClientMediaStreamUpdateObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.SESSION_LIVE, new AvatarSessionLive(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.AVATAR_ANSWER, new AvatarAnswerObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.STARTED_SPEAKING, new AvatarStartedSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.FINISHED_SPEAKING, new AvatarFinishSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.SPEECH_TRANSCRIPTION, new SpeechTranscriptionObserver(services))
    services.UneeqManager.subscribeEventObserver(enums.EUneeqEvent.SESSION_ENDED, new AvatarSessionEndedObserver(services))

    return (
        <>
            {bugherdEnabled && (
                <Helmet>
                    <script src={bugherdSource} defer></script>
                </Helmet>
            )}
            <Modal />
            <Background />
            <InitialController {...services} />
            <LoadingController {...services} />
            <DigitalHumanController {...services} />
        </>
    )
}

export default Application
