import * as enums from '@Application/entities/enums'
import { Subject } from 'rxjs'
import { Uneeq } from 'uneeq-js'
import type { Observer } from 'rxjs'

export interface IUneeqManagerProps {
    server: string
    token: string
    avatarId: string
    avatarVideoContainerElement: HTMLDivElement | null
    localVideoContainerElement: HTMLDivElement | null
    backgroundUrl: string
}

export interface IObserverValues {
    [enums.EUneeqEvent.SPEECH_TRANSCRIPTION]: Error
    [enums.EUneeqEvent.AVATAR_ANSWER]: { answerAvatar: string }
    [key: string]: unknown
}

export default class UneeqManager {
    private _loadingPercentage: number = 0
    private _loadingPercentageObservable: Subject<number> = new Subject<number>()
    avatarState: enums.EUneeqAvatarState = enums.EUneeqAvatarState.UNAVAILABLE

    connectionState: enums.EUneeqConnectionState = enums.EUneeqConnectionState.NOT_CONNECTED
    private uneeqInstance: Uneeq | null = null
    private readonly observers: Record<enums.EUneeqEvent, Subject<IObserverValues[enums.EUneeqEvent]>> = {} as Record<
        enums.EUneeqEvent,
        Subject<IObserverValues[enums.EUneeqEvent]>
    >

    public onLoadingPercentageChange = (callback: (percentage: number) => void): void => {
        this._loadingPercentageObservable.subscribe(callback)
    }

    public getSessionId = (): string | null => {
        return this.uneeqInstance?.sessionId ?? null
    }

    get loadingPercentage(): number {
        return this._loadingPercentage
    }

    set loadingPercentage(value: number) {
        this._loadingPercentage = value
        this._loadingPercentageObservable.next(value)
    }

    public static getInstance(): UneeqManager {
        if (!UneeqManager.instance) UneeqManager.instance = new UneeqManager()
        return UneeqManager.instance
    }
    // Singleton
    private static instance: UneeqManager

    subscribeEventObserver<T extends enums.EUneeqEvent>(event: T, observer: Observer<IObserverValues[T]>): void {
        // Check if the observer already exists, if not create it
        if (!this.observers[event]) this.observers[event] = new Subject<unknown>()

        this.observers[event].subscribe(observer as Partial<Observer<unknown>>) // #TODO This is stupid, but there is not other way to make it work. React does not like completly generic value
    }

    notifyEventObservers(message: { uneeqMessageType: enums.EUneeqEvent }): void {
        const uneeqEvent = message.uneeqMessageType

        if (this.observers[uneeqEvent]) this.observers[uneeqEvent].next(message)
    }

    async connect(props: IUneeqManagerProps): Promise<this> {
        // Check if token was initialized or if we are already connecting, prevent multiple connections
        if (this.connectionState !== enums.EUneeqConnectionState.NOT_CONNECTED) {
            console.warn('Connecting to Uneeq is already in progress or has already been completed')
            return this
        }

        if (!props.avatarVideoContainerElement || !props.localVideoContainerElement)
            throw new Error('Avatar e local video container must be defined')

        // Set state to connecting, prevent multiple connections
        this.connectionState = enums.EUneeqConnectionState.CONNECTING
        console.info(props)
        console.info(props.backgroundUrl)
        this.uneeqInstance = new Uneeq({
            url: props.server,
            conversationId: props.avatarId,
            messageHandler: (message: { uneeqMessageType: enums.EUneeqEvent }): void => {
                this.notifyEventObservers(message)
            },
            avatarVideoContainerElement: props.avatarVideoContainerElement,
            localVideoContainerElement: props.localVideoContainerElement,
            sendLocalAudio: false,
            sendLocalVideo: false,
            customData: null,
            voiceInputMode: 'PUSH_TO_TALK',
            logging: false,
            backgroundImageUrl: props.backgroundUrl,
        })
        this.uneeqInstance.setLoggerEnabled(false)
        await this.uneeqInstance.initWithToken(props.token)
        this.connectionState = enums.EUneeqConnectionState.CONNECTED

        // Return state
        return this
    }

    public stopSpeaking(): void {
        // To avoid create a flood of request to uneeq.
        if (this.avatarState === enums.EUneeqAvatarState.SPEAKING)
            this.uneeqInstance?.stopSpeaking().catch((err) => {
                console.log('Error', 'Could not stop uneeq from speaking', err)
            })
    }
}
