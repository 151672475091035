import React from "react";
import { Icon, IconVector } from "../icon/Icon";

export function SoundVisualizator({ scale = 1, children }: { scale?: Number, children?: React.ReactNode }) {
    return (
        <div className="soundVisualizator" style={{ '--sv-scale': scale } as React.CSSProperties }>
            <Icon vector={IconVector.RADIAL_GRADIENT} />
            {children}
        </div>
    )
}
