import React, { RefObject } from 'react'
import {
    EButtonState,
    Group,
    GroupAlignment,
    IconLabelButton,
    IconVector,
    Label,
    LabelButton,
    Modal,
} from '@Components'

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Gender, Genders } from './models/genders'
import { Language, Languages } from './models/languages'
import { VoiceStyle, VoiceStyles } from './models/voiceStyles'

import { uniqueId } from 'lodash'
import { FacialEmotion, FacialEmotions } from './models/facialEmotion'
import { Gesture, Gestures } from './models/gesture'
import { Camera, Cameras } from './models/camera'
import { facialEmotionIntensities, vocalStyleIntensities } from './data/constants'

import { UneeqCamerasPreview } from '@Images'
import { InputSlider, InputSliderItem } from '../input/inputSlider/InputSlider'
import { BackendManager, UneeqManager } from '@Application/services'
import ContentTab from './ContentTab'

// enum VoiceServiceConstraint {
//     gender,
//     language,
//     voiceStyle,
//     vocalCharacter
// }

export enum ContentMode {
    default,
    custom,
}

interface IModal {
    isModalOpened: boolean
    modalContent: JSX.Element
    modalAction: JSX.Element
}

interface IContent {
    content: string
    contentMode: ContentMode | undefined
}

interface IFacialEmotion {
    facialEmotionSelection: FacialEmotion | undefined
    facialEmotionIntensity: InputSliderItem | undefined
}

interface IVocalCharacter {
    vocalCharacterList: Array<string>
    availableVocalCharactersQty: number
}
interface IVoiceStyle {
    voiceStylesList: Array<VoiceStyle>
    voiceStyleIntensity: InputSliderItem | undefined
    availableVoiceStylesQty: number
}

interface IGender {
    gendersList: Array<Gender>
    availableGendersQty: number
}

interface ILanguage {
    languagesList: Array<Language>
    availableLanguageQty: number
}

enum SubMenuTab {
    language,
    gender,
    voiceStyle,
    vocalCharacter,
}
enum MenuTab {
    VOICE = 'voice',
    FACIALEMOTION = 'facial Emotion',
    GESTURES = 'gestures',
    CAMERA = 'camera',
    CONTENT = 'content',
}

interface IUpdateContentProps {
    LanguageSelection?: Language | undefined
    VoiceStyleSelection?: VoiceStyle | undefined
}

export interface HumanizerProps {
    inputEnabled?: boolean
    defaultVoiceStyleCode?: string
    defaultLanguageCode?: string
    defaultGenderCode?: string
    defaultVocalCharacterCode?: string
    defaultAvailableLanguageQty?: number
    defaultAvailableGendersQty?: number
    defaultAvailableVoiceStylesQty?: number
    defaultAvailableVocalCharactersQty?: number
    defaultCameraCode?: string
    defaultContentMode?: ContentMode
    defaultVoiceStyleIntensity?: InputSliderItem
    defaultFacialEmotionIntensity?: InputSliderItem
}

interface ICta {
    gesture: Gesture | undefined
    camera: Camera | undefined
    facialEmotion: IFacialEmotion | undefined
}
export interface HumanizerStates {
    VoiceStyle: IVoiceStyle
    FacialEmotion: IFacialEmotion
    PreviousCta: ICta
    GestureSelection: Gesture | undefined
    CameraSelection: Camera | undefined
    GenderSelection: Gender | undefined
    LanguageSelection: Language | undefined
    VocalCharacterSelection: string | undefined
    VoiceStyleSelection: VoiceStyle | undefined
    Language: ILanguage
    Content: IContent
    Gender: IGender
    VocalCharacter: IVocalCharacter
    Highlighted: boolean
    CtaButtonState: EButtonState
    MenuTab: MenuTab
    SubMenuTab: SubMenuTab
    Modal: IModal
    Ref: React.RefObject<HTMLDivElement>
    RequiredContentLength: number
    State: EButtonState
    CustomContent: string | undefined
}
export class Humanizer extends React.Component<HumanizerProps, HumanizerStates> {
    genders = new Genders()
    languages = new Languages()
    voiceStyles = new VoiceStyles()
    facialEmotions = new FacialEmotions()
    gestures = new Gestures()
    cameras = new Cameras()
    defaultVoiceStyle: VoiceStyle | undefined = undefined
    defaultLanguage: Language | undefined = undefined
    defaultGender: Gender | undefined = undefined
    defaultVocalCharacter: string | undefined = undefined
    backendManager: BackendManager = BackendManager.getInstance()
    uneeqManager: UneeqManager = UneeqManager.getInstance()
    inputRef: RefObject<HTMLInputElement> = React.createRef()
    constructor(props: HumanizerProps) {
        super(props)
        this.defaultVoiceStyle = this.voiceStyles.getDefaultVoiceStyle(props.defaultVoiceStyleCode ?? 'no_vocalStyle')
        this.defaultLanguage = this.languages.getDefaultLanguage(props.defaultLanguageCode ?? 'en-US')
        this.defaultGender = this.genders.getDefaultGender(props.defaultGenderCode ?? 'female')
        this.defaultVocalCharacter = props.defaultVocalCharacterCode ?? 'JennyNeural'
        this.state = {
            PreviousCta: { gesture: undefined, camera: undefined, facialEmotion: undefined },
            VoiceStyle: {
                voiceStyleIntensity: props.defaultVoiceStyleIntensity,
                voiceStylesList: this.voiceStyles.getAll(),
                availableVoiceStylesQty: props.defaultAvailableVoiceStylesQty ?? 6,
            },
            FacialEmotion: {
                facialEmotionIntensity: props.defaultFacialEmotionIntensity,
                facialEmotionSelection: undefined,
            },
            GestureSelection: undefined,
            CameraSelection: undefined,
            RequiredContentLength: 0,
            CustomContent: '',
            GenderSelection: this.defaultGender,
            LanguageSelection: this.defaultLanguage,
            VocalCharacterSelection: this.defaultVocalCharacter,
            VoiceStyleSelection: this.defaultVoiceStyle,
            Language: {
                languagesList: this.languages.getAll(),
                availableLanguageQty: props.defaultAvailableLanguageQty ?? 6,
            },
            Content: { contentMode: ContentMode.default, content: '' },
            Gender: { gendersList: this.genders.getAll(), availableGendersQty: props.defaultAvailableGendersQty ?? 2 },
            VocalCharacter: {
                vocalCharacterList: [],
                availableVocalCharactersQty: props.defaultAvailableVocalCharactersQty ?? 5,
            },
            Modal: { modalAction: <></>, modalContent: <></>, isModalOpened: false },
            CtaButtonState: EButtonState.DEFAULT,
            MenuTab: MenuTab.VOICE,
            SubMenuTab: SubMenuTab.language,
            Highlighted: false,
            Ref: React.createRef(),
            State: EButtonState.DEFAULT,
        }
    }

    set activeMenuTab(MenuTab: MenuTab) {
        this.setState((state) => ({
            ...state,
            MenuTab,
        }))
    }

    set activeSubMenuTab(SubMenuTab: SubMenuTab) {
        this.setState((state) => ({
            ...state,
            SubMenuTab,
        }))
    }

    set vocalCharacterSelection(VocalCharacterSelection: string | undefined) {
        this.setState((state) => ({
            ...state,
            VocalCharacterSelection,
        }))
    }

    set vocalCharacterList(vocalCharacterList: Array<string>) {
        this.setState((state) => ({
            VocalCharacter: {
                ...state.VocalCharacter,
                vocalCharacterList,
                availableVocalCharactersQty: vocalCharacterList.length,
            },
        }))
    }

    set languagesList(languagesList: Array<Language>) {
        this.setState((state) => ({
            Language: { ...state.Language, languagesList },
        }))
    }

    set availableLanguageQty(availableLanguageQty: number) {
        this.setState((state) => ({
            Language: { ...state.Language, availableLanguageQty },
        }))
    }

    set languageSelection(LanguageSelection: Language | undefined) {
        this.updateContent({ LanguageSelection })
        this.setState((state) => ({
            ...state,
            LanguageSelection,
        }))
    }

    set genderSelection(GenderSelection: Gender | undefined) {
        this.setState((state) => ({
            ...state,
            GenderSelection,
        }))
    }

    set gendersList(gendersList: Array<Gender>) {
        this.setState((state) => ({
            Gender: { ...state.Gender, gendersList },
        }))
    }

    set availableGendersQty(availableGendersQty: number) {
        this.setState((state) => ({
            Gender: { ...state.Gender, availableGendersQty },
        }))
    }

    set ctaButtonState(CtaButtonState: EButtonState) {
        if (this.state.CtaButtonState !== CtaButtonState)
            this.setState((state) => ({
                ...state,
                CtaButtonState,
            }))
    }

    set voiceStyleSelection(NewVoiceStyle: VoiceStyle | undefined) {
        const VoiceStyleSelection = NewVoiceStyle || this.defaultVoiceStyle
        this.updateContent({ VoiceStyleSelection })
        this.setState((state) => ({
            ...state,
            VoiceStyleSelection,
        }))
    }

    set voiceStylesList(voiceStylesList: Array<VoiceStyle>) {
        this.setState((state) => ({
            VoiceStyle: { ...state.VoiceStyle, voiceStylesList },
        }))
    }

    set voiceStyleIntensity(voiceStyleIntensity: InputSliderItem | undefined) {
        this.setState((state) => ({
            VoiceStyle: { ...state.VoiceStyle, voiceStyleIntensity },
        }))
    }

    set availableVoiceStylesQty(availableVoiceStylesQty: number) {
        this.setState((state) => ({
            VoiceStyle: { ...state.VoiceStyle, availableVoiceStylesQty },
        }))
    }

    set isModalOpened(isModalOpened: boolean) {
        this.setState((state) => ({
            Modal: { ...state.Modal, isModalOpened },
        }))
    }

    set modalContent(modalContent: JSX.Element) {
        this.setState((state) => ({
            Modal: { ...state.Modal, modalContent },
        }))
    }

    set modalAction(modalAction: JSX.Element) {
        this.setState((state) => ({
            Modal: { ...state.Modal, modalAction },
        }))
    }

    set facialEmotionSelection(facialEmotionSelection: FacialEmotion | undefined) {
        this.setState((state) => ({
            ...state,
            RequiredContentLength: facialEmotionSelection
                ? state.FacialEmotion.facialEmotionSelection
                    ? state.RequiredContentLength
                    : state.RequiredContentLength + 1
                : state.RequiredContentLength - 1,
            FacialEmotion: { ...state.FacialEmotion, facialEmotionSelection },
        }))
    }

    set facialEmotionIntensity(facialEmotionIntensity: InputSliderItem | undefined) {
        this.setState((state) => ({
            FacialEmotion: { ...state.FacialEmotion, facialEmotionIntensity },
        }))
    }

    set gestureSelection(GestureSelection: Gesture | undefined) {
        this.setState((state) => ({
            ...state,
            RequiredContentLength: GestureSelection
                ? state.GestureSelection
                    ? state.RequiredContentLength
                    : state.RequiredContentLength + 1
                : state.RequiredContentLength - 1,
            GestureSelection,
        }))
    }

    set content(content: string) {
        this.setState((state) => ({
            Content: { ...state.Content, content },
        }))
    }

    set customContent(CustomContent: string | undefined) {
        this.setState((state) => ({
            ...state,
            CustomContent,
        }))
    }

    set contentMode(contentMode: ContentMode) {
        this.setState((state) => ({
            CtaButtonState:
                contentMode === ContentMode.custom &&
                !(this.state.CustomContent && this.state.CustomContent.length >= this.state.RequiredContentLength)
                    ? EButtonState.BLOCKED
                    : EButtonState.DEFAULT,
            Content: { ...state.Content, contentMode },
        }))
    }

    set cameraSelection(CameraSelection: Camera | undefined) {
        this.setState((state) => ({
            ...state,
            RequiredContentLength: CameraSelection
                ? state.CameraSelection
                    ? state.RequiredContentLength
                    : state.RequiredContentLength + 1
                : state.RequiredContentLength - 1,
            CameraSelection,
        }))
    }

    set previousCta({ gesture, camera, facialEmotion }: ICta) {
        this.setState((state) => ({
            ...state,
            PreviousCta: { gesture, camera, facialEmotion },
        }))
    }

    blockedButtonClick(selection: Gender | Language | VoiceStyle): void {
        var type: string = ''
        var content: string = ''
        if (selection instanceof Gender) {
            type = 'gender'
        }
        if (selection instanceof Language) {
            type = 'language'
        }
        if (selection instanceof VoiceStyle) {
            type = 'voice style'
        }
        var genderToDisable: Gender | undefined = undefined
        var languageToDisable: Language | undefined = undefined
        var voiceStyleToDisable: VoiceStyle | undefined = undefined

        // Issues counting for text adaptation
        var issues: number = 0
        if (selection.currentCompatibility.language === false) {
            issues++
        }
        if (selection.currentCompatibility.gender === false) {
            issues++
        }
        if (selection.currentCompatibility.voiceStyle === false) {
            issues++
        }

        // Language diagnosis
        if (selection.currentCompatibility.language === false) {
            if (this.state.LanguageSelection === undefined) {
                content += `__Error__: The ${selection.label} ${type} is not compatible with the current language selection, that is currently undefined...
                    Please report this issue.`
            } else {
                content += `The ${selection.label} ${type} is not available in ${this.state.LanguageSelection.label}.`
                // Actions
                languageToDisable = this.state.LanguageSelection
            }
        }

        // Gender diagnosis
        if (selection.currentCompatibility.gender === false) {
            if (this.state.GenderSelection === undefined) {
                content += `__Error__: The ${selection.label} ${type} is not compatible with the current gender selection, that is currently undefined...
                    Please report this issue.`
            } else {
                switch (issues) {
                    case 1:
                        content += `The ${selection.label} ${type} is not compatible with the ${this.state.GenderSelection.label} gender.`
                        break
                    case 2:
                        selection.currentCompatibility.language === false
                            ? (content += `
                                Also, it is not compatible with the ${this.state.GenderSelection.label} gender.`)
                            : (content += `The ${selection.label} ${type} is not compatible with the ${this.state.GenderSelection.label} gender.`)
                        break
                    default:
                        content += `
                            Also, it is not compatible with the ${this.state.GenderSelection.label} gender.`
                        break
                }
                // Actions
                genderToDisable = this.state.GenderSelection
            }
        }

        // Voice Style diagnosis
        if (selection.currentCompatibility.voiceStyle === false) {
            if (this.state.VoiceStyleSelection === undefined) {
                content += `__Error__: The ${selection.label} ${type} is not compatible with the current voice style selection, that is currently undefined...
                    Please report this issue.`
            } else {
                switch (issues) {
                    case 1:
                        content += `The ${selection.label} ${type} is not compatible with the "${this.state.VoiceStyleSelection.label}" voice style.`
                        break
                    case 2:
                        content += `
                            Also, it is not compatible with the "${this.state.VoiceStyleSelection.label}" voice style.`
                        break
                    case 3:
                        content += `
                            Finally, it is not compatible with the "${this.state.VoiceStyleSelection.label}" voice style.`
                        break
                }
                // Actions
                voiceStyleToDisable = this.state.VoiceStyleSelection
            }
        }

        // Set Content
        this.modalContent = <Label Text={content} />

        // Set Action
        if (genderToDisable !== undefined || languageToDisable !== undefined || voiceStyleToDisable !== undefined) {
            var nTypesToDisable: number = 0
            if (genderToDisable !== undefined) {
                nTypesToDisable++
            }
            if (languageToDisable !== undefined) {
                nTypesToDisable++
            }
            if (voiceStyleToDisable !== undefined) {
                nTypesToDisable++
            }

            var label = `Select **${selection.label}** and disable `
            if (languageToDisable !== undefined) {
                label += `${languageToDisable.label}`
                if (nTypesToDisable > 1) {
                    label += ' and '
                }
            }
            if (genderToDisable !== undefined) {
                label += `${genderToDisable.label}`
                if (nTypesToDisable > 1) {
                    if (languageToDisable !== null) {
                        label += '.'
                    } else {
                        label += ' and '
                    }
                }
            }
            if (voiceStyleToDisable !== undefined) {
                label += `${voiceStyleToDisable.label}.`
            }

            this.modalAction = (
                <IconLabelButton
                    State={EButtonState.DEFAULT}
                    IconVector={IconVector.ARROW_RIGHT}
                    Label={label}
                    key={'modalActionRight_' + uniqueId()}
                    OnClick={() => {
                        // Close modal
                        this.isModalOpened = false
                        // Update selection states
                        if (languageToDisable !== undefined) {
                            this.languageSelection = undefined
                        }
                        if (genderToDisable !== undefined) {
                            this.genderSelection = undefined
                        }
                        if (voiceStyleToDisable !== undefined) {
                            this.voiceStyleSelection = undefined
                        }
                        if (selection instanceof Language) {
                            this.languageSelection = selection
                        }
                        if (selection instanceof Gender) {
                            this.genderSelection = selection
                        }
                        if (selection instanceof VoiceStyle) {
                            this.voiceStyleSelection = selection
                        }
                        // Update lists through useEffect:
                        //setForceApplyButtonSelection(selection);
                        //setIsForceApplyButtonClicked(true);
                    }}
                />
            )
        } else {
            this.modalAction = <></>
        }

        // Open Modal
        this.isModalOpened = true
    }

    updateContent = ({ LanguageSelection, VoiceStyleSelection }: IUpdateContentProps) => {
        const languageSelection = LanguageSelection || this.state.LanguageSelection || this.defaultLanguage
        const voiceStyleSelection = VoiceStyleSelection || this.state.VoiceStyleSelection || this.defaultVoiceStyle
        let Content = {
            ...this.state.Content,
            content: voiceStyleSelection?.voices[languageSelection?.code as string].content,
            customContent: this.inputRef.current?.value,
        }
        this.setState((state) => ({
            ...state,
            Content,
        }))
    }

    updateVocalCharacters = ({
        genderSelection = this.state.GenderSelection,
        languageSelection = this.state.LanguageSelection,
        voiceStyleSelection = this.state.VoiceStyleSelection,
        outputToState = true,
    } = {}): Array<string> => {
        var vocalCharacterList: Array<string> = []
        //  console.log("Updating vocal characters with: gender: " + genderSelection?.label + ", language: " + languageSelection?.label + ", voiceStyle: " + voiceStyleSelection?.label);
        if (genderSelection !== undefined && languageSelection !== undefined) {
            if (voiceStyleSelection === undefined || voiceStyleSelection.code === 'no_vocalStyle') {
                // Check all voice styles
                this.voiceStyles.getAll().map((voiceStyle: VoiceStyle) => {
                    if (
                        voiceStyle.voices.hasOwnProperty(languageSelection.code) &&
                        voiceStyle.voices[languageSelection.code].hasOwnProperty(genderSelection.code) &&
                        voiceStyle.voices[languageSelection.code][genderSelection.code] !== undefined
                    ) {
                        const availableVocalCharacters: Array<string> =
                            voiceStyle.voices[languageSelection.code][genderSelection.code]
                        availableVocalCharacters.forEach((availableVocalCharacter) => {
                            if (!vocalCharacterList.includes(availableVocalCharacter)) {
                                vocalCharacterList.push(availableVocalCharacter)
                            }
                        })
                    }
                    return vocalCharacterList
                })
            } else {
                // limited to current voice style
                vocalCharacterList = voiceStyleSelection.voices[languageSelection.code][genderSelection.code]
            }
        } else {
            console.log('Vocal Characters: No language or gender selected, nothing to do here.')
        }
        if (outputToState) {
            this.vocalCharacterList = vocalCharacterList
            if (
                this.state.VocalCharacterSelection !== undefined &&
                !vocalCharacterList.includes(this.state.VocalCharacterSelection)
            ) {
                this.vocalCharacterSelection = undefined
                this.ctaButtonState = EButtonState.BLOCKED
            }
        }
        return vocalCharacterList
    }

    updateLanguages = ({
        genderSelection = this.state.GenderSelection,
        languageSelection = this.state.LanguageSelection,
        voiceStyleSelection = this.state.VoiceStyleSelection,
        outputToState = true,
    } = {}): Array<Language> => {
        // console.log("Updating languages with: gender: "+genderSelection?.label+", language: "+languageSelection?.label+", voiceStyle: "+voiceStyleSelection?.label)
        const languagesList: Array<Language> = this.languages.getAll().map((language: Language) => {
            // Check languages according to gender:
            if (genderSelection === undefined) {
                language.state = EButtonState.DEFAULT
            } else {
                // Enable or disable language button according to gender
                language[genderSelection.code] === true
                    ? (language.state = EButtonState.DEFAULT)
                    : (language.state = EButtonState.BLOCKED)
                if (language[genderSelection.code] === true) {
                    language.currentCompatibility.gender = true
                } else {
                    //console.log("Language "+language.label+" was blocked because it is not compatible with gender "+genderConstraint.label);
                    language.currentCompatibility.gender = false
                }
            }
            // Check Languages that are not yet blocked, according to voice style:
            if (
                voiceStyleSelection !== null &&
                voiceStyleSelection !== undefined &&
                language.state !== EButtonState.BLOCKED
            ) {
                // Enable or disable language button according to voice style
                if (
                    voiceStyleSelection.voices.hasOwnProperty(language.code) &&
                    voiceStyleSelection.voices[language.code] !== undefined
                ) {
                    language.currentCompatibility.voiceStyle = true
                } else {
                    language.state = EButtonState.BLOCKED
                    language.currentCompatibility.voiceStyle = false
                }
            }
            // Check active status
            if (languageSelection?.code === language.code && languageSelection?.state !== EButtonState.BLOCKED) {
                language.state = EButtonState.ACTIVE
            }
            return language
        })
        if (outputToState) {
            this.languagesList = languagesList
            var blockedLanguagesButtons: number = languagesList.filter(
                (language: Language) => language.state === EButtonState.BLOCKED,
            ).length
            this.availableLanguageQty = this.languages.quantity() - blockedLanguagesButtons
        }
        return languagesList
    }

    updateGenders = ({
        genderSelection = this.state.GenderSelection,
        languageSelection = this.state.LanguageSelection,
        voiceStyleSelection = this.state.VoiceStyleSelection,
        outputToState = true,
    } = {}): Array<Gender> => {
        //console.log("Updating genders List with: language: " + languageSelection?.label + ", gender: " + genderSelection?.label + ", voiceStyle: " + voiceStyleSelection?.label);
        const gendersList: Array<Gender> = this.genders.getAll().map((gender: Gender) => {
            // Check genders according to languages:
            if (languageSelection === undefined) {
                console.log('Undefined language selection')
                gender.state = EButtonState.DEFAULT
            } else {
                // Enable or disable gender button according to language
                if (languageSelection[gender.code] === true) {
                    gender.currentCompatibility.language = true
                    gender.state = EButtonState.DEFAULT
                } else {
                    //console.log("Gender "+gender.label+" was blocked because it is not compatible with language "+languageConstraint.label);
                    gender.currentCompatibility.language = false
                    gender.state = EButtonState.BLOCKED
                }
                // Active gender compatibility check
                if (genderSelection?.code === gender.code) {
                    if (languageSelection[gender.code] === true) {
                        gender.state = EButtonState.ACTIVE
                    } else {
                        this.genderSelection = undefined
                        //console.log("Gender "+gender.label+" was deselected because it is not compatible with language "+languageConstraint.label);
                    }
                }
            }
            // Check genders that are not yet blocked, according to voice style:
            if (voiceStyleSelection !== undefined && gender.state !== EButtonState.BLOCKED) {
                // Enable or disable gender button according to voice style
                if (languageSelection === undefined) {
                    // Check all languages
                    var voiceStyleHasGender = false
                    for (const language of this.languages.getAll()) {
                        if (
                            voiceStyleSelection.voices.hasOwnProperty(language.code) &&
                            voiceStyleSelection.voices[language.code].hasOwnProperty(gender.code) &&
                            voiceStyleSelection.voices[language.code][gender.code] !== undefined
                        ) {
                            voiceStyleHasGender = true
                            break
                        }
                    }
                    if (voiceStyleHasGender) {
                        gender.currentCompatibility.voiceStyle = true
                    } else {
                        gender.state = EButtonState.BLOCKED
                        //console.log("gender "+gender.label+" was blocked because it is not compatible with voice style "+voiceStyleConstraint.label+" and any language");
                        gender.currentCompatibility.voiceStyle = false
                    }
                } else {
                    // Does this voice style have this language ?
                    if (
                        !voiceStyleSelection.voices.hasOwnProperty(languageSelection.code) ||
                        voiceStyleSelection.voices[languageSelection.code] === undefined
                    ) {
                        gender.state = EButtonState.BLOCKED
                        gender.currentCompatibility.voiceStyle = false
                        //console.log("gender "+gender.label+" was blocked because it is not compatible with voice style "+voiceStyleConstraint.label+" and language "+languageConstraint.label);
                    } else {
                        // Does the language bound with this voice style have this gender ?
                        if (
                            !voiceStyleSelection.voices[languageSelection.code].hasOwnProperty(gender.code) ||
                            voiceStyleSelection.voices[languageSelection.code][gender.code] === undefined
                        ) {
                            gender.state = EButtonState.BLOCKED
                            gender.currentCompatibility.voiceStyle = false
                            //console.log("gender "+gender.label+" was blocked because it is not compatible with voice style "+voiceStyleConstraint.label+" and language "+languageConstraint.label);
                        } else {
                            gender.currentCompatibility.voiceStyle = true
                        }
                    }
                }
                // Active gender compatibility check
                if (genderSelection?.code === gender.code) {
                    gender.state = EButtonState.ACTIVE
                    /*
                    if (languageConstraint && voiceStyleConstraint.voices.hasOwnProperty(languageConstraint.code) && voiceStyleConstraint.voices[languageConstraint.code].hasOwnProperty(gender.code) && voiceStyleConstraint.voices[languageConstraint.code][gender.code] !== undefined) {
                        gender.state = ButtonState.ACTIVE;
                    }else{
                        setGenderSelection(undefined);
                        console.log("Gender "+gender.label+" was deselected because it is not compatible with voice style "+voiceStyleConstraint.label);
                    }
                    */
                }
            }
            return gender
        })
        if (outputToState) {
            this.gendersList = gendersList
            var blockedGenderButtons: number = gendersList.filter(
                (gender: Gender) => gender.state === EButtonState.BLOCKED,
            ).length
            this.availableGendersQty = this.genders.quantity() - blockedGenderButtons
        }
        return gendersList
    }

    updateVoiceStyles = ({
        genderSelection = this.state.GenderSelection,
        languageSelection = this.state.LanguageSelection,
        voiceStyleSelection = this.state.VoiceStyleSelection,
        outputToState = true,
    } = {}): Array<VoiceStyle> => {
        //console.log("updating voice style list with genderSelection="+genderSelection?.label+", languageSelection="+languageSelection?.label+", and voiceStyleSelection="+voiceStyleSelection?.label);
        const voiceStylesList: Array<VoiceStyle> = this.voiceStyles.getAll().map((voiceStyle: VoiceStyle) => {
            // Check voiceStyles according to languages:
            if (languageSelection === undefined) {
                voiceStyle.state = EButtonState.DEFAULT
            } else {
                // Enable or disable voiceStyle button according to language
                voiceStyle.voices.hasOwnProperty(languageSelection.code) &&
                voiceStyle.voices[languageSelection.code] !== undefined
                    ? (voiceStyle.state = EButtonState.DEFAULT)
                    : (voiceStyle.state = EButtonState.BLOCKED)
                if (
                    voiceStyle.voices.hasOwnProperty(languageSelection.code) &&
                    voiceStyle.voices[languageSelection.code] !== undefined
                ) {
                    voiceStyle.currentCompatibility.language = true
                } else {
                    voiceStyle.currentCompatibility.language = false
                    //console.log("VoiceStyle "+voiceStyle.label+" was blocked because it is not compatible with language "+languageSelection.label);
                }
                // Active voiceStyle compatibility check
                if (voiceStyleSelection?.code === voiceStyle.code) {
                    if (
                        voiceStyle.voices.hasOwnProperty(languageSelection.code) &&
                        voiceStyle.voices[languageSelection.code] !== undefined
                    ) {
                        voiceStyle.state = EButtonState.ACTIVE
                    } else {
                        this.voiceStyleSelection = this.defaultVoiceStyle
                        //console.log("VoiceStyle "+voiceStyle.label+" was deselected because it is not compatible with language "+languageSelection.label);
                    }
                }
            }
            // Check voiceStyles that are not yet blocked, according to gender:
            if (
                genderSelection !== null &&
                genderSelection !== undefined &&
                voiceStyle.state !== EButtonState.BLOCKED
            ) {
                // Enable or disable voiceStyle button according to gender
                if (languageSelection !== null && languageSelection !== undefined) {
                    if (
                        voiceStyle.voices.hasOwnProperty(languageSelection.code) &&
                        voiceStyle.voices[languageSelection.code] !== undefined
                    ) {
                        voiceStyle.currentCompatibility.language = true
                        if (
                            voiceStyle.voices[languageSelection.code].hasOwnProperty(genderSelection.code) &&
                            voiceStyle.voices[languageSelection.code][genderSelection.code] !== undefined
                        ) {
                            voiceStyle.currentCompatibility.gender = true
                        } else {
                            voiceStyle.state = EButtonState.BLOCKED
                            voiceStyle.currentCompatibility.gender = false
                        }
                    } else {
                        voiceStyle.state = EButtonState.BLOCKED
                        voiceStyle.currentCompatibility.language = false
                    }
                } else {
                    // check all languages for this voiceStyle
                    var voiceStyleHasGender = false
                    for (const language of this.languages.getAll()) {
                        if (
                            voiceStyle.voices.hasOwnProperty(language.code) &&
                            voiceStyle.voices[language.code].hasOwnProperty(genderSelection.code) &&
                            voiceStyle.voices[language.code][genderSelection.code] !== undefined
                        ) {
                            voiceStyleHasGender = true
                            break
                        }
                    }
                    if (voiceStyleHasGender) {
                        voiceStyle.currentCompatibility.gender = true
                    } else {
                        voiceStyle.state = EButtonState.BLOCKED
                        voiceStyle.currentCompatibility.gender = false
                        //console.log("VoiceStyle "+voiceStyle.label+" was blocked because it is not compatible with gender "+genderSelection.label);
                    }
                }
                // Active voiceStyle compatibility check
                if (voiceStyleSelection?.code === voiceStyle.code) {
                    if (voiceStyle.state !== EButtonState.BLOCKED) {
                        voiceStyle.state = EButtonState.ACTIVE
                    }
                    /*
                    if (languageSelection !== null && languageSelection !== undefined) { // Language is defined too, , let's check if the active gender exists for that language
                        if (voiceStyleSelection.voices.hasOwnProperty(languageSelection.code) && voiceStyleSelection.voices[languageSelection.code].hasOwnProperty(genderSelection.code) && voiceStyleSelection.voices[languageSelection.code][genderSelection.code] !== undefined) {
                            voiceStyleSelection.state = ButtonState.ACTIVE;
                        }else{
                            setVoiceStyleSelection(defaultVoiceStyle);
                            //console.log("VoiceStyle "+voiceStyle.label+" was deselected because it is not compatible with gender "+genderSelection.label);
                        }
                    }else{ // Language is not defined, let's check if the active gender exists for any language of the active voiceStyle
                        var voiceStyleSelectionHasGender = false;
                        for (const language of languages.getAll()) {
                            if(voiceStyleSelection.voices.hasOwnProperty(language.code) && voiceStyleSelection.voices[language.code].hasOwnProperty(genderSelection.code) && voiceStyleSelection.voices[language.code][genderSelection.code] !== undefined) {
                                voiceStyleSelectionHasGender = true;
                                break;
                            }
                        }
                        if (voiceStyleSelectionHasGender) {
                            voiceStyle.currentCompatibility.gender = true;
                        }else{
                            voiceStyle.state = ButtonState.BLOCKED;
                            voiceStyle.currentCompatibility.gender = false;
                            //console.log("VoiceStyle "+voiceStyle.label+" was deselected because it is not compatible with gender "+genderSelection.label);
                        }
                    }
                    */
                }
            }
            return voiceStyle
        })
        if (outputToState) {
            this.voiceStylesList = voiceStylesList
            this.updateVocalCharacters({ genderSelection, languageSelection, voiceStyleSelection })
            var blockedVoiceStyleButtons: number = voiceStylesList.filter(
                (voiceStyle: VoiceStyle) => voiceStyle.state === EButtonState.BLOCKED,
            ).length
            this.availableVoiceStylesQty = this.voiceStyles.quantity() - blockedVoiceStyleButtons
        }
        return voiceStylesList
    }

    updateCTAButtonState({
        genderSelection = this.state.GenderSelection,
        languageSelection = this.state.LanguageSelection,
        vocalCharacterSelection = this.state.VocalCharacterSelection,
    } = {}): void {
        var newCtaButtonState: EButtonState =
            languageSelection !== undefined && genderSelection !== undefined && vocalCharacterSelection !== undefined
                ? EButtonState.DEFAULT
                : EButtonState.BLOCKED
        this.ctaButtonState = newCtaButtonState
    }

    actionButtonsLeft: Array<JSX.Element> = [
        <IconLabelButton
            Name="cancel"
            State={EButtonState.DEFAULT}
            IconVector={IconVector.CLOSE}
            Label="Cancel"
            key={'modalActionLeft_' + uniqueId()}
            OnClick={() => {
                this.isModalOpened = false
            }}
        />,
    ]
    updateState(defaultValues?: {
        genderSelection: Gender | undefined
        languageSelection: Language | undefined
        voiceStyleSelection: VoiceStyle | undefined
    }): void {
        this.updateLanguages(defaultValues && defaultValues)
        this.updateGenders(defaultValues && defaultValues)
        this.updateVoiceStyles(defaultValues && defaultValues)
        this.updateVocalCharacters(defaultValues && defaultValues)
    }

    override componentDidMount() {
        this.updateState({
            genderSelection: this.defaultGender,
            languageSelection: this.defaultLanguage,
            voiceStyleSelection: this.defaultVoiceStyle,
        })
        if (this.state.LanguageSelection !== undefined)
            this.content = this.state.VoiceStyleSelection?.voices[this.state.LanguageSelection?.code].content
    }

    override componentDidUpdate(
        _prevProps: Readonly<HumanizerProps | undefined>,
        prevState: Readonly<HumanizerStates>,
        _snapshot?: any,
    ): void {
        if (
            prevState.LanguageSelection !== this.state.LanguageSelection ||
            prevState.GenderSelection !== this.state.GenderSelection ||
            prevState.VoiceStyleSelection !== this.state.VoiceStyleSelection ||
            prevState.VocalCharacterSelection !== this.state.VocalCharacterSelection ||
            prevState.Content.contentMode !== this.state.Content.contentMode
        ) {
            this.updateState()
            this.updateCTAButtonState()
            this.updateContent({})
        }
    }

    override render() {
        return (
            <div className="humanizer">
                {
                    <Modal
                        modal={{
                            open: this.state.Modal.isModalOpened,
                            template: 'custom',
                            closeWithBackground: true,
                            content: this.state.Modal.modalContent,
                            actionButtonsLeft: this.actionButtonsLeft,
                            actionButtonsRight: [this.state.Modal.modalAction],
                        }}
                        onClose={() => {
                            this.setState({ Modal: { ...this.state.Modal, isModalOpened: false } })
                        }}
                    />
                }

                {/********** CTA ********************************************************/}
                <div className="CTA">
                    <LabelButton
                        State={this.state.CtaButtonState}
                        Label={'Test'}
                        OnClick={() => {
                            if (this.state.VocalCharacterSelection !== undefined) {
                                let counter = 0
                                let content
                                if (this.state.Content.contentMode === ContentMode.custom) {
                                    if (this.inputRef.current?.value) content = String(this.inputRef.current.value)
                                } else content = this.state.Content.content
                                content = content.split(' ')
                                let output: string = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">`
                                output += '<voice name="'
                                // Language
                                output += this.state.LanguageSelection?.code + '-'
                                // Vocal character
                                output += this.state.VocalCharacterSelection! + '">'
                                // Voice style
                                if (this.state.VoiceStyleSelection !== undefined) {
                                    output += '<mstts:express-as style="' + this.state.VoiceStyleSelection.code + '" '
                                }
                                // Voice style intensity
                                if (this.state.VoiceStyle.voiceStyleIntensity !== undefined) {
                                    output += 'styledegree="' + this.state.VoiceStyle.voiceStyleIntensity.Value + '">'
                                }
                                // Gestures
                                if (this.state.GestureSelection !== undefined) {
                                    output += '<uneeq:' + this.state.GestureSelection.code + ' />'
                                    output += content[counter]
                                    counter++
                                }
                                // Camera
                                if (
                                    this.state.CameraSelection !== undefined &&
                                    this.state.PreviousCta.camera !== this.state.CameraSelection
                                ) {
                                    output += '<uneeq:' + this.state.CameraSelection?.code + ' />'
                                    output += content[counter]
                                    counter++
                                }
                                // Facial Emotion
                                if (this.state.FacialEmotion.facialEmotionSelection !== undefined) {
                                    output +=
                                        '<uneeq:emotion_' +
                                        this.state.FacialEmotion.facialEmotionSelection.code +
                                        '_' +
                                        this.state.FacialEmotion.facialEmotionIntensity?.Label +
                                        ' />'
                                    output += content[counter]
                                    counter++
                                }
                                // Content
                                output += content.slice(counter).join(' ')
                                // Closing tag
                                if (this.state.VoiceStyleSelection !== undefined) {
                                    output += '</mstts:express-as>'
                                }
                                output += '</voice> </speak>'
                                this.backendManager.sendSpeak(output, this.uneeqManager.getSessionId() as string, {
                                    direct: true,
                                    useDF: false,
                                })
                                if (this.state.CameraSelection !== undefined) this.cameraSelection = undefined
                                this.previousCta = {
                                    gesture: this.state.GestureSelection,
                                    camera: this.state.CameraSelection,
                                    facialEmotion: this.state.FacialEmotion,
                                }
                            }
                        }}
                    />
                </div>
                {/********** MAIN MENU ********************************************************/}
                <div className="menu">
                    <div className="baseline"></div>
                    {Object.values(MenuTab).map((value, key) => {
                        return (
                            <button
                                key={key}
                                className={this.state.MenuTab === value ? 'active' : ''}
                                onClick={() => {
                                    this.activeMenuTab = value
                                }}
                            >
                                {value}
                            </button>
                        )
                    })}
                </div>

                {/********** VOICE TAB ********************************************************/}
                <div className={this.state.MenuTab === MenuTab.VOICE ? 'tabContent active' : 'tabContent'}>
                    {/********** VOICE > SUBMENU **********************************************/}
                    <div className="subMenuContainer">
                        <p className="helper">*Required fields</p>
                        <div className="subMenu">
                            <div className="subMenuItem">
                                <p className="helper">Language*</p>
                                <button
                                    className={
                                        this.state.SubMenuTab === SubMenuTab.language
                                            ? 'active'
                                            : this.state.LanguageSelection === undefined
                                              ? 'required'
                                              : ''
                                    }
                                    onClick={() => {
                                        this.activeSubMenuTab = SubMenuTab.language
                                    }}
                                >
                                    <p className="label availability">
                                        {this.state.Language.availableLanguageQty}/{this.languages.quantity()} available
                                    </p>
                                    <p className="label">
                                        {this.state.LanguageSelection == null
                                            ? 'None'
                                            : this.state.LanguageSelection.label}
                                    </p>
                                </button>
                            </div>
                            <div className="subMenuItem">
                                <p className="helper">Gender*</p>
                                <button
                                    className={
                                        this.state.SubMenuTab === SubMenuTab.gender
                                            ? 'active'
                                            : this.state.GenderSelection === undefined
                                              ? 'required'
                                              : ''
                                    }
                                    onClick={() => {
                                        this.activeSubMenuTab = SubMenuTab.gender
                                    }}
                                >
                                    <p className="label availability">
                                        {this.state.Gender.availableGendersQty}/{this.genders.quantity()} available
                                    </p>
                                    <p className="label">
                                        {this.state.GenderSelection == null ? 'None' : this.state.GenderSelection.label}
                                    </p>
                                </button>
                            </div>
                            <div className="subMenuItem">
                                <p className="helper">Voice Style</p>
                                <button
                                    className={this.state.SubMenuTab === SubMenuTab.voiceStyle ? 'active' : ''}
                                    onClick={() => {
                                        this.activeSubMenuTab = SubMenuTab.voiceStyle
                                    }}
                                >
                                    <p className="label availability">
                                        {this.state.VoiceStyle.availableVoiceStylesQty}/{this.voiceStyles.quantity()}{' '}
                                        available
                                    </p>
                                    <p className="label">
                                        {this.state.VoiceStyleSelection == null
                                            ? 'None'
                                            : this.state.VoiceStyleSelection.label}
                                    </p>
                                </button>
                            </div>
                            <div className="subMenuItem">
                                <p className="helper">Vocal Character*</p>
                                <button
                                    className={
                                        this.state.SubMenuTab === SubMenuTab.vocalCharacter
                                            ? 'active'
                                            : this.state.VocalCharacterSelection === undefined
                                              ? 'required'
                                              : ''
                                    }
                                    onClick={() => {
                                        this.activeSubMenuTab = SubMenuTab.vocalCharacter
                                    }}
                                >
                                    <p className="label availability">
                                        {this.state.VocalCharacter.availableVocalCharactersQty} available
                                    </p>
                                    <p className="label">
                                        {this.state.VocalCharacterSelection == null
                                            ? 'None'
                                            : this.state.VocalCharacterSelection}
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/********** VOICE > LANGUAGES **********************************************/}
                    <div
                        key="voiceLanguages"
                        className={
                            this.state.SubMenuTab === SubMenuTab.language ? 'subTabContent active' : 'subTabContent'
                        }
                    >
                        <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                            {this.state.Language.languagesList.map((language: Language) => (
                                <a
                                    onClick={() => {
                                        if (language.state === EButtonState.BLOCKED) {
                                            this.blockedButtonClick(language)
                                        } else {
                                            this.languageSelection = language
                                        }
                                    }}
                                    key={language.code}
                                >
                                    <LabelButton
                                        key={'button_' + language.code}
                                        State={language.state}
                                        Label={language.label}
                                    />
                                </a>
                            ))}
                        </Group>
                    </div>

                    {/********** VOICE > GENDERS **********************************************/}
                    <div
                        key="voiceGenders"
                        className={
                            this.state.SubMenuTab === SubMenuTab.gender ? 'subTabContent active' : 'subTabContent'
                        }
                    >
                        <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                            {this.state.Gender.gendersList.map((gender: Gender) => {
                                return (
                                    <a
                                        onClick={() => {
                                            if (gender.state === EButtonState.BLOCKED) {
                                                this.blockedButtonClick(gender)
                                            } else {
                                                console.log('setting gender selection to ' + gender.label)
                                                this.genderSelection = gender
                                            }
                                        }}
                                        key={gender.code}
                                    >
                                        <LabelButton
                                            key={'button_' + gender.code + '_' + uniqueId()}
                                            State={gender.state}
                                            Label={gender.label}
                                        />
                                    </a>
                                )
                            })}
                        </Group>
                    </div>

                    {/********** VOICE > VOICE STYLES **********************************************/}
                    <div
                        key="voiceStyles"
                        className={
                            this.state.SubMenuTab === SubMenuTab.voiceStyle
                                ? 'subTabContent active voiceStyles'
                                : 'subTabContent voiceStyles'
                        }
                    >
                        <Group
                            alignment={GroupAlignment.right}
                            expands={false}
                            hasMargins={true}
                            isWrapped={false}
                            className="persistent"
                        >
                            <LabelButton
                                key={'button_no_vocalStyle_' + uniqueId()}
                                State={
                                    this.state.VoiceStyleSelection === undefined ||
                                    this.state.VoiceStyleSelection.code === 'no_vocalStyle'
                                        ? EButtonState.ACTIVE
                                        : EButtonState.DEFAULT
                                }
                                Label={'No voice style'}
                                OnClick={() => {
                                    if (this.state.VoiceStyleSelection !== undefined)
                                        this.voiceStyleSelection = undefined
                                }}
                            />
                        </Group>
                        <InputSlider
                            Items={vocalStyleIntensities.map((vocalStyleIntensity) => {
                                return {
                                    Label: vocalStyleIntensity.Label,
                                    Value: vocalStyleIntensity.Value,
                                    IsActive:
                                        vocalStyleIntensity.Value === this.state.VoiceStyle.voiceStyleIntensity?.Value
                                            ? true
                                            : false,
                                }
                            })}
                            OnChangeHandler={(activeItem) => (this.voiceStyleIntensity = activeItem)}
                            OnClickPrevHandler={(activeItem) => (this.voiceStyleIntensity = activeItem)}
                            OnClickNextHandler={(activeItem) => (this.voiceStyleIntensity = activeItem)}
                        />
                        <p className="helper">
                            {this.state.LanguageSelection != null || this.state.GenderSelection != null
                                ? this.state.LanguageSelection != null && this.state.GenderSelection != null
                                    ? 'Available options are set from ' +
                                      this.state.LanguageSelection.label +
                                      ' language and ' +
                                      this.state.GenderSelection.label +
                                      ' gender.'
                                    : this.state.LanguageSelection != null
                                      ? 'Available options are set from ' +
                                        this.state.LanguageSelection.label +
                                        ' language.'
                                      : 'Available options are set from ' +
                                        this.state.GenderSelection!.label +
                                        ' gender.'
                                : ''}
                        </p>
                        <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                            {this.state.VoiceStyle.voiceStylesList
                                .filter((element) => {
                                    return element.code !== 'no_vocalStyle'
                                })
                                .map((voiceStyle: VoiceStyle) => (
                                    <a
                                        onClick={() => {
                                            if (voiceStyle.state === EButtonState.BLOCKED) {
                                                this.blockedButtonClick(voiceStyle)
                                            } else {
                                                this.voiceStyleSelection = voiceStyle
                                            }
                                        }}
                                        key={voiceStyle.code}
                                    >
                                        <LabelButton
                                            key={'button_' + voiceStyle.code + '_' + uniqueId()}
                                            State={voiceStyle.state}
                                            Label={voiceStyle.label}
                                        />
                                    </a>
                                ))}
                        </Group>
                    </div>

                    {/********** VOICE > VOCAL CHARACTERS **********************************************/}
                    <div
                        key="voiceVocalCharacters"
                        className={
                            this.state.SubMenuTab === SubMenuTab.vocalCharacter
                                ? 'subTabContent active'
                                : 'subTabContent'
                        }
                    >
                        {this.state.LanguageSelection != null && this.state.GenderSelection != null ? (
                            <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                                {this.state.VocalCharacter.vocalCharacterList.map((vocalCharacter: string) => {
                                    return (
                                        <LabelButton
                                            key={'button_' + vocalCharacter + '_' + uniqueId()}
                                            State={
                                                vocalCharacter === this.state.VocalCharacterSelection
                                                    ? EButtonState.ACTIVE
                                                    : EButtonState.DEFAULT
                                            }
                                            Label={vocalCharacter}
                                            OnClick={() => {
                                                this.vocalCharacterSelection = vocalCharacter
                                            }}
                                        />
                                    )
                                })}
                            </Group>
                        ) : (
                            <div>
                                {this.state.LanguageSelection == null ? <Label Text="Language is required." /> : null}
                                {this.state.GenderSelection == null ? <Label Text="Gender is required." /> : null}
                            </div>
                        )}
                    </div>
                </div>

                {/********** FACIAL EMOTIONS **********************************************/}
                <div className={this.state.MenuTab === MenuTab.FACIALEMOTION ? 'tabContent active' : 'tabContent'}>
                    <Group
                        alignment={GroupAlignment.right}
                        expands={false}
                        hasMargins={true}
                        isWrapped={false}
                        className="persistent"
                    >
                        <IconLabelButton
                            key={'button_facialEmotionCancel_' + uniqueId()}
                            State={
                                this.state.FacialEmotion.facialEmotionSelection === undefined
                                    ? EButtonState.ACTIVE
                                    : EButtonState.DEFAULT
                            }
                            Label="No facial emotion"
                            IconVector={IconVector.CLOSE}
                            OnClick={() => {
                                if (this.state.FacialEmotion.facialEmotionSelection !== undefined)
                                    this.facialEmotionSelection = undefined
                            }}
                        />
                    </Group>
                    <InputSlider
                        Items={facialEmotionIntensities.map((facialEmotionIntensityOption) => {
                            return {
                                Label: facialEmotionIntensityOption.Label,
                                Value: facialEmotionIntensityOption.Value,
                                IsActive:
                                    facialEmotionIntensityOption.Value ===
                                    this.state.FacialEmotion.facialEmotionIntensity?.Value
                                        ? true
                                        : false,
                            }
                        })}
                        OnChangeHandler={(activeItem) => (this.facialEmotionIntensity = activeItem)}
                        OnClickPrevHandler={(activeItem) => (this.facialEmotionIntensity = activeItem)}
                        OnClickNextHandler={(activeItem) => (this.facialEmotionIntensity = activeItem)}
                    />
                    <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                        {this.facialEmotions.getAll().map((facialEmotion: FacialEmotion) => {
                            return (
                                <LabelButton
                                    key={'button_' + facialEmotion.code + '_' + uniqueId()}
                                    State={
                                        facialEmotion.code === this.state.FacialEmotion.facialEmotionSelection?.code
                                            ? EButtonState.ACTIVE
                                            : EButtonState.DEFAULT
                                    }
                                    Label={facialEmotion.label}
                                    OnClick={() => {
                                        this.facialEmotionSelection = facialEmotion
                                    }}
                                />
                            )
                        })}
                    </Group>
                </div>

                {/********** GESTURES **********************************************/}
                <div className={this.state.MenuTab === MenuTab.GESTURES ? 'tabContent active' : 'tabContent'}>
                    <Group
                        alignment={GroupAlignment.right}
                        expands={false}
                        hasMargins={true}
                        isWrapped={false}
                        className="persistent"
                    >
                        <IconLabelButton
                            key={'button_gestureCancel_' + uniqueId()}
                            State={
                                this.state.GestureSelection === undefined ? EButtonState.ACTIVE : EButtonState.DEFAULT
                            }
                            Label="No gesture"
                            IconVector={IconVector.CLOSE}
                            OnClick={() => {
                                if (this.state.GestureSelection !== undefined) this.gestureSelection = undefined
                            }}
                        />
                    </Group>
                    <p className="helper">
                        {this.state.GestureSelection !== undefined && this.state.GestureSelection.description !== '' ? (
                            <>
                                <span style={{ fontWeight: 'bold' }}>{this.state.GestureSelection.label}</span>, like{' '}
                                {this.state.GestureSelection.description}
                            </>
                        ) : (
                            ''
                        )}
                    </p>
                    <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                        {this.gestures.getAll().map((gesture: Gesture) => {
                            return (
                                <LabelButton
                                    key={'button_' + gesture.code + '_' + uniqueId()}
                                    State={
                                        gesture.code === this.state.GestureSelection?.code
                                            ? EButtonState.ACTIVE
                                            : EButtonState.DEFAULT
                                    }
                                    Label={gesture.label}
                                    OnClick={() => {
                                        this.gestureSelection = gesture
                                    }}
                                />
                            )
                        })}
                    </Group>
                </div>

                {/********** CAMERA **********************************************/}
                <div
                    className={this.state.MenuTab === MenuTab.CAMERA ? 'tabContent camera active' : 'tabContent camera'}
                >
                    <div className={'cameraPreview ' + this.state.CameraSelection?.code}>
                        <UneeqCamerasPreview />
                        <p className="helper">
                            {this.state.CameraSelection !== undefined &&
                            this.state.CameraSelection.description !== '' ? (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>{this.state.CameraSelection.label}</span>:
                                    <br />
                                    {this.state.CameraSelection.description}
                                </>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>
                    <Group alignment={GroupAlignment.left} expands={false} hasMargins={true} isWrapped={true}>
                        {this.cameras.getAll().map((camera: Camera) => (
                            <LabelButton
                                key={'button_' + camera.code + '_' + uniqueId()}
                                State={
                                    camera.code === this.state.CameraSelection?.code
                                        ? EButtonState.ACTIVE
                                        : EButtonState.DEFAULT
                                }
                                Label={camera.label}
                                OnClick={() => {
                                    this.cameraSelection = camera
                                }}
                            />
                        ))}
                    </Group>
                </div>

                {/********** CONTENT **********************************************/}
                <div
                    className={
                        this.state.MenuTab === MenuTab.CONTENT ? 'tabContent content active' : 'tabContent content'
                    }
                >
                    <ContentTab
                        content={this.state.Content.content}
                        contentMode={this.state.Content.contentMode}
                        languageSelection={this.state.LanguageSelection}
                        voiceStyleSelection={this.state.VoiceStyleSelection}
                        inputEnabled={this.props.inputEnabled}
                        inputRef={this.inputRef}
                        requiredContentLength={this.state.RequiredContentLength}
                        contentHandler={() => {
                            if (this.state.LanguageSelection !== undefined) {
                                this.updateContent({})
                                this.contentMode = ContentMode.default
                            }
                        }}
                        customContentHandler={() => {
                            this.contentMode = ContentMode.custom
                        }}
                        validator={(enabled: boolean) => {
                            this.ctaButtonState = enabled ? EButtonState.DEFAULT : EButtonState.BLOCKED
                        }}
                        focusHandler={() => {
                            this.contentMode = ContentMode.custom
                        }}
                    />
                </div>
            </div>
        )
    }
}
