import * as enums from '@Application/entities/enums'
import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarFinishSpeakingObserver extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Stop Speaking Observer - executed')

    error = (err: Error): void => console.error('Avatar Stop Speaking Observer', err)

    next(_value: unknown): void {
        // If the avatar was speaking, stop it.
        console.log('FinishedSpeaking message')
        if (this.services.InterfaceManager.inputBlockQueued) {
            setTimeout(() => {
                this.services.InterfaceManager.inputBlockQueued = false
                this.services.InterfaceManager.inputBlocked = false
            }, 100)
        }

        if (this.services.UneeqManager.avatarState === enums.EUneeqAvatarState.SPEAKING) {
            this.services.UneeqManager.avatarState = enums.EUneeqAvatarState.STANDBY
        }

        this.services.HistoryManager.stopMessage()
    }
}
