import React from 'react';

export interface DividerProps {
    Name?: string;
}

/** Bar is container that provider a simplified way to positioning other elements */
export class Divider extends React.Component<DividerProps, {}> {
    override render() {
        return <hr className={`divider ${this.props.Name ? this.props.Name : ''}`} />;
    }
}
