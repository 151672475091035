import React from 'react';

export enum VisibilityState {
    Visible,
    Hide,
}

export interface IElementProps {
    VisibilityState: VisibilityState;
}

export abstract class AbstractReactElement<T extends IElementProps, U extends any> extends React.Component<T, U> {}

