export class LayoutInfo {
    render() {
        return (
            <div className="layoutInfo">
                <ul>
                    <li className="visible">
                        <b>Layout and media info</b>
                    </li>
                    <li className="avatar">Avatar</li>
                    <li className="chat">Chat</li>
                    <li className="extra">Extra</li>
                    <li className="portrait">Portrait</li>
                    <li className="landscape">Landscape</li>
                    <li className="extraSmall">extraSmall</li>
                    <li className="small">small</li>
                    <li className="large">large</li>
                    <li className="extraLarge">extraLarge</li>
                </ul>
            </div>
        );
    }
}

export default LayoutInfo;

