import * as enums from '@Application/entities/enums'
import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarStartedSpeakingObserver extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Started Speaking Observer - executed')

    error = (err: Error): void => console.error('Avatar Started Speaking Observer', err)

    next(): void {
        this.services.UneeqManager.avatarState = enums.EUneeqAvatarState.SPEAKING
        this.services.HistoryManager.startMessage()
    }
}
