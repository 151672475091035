import React from 'react';
import { t } from 'i18next';
import * as enums from '@Application/entities/enums';
import { Label } from '@Components';

export interface LoadingBarProps {
    text: string;
    percentage: number;
}

export class LoadingBar extends React.Component<
    LoadingBarProps,
    {
        currentPercentage: number;
    }
> {
    constructor(props: LoadingBarProps) {
        super(props);
        this.state = {
            currentPercentage: this.props.percentage,
        };
    }

    override componentDidUpdate(prevProps: Readonly<LoadingBarProps>) {
        if (this.props.percentage !== prevProps.percentage) this.setState({ currentPercentage: this.props.percentage });
    }

    override render() {
        return (
            <div className="loadingBar">
                <Label Text={t(this.props.text as enums.ETranslation)} />
                <div className="bar" style={{ width: `${this.state.currentPercentage}%` }}></div>
            </div>
        );
    }
}
