// eslint-disable-next-line import/prefer-default-export
export enum EDigitalHumanState {
    AVATAR = 'avatar',
    EXTRA_CONTENT = 'extraContent',
    CHAT = 'chat',
    SIDE_PANEL = 'sidePanel',
    MEDIA = 'media',
    FULLSCREEN_PHOTO = 'fullscreenPhoto',
    FULLSCREEN_VIDEO = 'fullscreenVideo',
}
