import React from 'react'
import { CaptionState } from '@Components'
import ConfigManager from './configManager'

interface IConfig {
    captionStyle: CaptionState | string
    interfaceSize: number | undefined
}

export default class SettingManager extends React.Component<unknown, unknown> {
    private _lang: string
    private _captionStyle: CaptionState
    private _interfaceSize: number
    private _interfaceSizeRatio: number
    private _defaultInterfaceSize: number
    private constructor(props?: { lang?: string }) {
        super(props)

        this._defaultInterfaceSize = 1
        this._lang = SettingManager.getConfig('lang', props?.lang) as string
        this._captionStyle = SettingManager.getConfig('captionStyle', CaptionState.DISABLED) as CaptionState
        this._interfaceSize = SettingManager.getConfig('interfaceSize', 0) as number
        this._interfaceSizeRatio = 8
    }

    get lang(): string {
        return this._lang
    }

    set lang(lang: string) {
        this._lang = lang

        SettingManager.setConfig('lang', lang)
    }

    get captionStyle(): CaptionState {
        return this._captionStyle
    }

    set captionStyle(captionStyle: CaptionState) {
        SettingManager.setConfig('captionStyle', captionStyle)

        this._captionStyle = captionStyle
    }

    get interfaceSize(): number {
        return this._interfaceSize
    }

    set interfaceSize(interfaceSize: number) {
        SettingManager.setConfig('interfaceSize', interfaceSize)

        this._interfaceSize = interfaceSize
    }

    get interfaceSizeStyle(): string {
        return `${this._defaultInterfaceSize + this._interfaceSize / this._interfaceSizeRatio}em`
    }

    set defaultInterfaceSize(defaultInterfaceSize: number) {
        this._defaultInterfaceSize = defaultInterfaceSize
    }

    private static getConfig(name: string, defaultValue: unknown = undefined): unknown {
        const { config } = this.storage()

        return name ? (config[name] ?? defaultValue) : config
    }

    static getInstance(props?: { lang?: string }): SettingManager {
        if (!SettingManager.instance) {
            SettingManager.instance = new SettingManager(props)
        }

        return SettingManager.instance
    }

    private static setConfig(key: string, value): void {
        const { configName, config } = this.storage()

        if (value === undefined) {
            window.localStorage.removeItem(key)
        } else {
            config[key] = value

            window.localStorage.setItem(configName, JSON.stringify(config))
        }
    }

    private static instance: SettingManager

    private static storage(): {
        configName: string
        config: IConfig
    } {
        const avatar = ConfigManager.getInstance().config.content.AvatarName
        const configName = `${avatar}-config`
        const jsonValue = window.localStorage.getItem(configName) ?? '{}'
        const config = JSON.parse(jsonValue)

        return {
            configName,
            config,
        }
    }
}
