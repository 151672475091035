import * as enums from '@Application/entities/enums'
import { Message } from '@Entities'
import { Expose } from 'class-transformer'
import type { IPoll } from '@Entities'

export default class UserMessage {
    @Expose({ name: 'page' })
    page?: string

    @Expose({ name: 'dataType' })
    dataType?: enums.EDataType

    @Expose({ name: 'data' })
    data?: string | Message

    @Expose({ name: 'sessionId' })
    sessionId?: string

    @Expose({ name: 'language' })
    language?: string

    @Expose({ name: 'actor' })
    actor?: string

    @Expose({ name: 'direct' })
    direct?: boolean

    @Expose({ name: 'noUserActionDelay' })
    noUserActionDelay?: boolean

    @Expose({ name: 'poll' })
    poll?: IPoll
}
