import React, { useState } from 'react';
import * as enums from '@Application/entities/enums';
import { InterfaceManager } from '@Application/services';
import { IconButton, IconVector } from '@Components';
export interface PhotoControlsProps {
    ZoomIn: () => void;
    ZoomOut: () => void;
    SetZoom: (action: enums.EZoomAction) => void;
    Zoom: number;
    FullscreenHandler: (value: (...params: unknown[]) => void) => void;
}

const PhotoControls: React.FC<PhotoControlsProps> = ({ Zoom, ZoomIn, SetZoom, ZoomOut }) => {
    const [disabled, setDisabled] = useState(false);
    const interfaceManager = InterfaceManager.getInstance();
    const adjustZoom = (action: enums.EZoomAction) => {
        setDisabled(true);
        SetZoom(action);
        action === enums.EZoomAction.INCREASE ? ZoomIn() : ZoomOut();
        setTimeout(() => setDisabled(false), 300);
    };
    return (
        <div className="photoControls">
            <div
                className="item"
                onClick={() => {
                    console.log('Closing gallery');
                    interfaceManager.closeMediaGallery();
                }}
            >
                <IconButton Name="close" IconVector={IconVector.CLOSE} />
            </div>
            <div
                className="item"
                onClick={() => {
                    interfaceManager.toggleMediaGalleryFullscreen();
                }}
            >
                <IconButton Name="fullscreen" IconVector={IconVector.EXPAND} />
            </div>
            <div
                className={`item${Zoom >= 4 ? ' disabled' : ''}`}
                onClick={() => {
                    if (!disabled && Zoom < 4) adjustZoom(enums.EZoomAction.INCREASE);
                    else console.log('Maximum zoom value reached');
                }}
            >
                <IconButton Name="zoom" IconVector={IconVector.ZOOM_IN} />
            </div>
            <div
                className={`item${Zoom === 0 ? ' disabled' : ''}`}
                onClick={() => {
                    if (!disabled && Zoom > 0) adjustZoom(enums.EZoomAction.DECREASE);
                    else console.log('Minimum zoom value reached');
                }}
            >
                <IconButton Name={`zoom`} IconVector={IconVector.ZOOM_OUT} />
            </div>
        </div>
    );
};

export default PhotoControls;
