import { useEffect, useState } from 'react'
import { Caption } from '@Components'
import type * as types from '@Entities/interfaces/services'
import * as enums from '@Application/entities/enums'
import { Message } from '@Application/entities'

export interface CaptionControlProps {
    Services: types.IServices
}

function CaptionController(props: CaptionControlProps) {
    // CurrentFrame state
    const [message, setMessage] = useState<string>('')
    const [digitalHumanState, setDigitalHumanState] = useState(
        props.Services.InterfaceManager.getCurrentDigitalHumanState(),
    )

    const registerRecordHandler = () => {
        props.Services.HistoryManager.onMessageStart((message: Message) => {
            if (!message.element?.mute) {
                const delay = message.element?.delay

                if (delay) {
                    setTimeout(() => {
                        setMessage(message.element?.label as string)
                    }, delay)
                } else {
                    setMessage(message.element?.label as string)
                }
            }
        })

        props.Services.HistoryManager.onMessageStop(_ => {
            setMessage('')
        })
    }

    useEffect(() => {
        registerRecordHandler()
    }, [])

    useEffect(() => {
        setDigitalHumanState(props.Services.InterfaceManager.getCurrentDigitalHumanState())
    }, [props.Services.InterfaceManager.getCurrentDigitalHumanState()])

    return (
        <Caption
            State={props.Services.SettingManager.captionStyle}
            AvatarMessage={message}
            CaptionlessLayout={digitalHumanState === enums.EDigitalHumanState.CHAT}
        />
    )
}

export default CaptionController
