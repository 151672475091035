import React, { PropsWithChildren } from 'react';

export class DigitalHumanContainer extends React.Component<
    PropsWithChildren<{
        State: string;
        Hidden?: boolean;
    }>,
    {}
> {
    override render() {
        return (
            <div className={`digitalHuman ${this.props.State} ${this.props.Hidden === true ? 'hidden' : ''}`}>
                {this.props.children}
            </div>
        );
    }
}
