import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import * as enums from '@Application/entities/enums';
import { ConfigManager } from '@Application/services';
import {
    Bar,
    Bubble,
    EBubbleState,
    EButtonState,
    CheckButton,
    DropdownAlignment,
    Group,
    GroupAlignment,
    IconLabelButton,
    IconVector,
    Label,
    MainContainer,
} from '@Components';

import DropdownController, {
    DropdownTemplate,
} from '@Application/controllers/contentControllers/DropdownController';

export function InitialScreen({ services, start, mode, lang }) {
    const [isStartDisabled, setIsStartDisabled] = useState(true);
    const config = ConfigManager.getInstance().config;
    const startRef = useRef<HTMLDivElement | null>(null);

    let dropdownTrigger = config.components.dropdown.languageSelection.trigger.icon;
    const triggerFlag = dropdownTrigger === 'flag';
    dropdownTrigger = (triggerFlag ? dropdownTrigger + '_' + lang : dropdownTrigger).toUpperCase();

    const handleClick = () => {
        isStartDisabled && startRef.current?.scrollIntoView({ behavior: 'smooth' });
        setIsStartDisabled((isStartDisabled) => !isStartDisabled);
    };

    switch (mode) {
        case enums.ELandingPage.DEFAULT:
            return (
                <MainContainer
                    key="InitialController"
                    Name={`InitialController`}
                    Visible={true}
                    Top={[
                        <Bar
                            Name="topActionBar"
                            Right={[
                                config.modules.i18n.enabled && (
                                    <DropdownController
                                        Services={services}
                                        key="LanguageSelector"
                                        Icon={IconVector[dropdownTrigger]}
                                        IconName={`LanguageSelector${triggerFlag ? ' flag' : ''}`}
                                        Alignment={DropdownAlignment.BOTTOM_LEFT}
                                        Template={DropdownTemplate.LANGUAGE_SELECTION}
                                    />
                                ),
                                <DropdownController
                                    Services={services}
                                    key="SettingControl"
                                    Icon={IconVector.TEXT_SIZE}
                                    IconName="SettingControl"
                                    Alignment={DropdownAlignment.BOTTOM_LEFT}
                                    Template={DropdownTemplate.INTERFACE_SIZE_NO_BAR}
                                />,
                            ]}
                        />,
                    ]}
                    Main={[
                        <div className="contentArea noFooter">
                            <div className="contentScrollArea scrollTop" id="contentScrollArea">
                                <div className="contentGroup">
                                    <Group
                                        alignment={GroupAlignment.left}
                                        expands={false}
                                        hasMargins={false}
                                        isWrapped={false}
                                        HtmlRef={React.createRef<HTMLInputElement>()}
                                    >
                                        {/* @ts-ignore */}
                                        {t(enums.ETranslation.HOMESCREEN_BUBBLES, { returnObjects: true }).map(
                                            (label: { text: string }, idx: string) => (
                                                <Bubble
                                                    Actor={enums.EActor.AVATAR}
                                                    Label={label.text}
                                                    key={`initial_bubbles_${idx}`}
                                                    State={EBubbleState.ACTIVE}
                                                />
                                            ),
                                        )}
                                    </Group>
                                    <Group
                                        alignment={GroupAlignment.right}
                                        expands={false}
                                        hasMargins={true}
                                        isWrapped={false}
                                        HtmlRef={React.createRef<HTMLInputElement>()}
                                    >
                                        <CheckButton
                                            State={EButtonState.DEFAULT}
                                            Name="editorial"
                                            Label={enums.ETranslation.HOMESCREEN_AGREEMENT}
                                            Checked={false}
                                            OnClick={handleClick}
                                        />
                                        <div
                                            ref={startRef}
                                            style={{ visibility: isStartDisabled ? 'hidden' : 'visible' }}
                                            className="editorial"
                                        >
                                            <IconLabelButton
                                                Label={enums.ETranslation.HOMESCREEN_START_BUTTON}
                                                IconVector={IconVector.CHEVRON_RIGHT}
                                                State={EButtonState.DEFAULT}
                                                OnClick={start}
                                                Name="editorial start"
                                            />
                                        </div>
                                    </Group>
                                </div>
                            </div>
                        </div>,
                    ]}
                    Bottom={[]}
                />
            );
        case enums.ELandingPage.VIDEO:
            return (
                <div className="initialScreen video">
                    <video
                        className="video"
                        autoPlay
                        loop
                        playsInline
                        src={config.components?.initialController?.video?.language[lang] as string}
                    />
                    <div className="initialOverlay" />
                    <MainContainer
                        Top={[]}
                        Main={[
                            <div className="contentArea initial extra">
                                <div className="contentScrollArea scrollTop" id="contentScrollArea">
                                    <div className="contentGroup">
                                        <Group
                                            alignment={GroupAlignment.right}
                                            expands={true}
                                            hasMargins={false}
                                            isWrapped={false}
                                            HtmlRef={React.createRef<HTMLInputElement>()}
                                        >
                                            <DropdownController
                                                Template={DropdownTemplate.LANGUAGE_SELECTION}
                                                Services={services}
                                                Icon={IconVector[dropdownTrigger]}
                                                Alignment={DropdownAlignment.TOP_LEFT}
                                                IconName={`dropdownInitial${triggerFlag ? ' flag' : ''}`}
                                            />
                                            <Label Name={'initialTitle'} Text={enums.ETranslation.HOMESCREEN_TITLE} />
                                            <Label
                                                Name={'initialSubtitle'}
                                                Text={enums.ETranslation.HOMESCREEN_SUBTITLE}
                                            />
                                            <IconLabelButton
                                                State={EButtonState.DEFAULT}
                                                Label={enums.ETranslation.HOMESCREEN_START_BUTTON}
                                                Name="editorial start"
                                                IconVector={IconVector.CHEVRON_RIGHT}
                                                OnClick={start}
                                            />
                                        </Group>
                                    </div>
                                </div>
                            </div>,
                        ]}
                        Bottom={[]}
                        Visible={true}
                    />
                </div>
            );
        default:
            return <></>;
    }
}
