export class Compatibility {
    public gender: boolean | undefined;
    public language: boolean | undefined;
    public voiceStyle: boolean | undefined;

    constructor(
        gender: boolean | undefined = undefined,
        language: boolean | undefined = undefined,
        voiceStyle: boolean | undefined = undefined,
    ) {
        this.gender = gender;
        this.language = language;
        this.voiceStyle = voiceStyle;
    }
}

