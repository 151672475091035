import { EButtonState } from '../../button/buttonState';
import { genders } from '../data/constants';
import { Compatibility } from './compatibility';

export class Genders {
    public getAll(): Array<Gender> {
        const arrayOfGenders: Array<Gender> = Object.keys(genders).map((code: string) => {
            const label: string = genders[code];
            return new Gender(code, label);
        });
        return arrayOfGenders;
    }

    public getDefaultGender(defaultGenderCode: string): Gender | undefined {
        return this.getAll().filter((gender: Gender) => gender.code === defaultGenderCode)[0];
    }

    public quantity(): number {
        return this.getAll().length;
    }
}

export class Gender {
    public code: string;
    public label: string;
    public state: EButtonState;
    public currentCompatibility: Compatibility;

    constructor(
        code: string,
        label: string,
        state: EButtonState = EButtonState.DEFAULT,
        currentCompatibility: Compatibility = new Compatibility(),
    ) {
        this.code = code;
        this.label = label;
        this.state = state;
        this.currentCompatibility = currentCompatibility;
    }
}
