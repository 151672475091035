import { Message } from '@Entities'
import { plainToInstance } from 'class-transformer'
import UneeqEventObserver from './uneeqEventObserver'

export default class AvatarAnswerObserver extends UneeqEventObserver {
    complete = (): void => console.log('Avatar Answer Observer - executed')
    error = (err: Error): void => console.error('Avatar Answer Observer', err)

    next(value: { answerAvatar: string }): void {
        if (!value.answerAvatar) console.warn('No answerAvatar was found in the response')

        const message = plainToInstance(Message, (JSON.parse(value.answerAvatar) as { instructions }).instructions)

        this.services.HistoryManager.pushMessage(message)

        if (!message.speech) this.services.HistoryManager.startMessage()
    }
}
