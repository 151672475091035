import { Highlight, LinkContainer, LinkProps, EButtonState, Label } from '@Components';
import * as enums from '@Application/entities/enums';
import React, { MouseEventHandler } from 'react';

export interface LabelButtonProps {
    State: EButtonState;
    Label: enums.ETranslation | string;
    Name?: string;
    LinkProps?: LinkProps;
    OnClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface LabelButtonStates {
    PreviousState: EButtonState;
    State: EButtonState;
    Highlighted: boolean;
    LinkProps?: LinkProps;
    Ref: React.RefObject<HTMLButtonElement>;
}

export default class LabelButton extends React.Component<LabelButtonProps, LabelButtonStates> {
    constructor(props: LabelButtonProps) {
        super(props);
        this.state = {
            PreviousState: this.props.State,
            State: this.props.State,
            Highlighted: false,
            LinkProps: this.props.LinkProps,
            Ref: React.createRef(),
        };
    }

    override componentDidMount() {
        this.setState({
            PreviousState: EButtonState.DEFAULT,
            State: this.props.State,
        });
    }

    override componentDidUpdate(prevProps: Readonly<LabelButtonProps>) {
        if (this.props !== prevProps) {
            this.setState({
                PreviousState: this.props.State,
                State: this.props.State,
            });
        }
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            PreviousState: this.state.State,
            State: EButtonState.HOVER,
        });
    };

    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            State: this.state.PreviousState,
        });
    };

    override render() {
        return (
            <Highlight
                Name="box"
                Highlighted={this.state.Highlighted}
                Child={
                    <LinkContainer
                        Name={'LabelButtonLink'}
                        Href={this.state.LinkProps?.Href}
                        Target={this.state.LinkProps?.Target}
                        Child={
                            <button
                                onMouseEnter={this.onHover}
                                onMouseLeave={this.onLeave}
                                disabled={this.state.State === EButtonState.BLOCKED}
                                ref={this.state.Ref}
                                className={`labelButton ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                                onClick={this.props.OnClick}
                            >
                                <div className="container">
                                    <Label Text={this.props.Label} />
                                </div>
                            </button>
                        }
                    />
                }
            />
        );
    }
}
