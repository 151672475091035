import { IElement } from '@Entities/interfaces'
import React, { Component, createRef, Key, RefObject, JSXElementConstructor, FunctionComponent } from 'react'
import { IconLabelButtonProps, LabelButton, LabelButtonProps } from '../button/index'
import { Bubble, BubbleProps } from '../bubble/Bubble'
import { Incrementer, IncrementerProps } from '../input/incrementer/Incrementer'
import * as enums from '@Application/entities/enums'
import classNames from 'classnames'
import { IContentElement } from '@Application/entities/models/elements/contentElementFactory'

export enum GroupAlignment {
    center = 'center',
    left = 'left',
    right = 'right',
}

export enum FlexDirection {
    column = 'column',
    row = 'row',
}

export interface GroupProps {
    alignment: GroupAlignment.center | GroupAlignment.left | GroupAlignment.right
    expands: boolean
    hasMargins: boolean
    flexDirection?: FlexDirection
    isWrapped?: boolean
    HtmlRef?: RefObject<HTMLInputElement>
    children?: React.ReactNode
    Elements?: ElementReference[]
    Visible?: boolean
    name?: string
    className?: string
}

interface GroupState {
    Elements: ElementReference[]
    Visible: boolean
}

export interface ContentElementProps {
    frameElement: IElement
    component: LabelButton | Bubble | Incrementer | JSXElementConstructor<any>
    props: LabelButtonProps | IconLabelButtonProps | BubbleProps | IncrementerProps | any
}

export interface ElementReference {
    idx: number
    id: string | null
    type: enums.EElementType
    key: Key | null
    content: React.ReactNode
    frameElement: IElement
    getRef: () => LabelButton | Bubble | Incrementer
}

export class Group extends Component<GroupProps, GroupState> {
    constructor(props: GroupProps) {
        super(props)
        this.state = {
            Visible: this.props.Visible || true,
            Elements: this.props.Elements || [],
        }
    }

    private renderElement = async (
        type: enums.EElementType,
        elementModel: IElement,
        elementReact: React.ReactNode,
        ref: RefObject<any>,
    ): Promise<ElementReference> => {
        const newElementReference: ElementReference = {
            idx: this.state.Elements.length,
            id: React.isValidElement(elementReact) ? elementReact.key : '',
            key: React.isValidElement(elementReact) ? elementReact.key : null,
            type,
            frameElement: elementModel,
            content: elementReact,
            getRef: () => {
                if (!ref.current) throw new Error('groupRef.current is null')
                return ref.current
            },
        }
        return new Promise((resolve) =>
            this.setState(
                (prevState) => ({
                    Elements: [...prevState.Elements, newElementReference],
                }),
                () => {
                    resolve(newElementReference)
                },
            ),
        )
    }

    getAllElementByType = (type: enums.EElementType): ElementReference[] => {
        return this.state.Elements.filter((element) => element.type === type)
    }

    getElement = (element: IElement): ElementReference | undefined => {
        return this.state.Elements.find((e) => e.id === element.id)
    }

    cleanHighlights = () => {
        const promises: Promise<boolean>[] = []
        this.state.Elements.forEach((element) => {
            const elementRef = element.getRef()
            if (elementRef) {
                promises.push(
                    new Promise<boolean>((resolve) => {
                        // @ts-ignore
                        elementRef.setState({ Highlighted: false }, () => {
                            resolve(true)
                        })
                    }),
                )
            }
        })
        return Promise.all(promises)
    }

    addElement = async <T extends enums.EElementType>(
        params: IContentElement<T>,
    ): Promise<ElementReference | undefined> => {
        if (!params) return Promise.resolve(undefined)
        const ref = createRef<typeof params.component>()
        const elementModel: IElement = params.element
        // #TODO Not sure if possible, but creating completly generic element might be impossible, due to react's limitations and requirements to have properly marked values
        const reactElement = React.createElement(
            params.component as unknown as FunctionComponent<{ ref: RefObject<unknown> }>,
            {
                ref,
                ...(params.props as Record<string, unknown>),
            },
        )
        return this.renderElement(params.type, elementModel, reactElement, ref)
    }

    override render(): React.ReactNode {
        const groupClass = classNames({
            left: this.props.alignment === GroupAlignment.left,
            right: this.props.alignment === GroupAlignment.right,
            center: this.props.alignment === GroupAlignment.center,
            expands: this.props.expands,
            hasFixedWidthChildren: !this.props.expands,
            hasMargins: this.props.hasMargins,
            isWrapped: this.props.isWrapped,
            row: this.props.flexDirection === FlexDirection.row,
        })

        return (
            <div className={`group${this.props.className ? ' ' + this.props.className : ''} ${groupClass}`}>
                {this.state.Elements.map((element) => element.content)}
                {this.props.children}
            </div>
        )
    }
}
