import { RefObject, useState } from 'react'
import { ContentMode } from './Humanizer'
import { Language } from './models/languages'
import { EButtonState, Label, RadioButton, TextInput, TextInputState } from '@Components'
import { uniqueId } from 'lodash'
import { VoiceStyle } from './models/voiceStyles'
const Numbers = ['one', 'one', 'two', 'three']

interface ContentTabProps {
    content: string
    contentMode: ContentMode | undefined
    languageSelection: Language | undefined
    voiceStyleSelection: VoiceStyle | undefined
    inputEnabled: boolean | undefined
    contentHandler: () => void
    customContentHandler: () => void
    focusHandler: () => void
    inputRef: RefObject<HTMLInputElement>
    requiredContentLength: number
    validator: (enabled: boolean) => void
}

function ContentTab({
    content,
    contentMode,
    languageSelection,
    voiceStyleSelection,
    inputEnabled,
    inputRef,
    requiredContentLength,
    validator,
    contentHandler,
    customContentHandler,
    focusHandler,
}: ContentTabProps) {
    const [inputValue, setInputValue] = useState('')

    const updateInput = (value: string) => {
        setInputValue(value)
        const trimmed = value?.trim().split(' ')
        validator((trimmed[0]! && trimmed.length >= requiredContentLength) as boolean)
    }
    return (
        <>
            <RadioButton
                key={'button_contentMode_default_' + uniqueId()}
                State={
                    languageSelection === undefined
                        ? EButtonState.BLOCKED
                        : contentMode === ContentMode.default
                          ? EButtonState.ACTIVE
                          : EButtonState.DEFAULT
                }
                Checked={contentMode === ContentMode.default && languageSelection !== undefined}
                Label={'Automatic content, provided by the current voice style (' + voiceStyleSelection?.label + ')'}
                OnClick={contentHandler}
                Name="content"
            />

            <div className={'gridContainer'}>
                {inputEnabled !== false && (
                    <>
                        <div className="form">
                            <RadioButton
                                key={'button_contentMode_default_' + uniqueId()}
                                State={
                                    languageSelection === undefined || contentMode === ContentMode.custom
                                        ? EButtonState.ACTIVE
                                        : EButtonState.DEFAULT
                                }
                                Label={'Custom content'}
                                Checked={languageSelection === undefined || contentMode === ContentMode.custom}
                                OnClick={customContentHandler}
                                Name="customHumanizerContent"
                            />
                            <TextInput
                                State={
                                    contentMode === ContentMode.custom ? TextInputState.ACTIVE : TextInputState.DISABLED
                                }
                                Placeholder="Enter your custom content here"
                                OnFocus={focusHandler}
                                Ref={inputRef}
                                Handler={updateInput}
                            />
                            {contentMode === ContentMode.custom &&
                                (!inputValue || inputValue?.trim().split(' ').length < requiredContentLength) && (
                                    <div className={'error'}>
                                        <Label
                                            Name="error basic"
                                            Text={`At least **${Numbers[requiredContentLength]}** word${requiredContentLength <= 1 ? '' : 's'} required`}
                                        />
                                    </div>
                                )}
                        </div>

                        {languageSelection !== undefined && (
                            <Label Text={`Your custom text should be written in ${languageSelection.label}`} />
                        )}
                    </>
                )}
                {contentMode === ContentMode.default && <Label Name="preview basic" Text={`*"${content}"*`} />}
            </div>
        </>
    )
}

export default ContentTab
