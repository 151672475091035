import { EButtonState } from '@Components';
import { uneeqActions } from '../data/constants';

export class Gesture {
    public code: string;
    public label: string;
    public description: string;
    public state: EButtonState;

    constructor(code: string, label: string, description: string, state: EButtonState = EButtonState.DEFAULT) {
        this.code = code;
        this.label = label;
        this.state = state;
        this.description = description;
    }
}

export class Gestures {
    public getAll(): Array<Gesture> {
        const arrayOfGestures: Array<Gesture> = Object.keys(uneeqActions).map((code: string) => {
            const label: string = uneeqActions[code].label;
            const description: string =
                uneeqActions[code].hasOwnProperty('description') && uneeqActions[code].description !== undefined
                    ? uneeqActions[code].description
                    : '';
            return new Gesture(code, label, description);
        });
        return arrayOfGestures;
    }
}
