export const vocalStyleIntensities = [
    {
        Label: "Weak",
        Value: 0
    },
    {
        Label: "Normal",
        Value: 1
    },
    {
        Label: "Strong",
        Value: 2
    }
];

export const genders = {
    male: 'Male',
    female: 'Female',
    femaleChild: 'Female Child',
    neutral: 'Neutral',
}
/*
export const ages = {
    child: 'Child',
    adult: 'Adult',
    youngAdult: 'Young Adult',
    olderAdult: 'Older Adult',
    senior: 'Senior',
}
*/
export const languages = {

    'af-ZA': {
        label: 'Afrikaans (South Africa)', femaleVoices: ['AdriNeural',
        ], maleVoices: ['WillemNeural',
        ],
    }, 'am-ET': {
        label: 'Amharic (Ethiopia)', femaleVoices: ['MekdesNeural',
        ], maleVoices: ['AmehaNeural',
        ],
    }, 'ar-AE': {
        label: 'Arabic (United Arab Emirates)', femaleVoices: ['FatimaNeural',
        ], maleVoices: ['HamdanNeural',
        ],
    }, 'ar-BH': {
        label: 'Arabic (Bahrain)', femaleVoices: ['LailaNeural',
        ], maleVoices: ['AliNeural',
        ],
    }, 'ar-DZ': {
        label: 'Arabic (Algeria)', femaleVoices: ['AminaNeural',
        ], maleVoices: ['IsmaelNeural',
        ],
    }, 'ar-EG': {
        label: 'Arabic (Egypt)', femaleVoices: ['SalmaNeural',
        ], maleVoices: ['ShakirNeural',
        ],
    }, 'ar-IQ': {
        label: 'Arabic (Iraq)', femaleVoices: ['RanaNeural',
        ], maleVoices: ['BasselNeural',
        ],
    }, 'ar-JO': {
        label: 'Arabic (Jordan)', femaleVoices: ['SanaNeural',
        ], maleVoices: ['TaimNeural',
        ],
    }, 'ar-KW': {
        label: 'Arabic (Kuwait)', femaleVoices: ['NouraNeural',
        ], maleVoices: ['FahedNeural',
        ],
    }, 'ar-LB': {
        label: 'Arabic (Lebanon)', femaleVoices: ['LaylaNeural',
        ], maleVoices: ['RamiNeural',
        ],
    }, 'ar-LY': {
        label: 'Arabic (Libya)', femaleVoices: ['ImanNeural',
        ], maleVoices: ['OmarNeural',
        ],
    }, 'ar-MA': {
        label: 'Arabic (Morocco)', femaleVoices: ['MounaNeural',
        ], maleVoices: ['JamalNeural',
        ],
    }, 'ar-OM': {
        label: 'Arabic (Oman)', femaleVoices: ['AyshaNeural',
        ], maleVoices: ['AbdullahNeural',
        ],
    }, 'ar-QA': {
        label: 'Arabic (Qatar)', femaleVoices: ['AmalNeural',
        ], maleVoices: ['MoazNeural',
        ],
    }, 'ar-SA': {
        label: 'Arabic (Saudi Arabia)', femaleVoices: ['ZariyahNeural',
        ], maleVoices: ['HamedNeural',
        ],
    }, 'ar-SY': {
        label: 'Arabic (Syria)', femaleVoices: ['AmanyNeural',
        ], maleVoices: ['LaithNeural',
        ],
    }, 'ar-TN': {
        label: 'Arabic (Tunisia)', femaleVoices: ['ReemNeural',
        ], maleVoices: ['HediNeural',
        ],
    }, 'ar-YE': {
        label: 'Arabic (Yemen)', femaleVoices: ['MaryamNeural',
        ], maleVoices: ['SalehNeural',
        ],
    }, 'az-AZ': {
        label: 'Azerbaijani (Azerbaijan)', femaleVoices: ['BanuNeural',
        ], maleVoices: ['BabekNeural',
        ],
    }, 'bg-BG': {
        label: 'Bulgarian (Bulgaria)', femaleVoices: ['KalinaNeural',
        ], maleVoices: ['BorislavNeural',
        ],
    }, 'bn-BD': {
        label: 'Bangla (Bangladesh)', femaleVoices: ['NabanitaNeural',
        ], maleVoices: ['PradeepNeural',
        ],
    }, 'bn-IN': {
        label: 'Bengali (India)', femaleVoices: ['TanishaaNeural',
        ], maleVoices: ['BashkarNeural',
        ],
    }, 'bs-BA': {
        label: 'Bosnian (Bosnia and Herzegovina)', femaleVoices: ['VesnaNeural',
        ], maleVoices: ['GoranNeural',
        ],
    }, 'ca-ES': {
        label: 'Catalan', femaleVoices: ['JoanaNeural',
            'AlbaNeural',
        ], maleVoices: ['EnricNeural',
        ],
    }, 'cs-CZ': {
        label: 'Czech (Czechia)', femaleVoices: ['VlastaNeural',
        ], maleVoices: ['AntoninNeural',
        ],
    }, 'cy-GB': {
        label: 'Welsh (United Kingdom)', femaleVoices: ['NiaNeural',
        ], maleVoices: ['AledNeural',
        ],
    }, 'da-DK': {
        label: 'Danish (Denmark)', femaleVoices: ['ChristelNeural',
        ], maleVoices: ['JeppeNeural',
        ],
    }, 'de-AT': {
        label: 'German (Austria)', femaleVoices: ['IngridNeural',
        ], maleVoices: ['JonasNeural',
        ],
    },
    'de-CH': {
        label: 'German (Switzerland)',
        femaleVoices: ['LeniNeural',],
        maleVoices: ['JanNeural',],
    },
    'de-DE': {
        label: 'German (Germany)', femaleVoices: ['KatjaNeural',
            'AmalaNeural',
            'ElkeNeural',
            'KlarissaNeural',
            'LouisaNeural',
            'MajaNeural',
            'TanjaNeural',
            'SeraphinaMultilingualNeural',
        ], femaleChildVoices: ['GiselaNeural',
        ], maleVoices: ['ConradNeural',
            'BerndNeural',
            'ChristophNeural',
            'KasperNeural',
            'KillianNeural',
            'KlausNeural',
            'RalfNeural',
            'FlorianMultilingualNeural',
        ],
    }, 'el-GR': {
        label: 'Greek (Greece)', femaleVoices: ['AthinaNeural',
        ], maleVoices: ['NestorasNeural',
        ],
    }, 'en-AU': {
        label: 'English (Australia)', femaleVoices: ['NatashaNeural',
            'AnnetteNeural',
            'CarlyNeural',
            'ElsieNeural',
            'FreyaNeural',
            'JoanneNeural',
            'KimNeural',
            'TinaNeural',
        ], maleVoices: ['WilliamNeural',
            'DarrenNeural',
            'DuncanNeural',
            'KenNeural',
            'NeilNeural',
            'TimNeural',
        ],
    }, 'en-CA': {
        label: 'English (Canada)', femaleVoices: ['ClaraNeural',
        ], maleVoices: ['LiamNeural',
        ],
    }, 'en-GB': {
        label: 'English (United Kingdom)', femaleVoices: ['SoniaNeural',
            'LibbyNeural',
            'AbbiNeural',
            'BellaNeural',
            'HollieNeural',
            'OliviaNeural',
        ], femaleChildVoices: ['MaisieNeural',
        ], maleVoices: ['RyanNeural',
            'AlfieNeural',
            'ElliotNeural',
            'EthanNeural',
            'NoahNeural',
            'OliverNeural',
            'ThomasNeural',
        ],
    }, 'en-HK': {
        label: 'English (Hong Kong SAR)', femaleVoices: ['YanNeural',
        ], maleVoices: ['SamNeural',
        ],
    }, 'en-IE': {
        label: 'English (Ireland)', femaleVoices: ['EmilyNeural',
        ], maleVoices: ['ConnorNeural',
        ],
    }, 'en-IN': {
        label: 'English (India)', femaleVoices: ['NeerjaNeural',
        ], maleVoices: ['PrabhatNeural',
        ],
    }, 'en-KE': {
        label: 'English (Kenya)', femaleVoices: ['AsiliaNeural',
        ], maleVoices: ['ChilembaNeural',
        ],
    }, 'en-NG': {
        label: 'English (Nigeria)', femaleVoices: ['EzinneNeural',
        ], maleVoices: ['AbeoNeural',
        ],
    }, 'en-NZ': {
        label: 'English (New Zealand)', femaleVoices: ['MollyNeural',
        ], maleVoices: ['MitchellNeural',
        ],
    }, 'en-PH': {
        label: 'English (Philippines)', femaleVoices: ['RosaNeural',
        ], maleVoices: ['JamesNeural',
        ],
    }, 'en-SG': {
        label: 'English (Singapore)', femaleVoices: ['LunaNeural',
        ], maleVoices: ['WayneNeural',
        ],
    }, 'en-TZ': {
        label: 'English (Tanzania)', femaleVoices: ['ImaniNeural',
        ], maleVoices: ['ElimuNeural',
        ],
    }, 'en-US': {
        label: 'English (United States)', femaleVoices: ['AvaNeural',
            'EmmaNeural',
            'JennyNeural',
            'AriaNeural',
            'JaneNeural',
            'SaraNeural',
            'NancyNeural',
            'AmberNeural',
            'AshleyNeural',
            'CoraNeural',
            'ElizabethNeural',
            'JennyMultilingualNeural',
            'MichelleNeural',
            'MonicaNeural',
            'AIGenerateNeural',
            'AvaMultilingualNeural',
            'EmmaMultilingualNeural',
            'NovaMultilingualNeural4',
            'ShimmerMultilingualNeural4',
            'NovaMultilingualNeuralHD4',
            'ShimmerMultilingualNeuralHD4',
        ], femaleChildVoices: ['AnaNeural',
        ], maleVoices: ['AndrewNeural',
            'BrianNeural',
            'GuyNeural',
            'DavisNeural',
            'JasonNeural',
            'TonyNeural',
            'BrandonNeural',
            'ChristopherNeural',
            'EricNeural',
            'JacobNeural',
            'RogerNeural',
            'RyanMultilingualNeural',
            'SteffanNeural',
            'AIGenerateNeural',
            'AndrewMultilingualNeural',
            'BrianMultilingualNeural',
            'AlloyMultilingualNeural4',
            'EchoMultilingualNeural4',
            'OnyxMultilingualNeural4',
            'AlloyMultilingualNeuralHD4',
            'EchoMultilingualNeuralHD4',
            'OnyxMultilingualNeuralHD4',
        ], neutralVoices: ['BlueNeural',
            'FableMultilingualNeural4',
            'FableMultilingualNeuralHD4',
        ],
    }, 'en-ZA': {
        label: 'English (South Africa)', femaleVoices: ['LeahNeural',
        ], maleVoices: ['LukeNeural',
        ],
    }, 'es-AR': {
        label: 'Spanish (Argentina)', femaleVoices: ['ElenaNeural',
        ], maleVoices: ['TomasNeural',
        ],
    }, 'es-BO': {
        label: 'Spanish (Bolivia)', femaleVoices: ['SofiaNeural',
        ], maleVoices: ['MarceloNeural',
        ],
    }, 'es-CL': {
        label: 'Spanish (Chile)', femaleVoices: ['CatalinaNeural',
        ], maleVoices: ['LorenzoNeural',
        ],
    }, 'es-CO': {
        label: 'Spanish (Colombia)', femaleVoices: ['SalomeNeural',
        ], maleVoices: ['GonzaloNeural',
        ],
    }, 'es-CR': {
        label: 'Spanish (Costa Rica)', femaleVoices: ['MariaNeural',
        ], maleVoices: ['JuanNeural',
        ],
    }, 'es-CU': {
        label: 'Spanish (Cuba)', femaleVoices: ['BelkysNeural',
        ], maleVoices: ['ManuelNeural',
        ],
    }, 'es-DO': {
        label: 'Spanish (Dominican Republic)', femaleVoices: ['RamonaNeural',
        ], maleVoices: ['EmilioNeural',
        ],
    }, 'es-EC': {
        label: 'Spanish (Ecuador)', femaleVoices: ['AndreaNeural',
        ], maleVoices: ['LuisNeural',
        ],
    }, 'es-ES': {
        label: 'Spanish (Spain)', femaleVoices: ['ElviraNeural',
            'AbrilNeural',
            'EstrellaNeural',
            'IreneNeural',
            'LaiaNeural',
            'LiaNeural',
            'TrianaNeural',
            'VeraNeural',
            'XimenaNeural',
        ], maleVoices: ['AlvaroNeural',
            'ArnauNeural',
            'DarioNeural',
            'EliasNeural',
            'NilNeural',
            'SaulNeural',
            'TeoNeural',
        ],
    }, 'es-GQ': {
        label: 'Spanish (Equatorial Guinea)', femaleVoices: ['TeresaNeural',
        ], maleVoices: ['JavierNeural',
        ],
    }, 'es-GT': {
        label: 'Spanish (Guatemala)', femaleVoices: ['MartaNeural',
        ], maleVoices: ['AndresNeural',
        ],
    }, 'es-HN': {
        label: 'Spanish (Honduras)', femaleVoices: ['KarlaNeural',
        ], maleVoices: ['CarlosNeural',
        ],
    }, 'es-MX': {
        label: 'Spanish (Mexico)', femaleVoices: ['DaliaNeural',
            'BeatrizNeural',
            'CandelaNeural',
            'CarlotaNeural',
            'LarissaNeural',
            'MarinaNeural',
            'NuriaNeural',
            'RenataNeural',
        ], maleVoices: ['JorgeNeural',
            'CecilioNeural',
            'GerardoNeural',
            'LibertoNeural',
            'LucianoNeural',
            'PelayoNeural',
            'YagoNeural',
        ],
    }, 'es-NI': {
        label: 'Spanish (Nicaragua)', femaleVoices: ['YolandaNeural',
        ], maleVoices: ['FedericoNeural',
        ],
    }, 'es-PA': {
        label: 'Spanish (Panama)', femaleVoices: ['MargaritaNeural',
        ], maleVoices: ['RobertoNeural',
        ],
    }, 'es-PE': {
        label: 'Spanish (Peru)', femaleVoices: ['CamilaNeural',
        ], maleVoices: ['AlexNeural',
        ],
    }, 'es-PR': {
        label: 'Spanish (Puerto Rico)', femaleVoices: ['KarinaNeural',
        ], maleVoices: ['VictorNeural',
        ],
    }, 'es-PY': {
        label: 'Spanish (Paraguay)', femaleVoices: ['TaniaNeural',
        ], maleVoices: ['MarioNeural',
        ],
    }, 'es-SV': {
        label: 'Spanish (El Salvador)', femaleVoices: ['LorenaNeural',
        ], maleVoices: ['RodrigoNeural',
        ],
    }, 'es-US': {
        label: 'Spanish (United States)', femaleVoices: ['PalomaNeural',
        ], maleVoices: ['AlonsoNeural',
        ],
    }, 'es-UY': {
        label: 'Spanish (Uruguay)', femaleVoices: ['ValentinaNeural',
        ], maleVoices: ['MateoNeural',
        ],
    }, 'es-VE': {
        label: 'Spanish (Venezuela)', femaleVoices: ['PaolaNeural',
        ], maleVoices: ['SebastianNeural',
        ],
    }, 'et-EE': {
        label: 'Estonian (Estonia)', femaleVoices: ['AnuNeural',
        ], maleVoices: ['KertNeural',
        ],
    }, 'eu-ES': {
        label: 'Basque', femaleVoices: ['AinhoaNeural',
        ], maleVoices: ['AnderNeural',
        ],
    }, 'fa-IR': {
        label: 'Persian (Iran)', femaleVoices: ['DilaraNeural',
        ], maleVoices: ['FaridNeural',
        ],
    }, 'fi-FI': {
        label: 'Finnish (Finland)', femaleVoices: ['SelmaNeural',
            'NooraNeural',
        ], maleVoices: ['HarriNeural',
        ],
    }, 'fil-PH': {
        label: 'Filipino (Philippines)', femaleVoices: ['BlessicaNeural',
        ], maleVoices: ['AngeloNeural',
        ],
    }, 'fr-BE': {
        label: 'French (Belgium)', femaleVoices: ['CharlineNeural',
        ], maleVoices: ['GerardNeural',
        ],
    }, 'fr-CA': {
        label: 'French (Canada)', femaleVoices: ['SylvieNeural',
        ], maleVoices: ['JeanNeural',
            'AntoineNeural',
            'ThierryNeural',
        ],
    }, 'fr-CH': {
        label: 'French (Switzerland)', femaleVoices: ['ArianeNeural',
        ], maleVoices: ['FabriceNeural',
        ],
    }, 'fr-FR': {
        label: 'French (France)', femaleVoices: ['DeniseNeural',
            'BrigitteNeural',
            'CelesteNeural',
            'CoralieNeural',
            'JacquelineNeural',
            'JosephineNeural',
            'YvetteNeural',
            'VivienneMultilingualNeural',
        ], femaleChildVoices: ['EloiseNeural',
        ], maleVoices: ['HenriNeural',
            'AlainNeural',
            'ClaudeNeural',
            'JeromeNeural',
            'MauriceNeural',
            'YvesNeural',
            'RemyMultilingualNeural',
        ],
    }, 'ga-IE': {
        label: 'Irish (Ireland)', femaleVoices: ['OrlaNeural',
        ], maleVoices: ['ColmNeural',
        ],
    }, 'gl-ES': {
        label: 'Galician', femaleVoices: ['SabelaNeural',
        ], maleVoices: ['RoiNeural',
        ],
    }, 'gu-IN': {
        label: 'Gujarati (India)', femaleVoices: ['DhwaniNeural',
        ], maleVoices: ['NiranjanNeural',
        ],
    }, 'he-IL': {
        label: 'Hebrew (Israel)', femaleVoices: ['HilaNeural',
        ], maleVoices: ['AvriNeural',
        ],
    }, 'hi-IN': {
        label: 'Hindi (India)', femaleVoices: ['SwaraNeural',
        ], maleVoices: ['MadhurNeural',
        ],
    }, 'hr-HR': {
        label: 'Croatian (Croatia)', femaleVoices: ['GabrijelaNeural',
        ], maleVoices: ['SreckoNeural',
        ],
    }, 'hu-HU': {
        label: 'Hungarian (Hungary)', femaleVoices: ['NoemiNeural',
        ], maleVoices: ['TamasNeural',
        ],
    }, 'hy-AM': {
        label: 'Armenian (Armenia)', femaleVoices: ['AnahitNeural',
        ], maleVoices: ['HaykNeural',
        ],
    }, 'id-ID': {
        label: 'Indonesian (Indonesia)', femaleVoices: ['GadisNeural',
        ], maleVoices: ['ArdiNeural',
        ],
    }, 'is-IS': {
        label: 'Icelandic (Iceland)', femaleVoices: ['GudrunNeural',
        ], maleVoices: ['GunnarNeural',
        ],
    }, 'it-IT': {
        label: 'Italian (Italy)', femaleVoices: ['ElsaNeural',
            'IsabellaNeural',
            'FabiolaNeural',
            'FiammaNeural',
            'ImeldaNeural',
            'IrmaNeural',
            'PalmiraNeural',
            'PierinaNeural',
        ], maleVoices: ['DiegoNeural',
            'BenignoNeural',
            'CalimeroNeural',
            'CataldoNeural',
            'GianniNeural',
            'LisandroNeural',
            'RinaldoNeural',
            'GiuseppeNeural',
        ],
    }, 'ja-JP': {
        label: 'Japanese (Japan)', femaleVoices: ['NanamiNeural',
            'AoiNeural',
            'MayuNeural',
            'ShioriNeural',
        ], maleVoices: ['KeitaNeural',
            'DaichiNeural',
            'NaokiNeural',
            'MasaruMultilingualNeural',
            'MasaruMultilingualNeural',
        ],
    }, 'jv-ID': {
        label: 'Javanese (Indonesia)', femaleVoices: ['SitiNeural',
        ], maleVoices: ['DimasNeural',
        ],
    }, 'ka-GE': {
        label: 'Georgian (Georgia)', femaleVoices: ['EkaNeural',
        ], maleVoices: ['GiorgiNeural',
        ],
    }, 'kk-KZ': {
        label: 'Kazakh (Kazakhstan)', femaleVoices: ['AigulNeural',
        ], maleVoices: ['DauletNeural',
        ],
    }, 'km-KH': {
        label: 'Khmer (Cambodia)', femaleVoices: ['SreymomNeural',
        ], maleVoices: ['PisethNeural',
        ],
    }, 'kn-IN': {
        label: 'Kannada (India)', femaleVoices: ['SapnaNeural',
        ], maleVoices: ['GaganNeural',
        ],
    }, 'ko-KR': {
        label: 'Korean (Korea)', femaleVoices: ['SunHiNeural',
            'JiMinNeural',
            'SeoHyeonNeural',
            'SoonBokNeural',
            'YuJinNeural',
        ], maleVoices: ['InJoonNeural',
            'BongJinNeural',
            'GookMinNeural',
            'HyunsuNeural',
        ],
    }, 'lo-LA': {
        label: 'Lao (Laos)', femaleVoices: ['KeomanyNeural',
        ], maleVoices: ['ChanthavongNeural',
        ],
    }, 'lt-LT': {
        label: 'Lithuanian (Lithuania)', femaleVoices: ['OnaNeural',
        ], maleVoices: ['LeonasNeural',
        ],
    }, 'lv-LV': {
        label: 'Latvian (Latvia)', femaleVoices: ['EveritaNeural',
        ], maleVoices: ['NilsNeural',
        ],
    }, 'mk-MK': {
        label: 'Macedonian (North Macedonia)', femaleVoices: ['MarijaNeural',
        ], maleVoices: ['AleksandarNeural',
        ],
    }, 'ml-IN': {
        label: 'Malayalam (India)', femaleVoices: ['SobhanaNeural',
        ], maleVoices: ['MidhunNeural',
        ],
    }, 'mn-MN': {
        label: 'Mongolian (Mongolia)', femaleVoices: ['YesuiNeural',
        ], maleVoices: ['BataaNeural',
        ],
    }, 'mr-IN': {
        label: 'Marathi (India)', femaleVoices: ['AarohiNeural',
        ], maleVoices: ['ManoharNeural',
        ],
    }, 'ms-MY': {
        label: 'Malay (Malaysia)', femaleVoices: ['YasminNeural',
        ], maleVoices: ['OsmanNeural',
        ],
    }, 'mt-MT': {
        label: 'Maltese (Malta)', femaleVoices: ['GraceNeural',
        ], maleVoices: ['JosephNeural',
        ],
    }, 'my-MM': {
        label: 'Burmese (Myanmar)', femaleVoices: ['NilarNeural',
        ], maleVoices: ['ThihaNeural',
        ],
    }, 'nb-NO': {
        label: 'Norwegian Bokmål (Norway)', femaleVoices: ['PernilleNeural',
            'IselinNeural',
        ], maleVoices: ['FinnNeural',
        ],
    }, 'ne-NP': {
        label: 'Nepali (Nepal)', femaleVoices: ['HemkalaNeural',
        ], maleVoices: ['SagarNeural',
        ],
    }, 'nl-BE': {
        label: 'Dutch (Belgium)', femaleVoices: ['DenaNeural',
        ], maleVoices: ['ArnaudNeural',
        ],
    }, 'nl-NL': {
        label: 'Dutch (Netherlands)', femaleVoices: ['FennaNeural',
            'ColetteNeural',
        ], maleVoices: ['MaartenNeural',
        ],
    }, 'pl-PL': {
        label: 'Polish (Poland)', femaleVoices: ['AgnieszkaNeural',
            'ZofiaNeural',
        ], maleVoices: ['MarekNeural',
        ],
    }, 'ps-AF': {
        label: 'Pashto (Afghanistan)', femaleVoices: ['LatifaNeural',
        ], maleVoices: ['GulNawazNeural',
        ],
    }, 'pt-BR': {
        label: 'Portuguese (Brazil)', femaleVoices: ['FranciscaNeural',
            'BrendaNeural',
            'ElzaNeural',
            'GiovannaNeural',
            'LeilaNeural',
            'LeticiaNeural',
            'ManuelaNeural',
            'YaraNeural',
            'ThalitaNeural',
        ], maleVoices: ['AntonioNeural',
            'DonatoNeural',
            'FabioNeural',
            'HumbertoNeural',
            'JulioNeural',
            'NicolauNeural',
            'ValerioNeural',
        ],
    }, 'pt-PT': {
        label: 'Portuguese (Portugal)', femaleVoices: ['RaquelNeural',
            'FernandaNeural',
        ], maleVoices: ['DuarteNeural',
        ],
    }, 'ro-RO': {
        label: 'Romanian (Romania)', femaleVoices: ['AlinaNeural',
        ], maleVoices: ['EmilNeural',
        ],
    }, 'ru-RU': {
        label: 'Russian (Russia)', femaleVoices: ['SvetlanaNeural',
            'DariyaNeural',
        ], maleVoices: ['DmitryNeural',
        ],
    }, 'si-LK': {
        label: 'Sinhala (Sri Lanka)', femaleVoices: ['ThiliniNeural',
        ], maleVoices: ['SameeraNeural',
        ],
    }, 'sk-SK': {
        label: 'Slovak (Slovakia)', femaleVoices: ['ViktoriaNeural',
        ], maleVoices: ['LukasNeural',
        ],
    }, 'sl-SI': {
        label: 'Slovenian (Slovenia)', femaleVoices: ['PetraNeural',
        ], maleVoices: ['RokNeural',
        ],
    }, 'so-SO': {
        label: 'Somali (Somalia)', femaleVoices: ['UbaxNeural',
        ], maleVoices: ['MuuseNeural',
        ],
    }, 'sq-AL': {
        label: 'Albanian (Albania)', femaleVoices: ['AnilaNeural',
        ], maleVoices: ['IlirNeural',
        ],
    }, 'RS': {
        label: 'Serbian (Latin Serbia)', femaleVoices: ['SophieNeural',
        ], maleVoices: ['NicholasNeural',
        ],
    }, 'sr-RS': {
        label: 'Serbian (Cyrillic Serbia)', femaleVoices: ['SophieNeural',
        ], maleVoices: ['NicholasNeural',
        ],
    }, 'su-ID': {
        label: 'Sundanese (Indonesia)', femaleVoices: ['TutiNeural',
        ], maleVoices: ['JajangNeural',
        ],
    }, 'sv-SE': {
        label: 'Swedish (Sweden)', femaleVoices: ['SofieNeural',
            'HilleviNeural',
        ], maleVoices: ['MattiasNeural',
        ],
    }, 'sw-KE': {
        label: 'Swahili (Kenya)', femaleVoices: ['ZuriNeural',
        ], maleVoices: ['RafikiNeural',
        ],
    }, 'sw-TZ': {
        label: 'Swahili (Tanzania)', femaleVoices: ['RehemaNeural',
        ], maleVoices: ['DaudiNeural',
        ],
    }, 'ta-IN': {
        label: 'Tamil (India)', femaleVoices: ['PallaviNeural',
        ], maleVoices: ['ValluvarNeural',
        ],
    }, 'ta-LK': {
        label: 'Tamil (Sri Lanka)', femaleVoices: ['SaranyaNeural',
        ], maleVoices: ['KumarNeural',
        ],
    }, 'ta-MY': {
        label: 'Tamil (Malaysia)', femaleVoices: ['KaniNeural',
        ], maleVoices: ['SuryaNeural',
        ],
    }, 'ta-SG': {
        label: 'Tamil (Singapore)', femaleVoices: ['VenbaNeural',
        ], maleVoices: ['AnbuNeural',
        ],
    }, 'te-IN': {
        label: 'Telugu (India)', femaleVoices: ['ShrutiNeural',
        ], maleVoices: ['MohanNeural',
        ],
    }, 'th-TH': {
        label: 'Thai (Thailand)', femaleVoices: ['PremwadeeNeural',
            'AcharaNeural',
        ], maleVoices: ['NiwatNeural',
        ],
    }, 'tr-TR': {
        label: 'Turkish (Türkiye)', femaleVoices: ['EmelNeural',
        ], maleVoices: ['AhmetNeural',
        ],
    }, 'uk-UA': {
        label: 'Ukrainian (Ukraine)', femaleVoices: ['PolinaNeural',
        ], maleVoices: ['OstapNeural',
        ],
    }, 'ur-IN': {
        label: 'Urdu (India)', femaleVoices: ['GulNeural',
        ], maleVoices: ['SalmanNeural',
        ],
    }, 'ur-PK': {
        label: 'Urdu (Pakistan)', femaleVoices: ['UzmaNeural',
        ], maleVoices: ['AsadNeural',
        ],
    }, 'uz-UZ': {
        label: 'Uzbek (Latin Uzbekistan)', femaleVoices: ['MadinaNeural',
        ], maleVoices: ['SardorNeural',
        ],
    }, 'vi-VN': {
        label: 'Vietnamese (Vietnam)', femaleVoices: ['HoaiMyNeural',
        ], maleVoices: ['NamMinhNeural',
        ],
    }, 'wuu-CN': {
        label: 'Chinese (Wu Simplified)', femaleVoices: ['XiaotongNeural',
        ], maleVoices: ['YunzheNeural',
        ],
    }, 'yue-CN': {
        label: 'Chinese (Cantonese Simplified)', femaleVoices: ['XiaoMinNeural',
        ], maleVoices: ['YunSongNeural',
        ],
    }, 'zh-CN': {
        label: 'Chinese (Mandarin Simplified)', femaleVoices: ['XiaoxiaoNeural',
            'XiaoyiNeural',
            'XiaochenNeural',
            'XiaohanNeural',
            'XiaomengNeural',
            'XiaomoNeural',
            'XiaoqiuNeural',
            'XiaoruiNeural',
            'XiaoyanNeural',
            'XiaozhenNeural',
            'XiaochenMultilingualNeural',
            'XiaorouNeural',
            'XiaoxiaoDialectsNeural',
            'XiaoxiaoMultilingualNeural',
            'XiaoyuMultilingualNeural',
        ], femaleChildVoices: ['XiaoshuangNeural',
            'XiaoyouNeural',
        ], maleVoices: ['YunxiNeural',
            'YunjianNeural',
            'YunyangNeural',
            'YunfengNeural',
            'YunhaoNeural',
            'YunxiaNeural',
            'YunyeNeural',
            'YunzeNeural',
            'YunjieNeural',
            'YunyiMultilingualNeural',
        ],
    }, 'GUANGXI': {
        label: 'Chinese (Guangxi Accent Mandarin Simplified)', maleVoices: ['YunqiNeural',
            'YunqiNeural',
        ],
    }, 'henan': {
        label: 'Chinese (Zhongyuan Mandarin Henan Simplified)', maleVoices: ['YundengNeural',
        ],
    }, 'liaoning': {
        label: 'Chinese (Northeastern Mandarin Simplified)', femaleVoices: ['XiaobeiNeural',
            'XiaobeiNeural',
        ], maleVoices: ['YunbiaoNeural',
        ],
    }, 'shaanxi': {
        label: 'Chinese (Zhongyuan Mandarin Shaanxi Simplified)', femaleVoices: ['XiaoniNeural',
        ],
    }, 'shandong': {
        label: 'Chinese (Jilu Mandarin Simplified)', maleVoices: ['YunxiangNeural',
        ],
    }, 'sichuan': {
        label: 'Chinese (Southwestern Mandarin Simplified)', femaleVoices: ['HiuMaanNeural',
            'HiuGaaiNeural',
        ], maleVoices: ['YunxiNeural',
            'WanLungNeural',
        ],
    }, 'zh-TW': {
        label: 'Chinese (Taiwanese Mandarin Traditional)', femaleVoices: ['HsiaoChenNeural',
            'HsiaoYuNeural',
        ], maleVoices: ['YunJheNeural',
        ],
    },
    'zu-ZA': {
        label: 'Zulu (South Africa)',
        femaleVoices: ['ThandoNeural',],
        maleVoices: ['ThembaNeural',],
    }
}

export const vocalStyles = {
    no_vocalStyle: {
        code: 'no_vocalStyle',
        label: 'No Voice Style',
        exampleContent: {
            "af-ZA": "Dit is my neutrale stem",
            "am-ET": "ይህ የኔ አስተዳደራዊ ድምፅ ነው",
            "ar-AE": "هذه هي نبرتي المحايدة",
            "ar-BH": "هذه هي نبرتي المحايدة",
            "ar-DZ": "هذه هي نبرتي المحايدة",
            "ar-EG": "هذه هي نبرتي المحايدة",
            "ar-IQ": "هذه هي نبرتي المحايدة",
            "ar-JO": "هذه هي نبرتي المحايدة",
            "ar-KW": "هذه هي نبرتي المحايدة",
            "ar-LB": "هذه هي نبرتي المحايدة",
            "ar-LY": "هذه هي نبرتي المحايدة",
            "ar-MA": "هذه هي نبرتي المحايدة",
            "ar-OM": "هذه هي نبرتي المحايدة",
            "ar-QA": "هذه هي نبرتي المحايدة",
            "ar-SA": "هذه هي نبرتي المحايدة",
            "ar-SY": "هذه هي نبرتي المحايدة",
            "ar-TN": "هذه هي نبرتي المحايدة",
            "ar-YE": "هذه هي نبرتي المحايدة",
            "az-AZ": "Bu mənim neytral səsimdir",
            "bg-BG": "Това е моят неутрален глас",
            "bn-BD": "এটি আমার নিরপেক্ষ কণ্ঠস্বর",
            "bn-IN": "এটি আমার নিরপেক্ষ কণ্ঠস্বর",
            "bs-BA": "Ovo je moj neutralni glas",
            "ca-ES": "Aquesta és la meva veu neutral",
            "cs-CZ": "Toto je můj neutrální hlas",
            "cy-GB": "Dyma fy llais niwtral",
            "da-DK": "Dette er min neutrale stemme",
            "de-AT": "Das ist meine neutrale Stimme",
            "de-CH": "Das ist meine neutrale Stimme",
            "de-DE": "Das ist meine neutrale Stimme",
            "el-GR": "Αυτή είναι η ουδέτερη φωνή μου",
            "en-AU": "This is my neutral voice",
            "en-CA": "This is my neutral voice",
            "en-GB": "This is my neutral voice",
            "en-HK": "This is my neutral voice",
            "en-IE": "This is my neutral voice",
            "en-IN": "This is my neutral voice",
            "en-KE": "This is my neutral voice",
            "en-NG": "This is my neutral voice",
            "en-NZ": "This is my neutral voice",
            "en-PH": "This is my neutral voice",
            "en-SG": "This is my neutral voice",
            "en-TZ": "This is my neutral voice",
            "en-US": "This is my neutral voice",
            "en-ZA": "This is my neutral voice",
            "es-AR": "Esta es mi voz neutral",
            "es-BO": "Esta es mi voz neutral",
            "es-CL": "Esta es mi voz neutral",
            "es-CO": "Esta es mi voz neutral",
            "es-CR": "Esta es mi voz neutral",
            "es-CU": "Esta es mi voz neutral",
            "es-DO": "Esta es mi voz neutral",
            "es-EC": "Esta es mi voz neutral",
            "es-ES": "Esta es mi voz neutral",
            "es-GQ": "Esta es mi voz neutral",
            "es-GT": "Esta es mi voz neutral",
            "es-HN": "Esta es mi voz neutral",
            "es-MX": "Esta es mi voz neutral",
            "es-NI": "Esta es mi voz neutral",
            "es-PA": "Esta es mi voz neutral",
            "es-PE": "Esta es mi voz neutral",
            "es-PR": "Esta es mi voz neutral",
            "es-PY": "Esta es mi voz neutral",
            "es-SV": "Esta es mi voz neutral",
            "es-US": "Esta es mi voz neutral",
            "es-UY": "Esta es mi voz neutral",
            "es-VE": "Esta es mi voz neutral",
            "et-EE": "See on minu neutraalne hääl",
            "eu-ES": "Hau nire ahots neutroa da",
            "fa-IR": "این صدای خنثی من است",
            "fi-FI": "Tämä on neutraali ääneni",
            "fil-PH": "Ito ang aking neutral na boses",
            "fr-BE": "C'est ma voix neutre",
            "fr-CA": "C'est ma voix neutre",
            "fr-CH": "C'est ma voix neutre",
            "fr-FR": "C'est ma voix neutre",
            "ga-IE": "Seo mo ghuth neodrach",
            "gl-ES": "Esta é a miña voz neutral",
            "gu-IN": "આ મારી તટસ્થ અવાજ છે",
            "he-IL": "זה הקול הנייטרלי שלי",
            "hi-IN": "यह मेरी तटस्थ आवाज़ है",
            "hr-HR": "Ovo je moj neutralni glas",
            "hu-HU": "Ez az én semleges hangom",
            "hy-AM": "Սա իմ չեզոք ձայնն է",
            "id-ID": "Ini adalah suara netral saya",
            "is-IS": "Þetta er hlutlausa röddin mín",
            "it-IT": "Questa è la mia voce neutra",
            "ja-JP": "これは私のニュートラルな声です",
            "jv-ID": "Iki swara netralku",
            "ka-GE": "ეს არის ჩემი ნეიტრალური ხმა",
            "kk-KZ": "Бұл менің бейтарап дауысым",
            "km-KH": "នេះគឺសំឡេងអព្យាក្រឹតរបស់ខ្ញុំ",
            "kn-IN": "ಇದು ನನ್ನ ತಟಸ್ಥ ಧ್ವನಿ",
            "ko-KR": "이것이 나의 중립적인 목소리입니다",
            "lo-LA": "ນີ້ແມ່ນສຽງກາງຂອງຂ້ອຍ",
            "lt-LT": "Tai yra mano neutrali balsas",
            "lv-LV": "Šī ir mana neitrāla balss",
            "mk-MK": "Ова е мојот неутрален глас",
            "ml-IN": "ഇത് എന്റെ ന്യൂട്രൽ ശബ്ദം ആണ്",
            "mn-MN": "Энэ бол миний төвийг сахисан дуу хоолой юм",
            "mr-IN": "हा माझा तटस्थ आवाज आहे",
            "ms-MY": "Ini adalah suara neutral saya",
            "mt-MT": "Din hija l-vuċi newtrali tiegħi",
            "my-MM": "ဒီဟာကျွန်တော့်ရဲ့အလယ်လမ်းအသံပါ",
            "nb-NO": "Dette er min nøytrale stemme",
            "ne-NP": "यो मेरो निष्पक्ष आवाज हो",
            "nl-BE": "Dit is mijn neutrale stem",
            "nl-NL": "Dit is mijn neutrale stem",
            "pl-PL": "To jest mój neutralny głos",
            "ps-AF": "دا زما بې پرې غږ دی",
            "pt-BR": "Esta é a minha voz neutra",
            "pt-PT": "Esta é a minha voz neutra",
            "ro-RO": "Aceasta este vocea mea neutră",
            "ru-RU": "Это мой нейтральный голос",
            "si-LK": "මෙය මාගේ නිශ්පක්ෂ හඬයි",
            "sk-SK": "Toto je môj neutrálny hlas",
            "sl-SI": "To je moj nevtralni glas",
            "so-SO": "Kani waa codkeyga dhexdhexaadka ah",
            "sq-AL": "Kjo është zëri im neutral",
            "RS": "Ovo je moj neutralni glas",
            "sr-RS": "Ово је мој неутрални глас",
            "su-ID": "Ieu sora netral abdi",
            "sv-SE": "Detta är min neutrala röst",
            "sw-KE": "Hii ni sauti yangu ya kawaida",
            "sw-TZ": "Hii ni sauti yangu ya kawaida",
            "ta-IN": "இது என்னுடைய நடுநிலை குரல்",
            "ta-LK": "இது என்னுடைய நடுநிலை குரல்",
            "ta-MY": "இது என்னுடைய நடுநிலை குரல்",
            "ta-SG": "இது என்னுடைய நடுநிலை குரல்",
            "te-IN": "ఇది నా తటస్థమైన స్వరం",
            "th-TH": "นี่คือเสียงที่เป็นกลางของฉัน",
            "tr-TR": "Bu benim nötr sesim",
            "uk-UA": "Це мій нейтральний голос",
            "ur-IN": "یہ میری غیر جانبدار آواز ہے",
            "ur-PK": "یہ میری غیر جانبدار آواز ہے",
            "uz-UZ": "Bu mening neytral ovozim",
            "vi-VN": "Đây là giọng trung lập của tôi",
            "wuu-CN": "这是我的中性声音",
            "yue-CN": "呢個係我嘅中性聲",
            "zh-CN": "这是我的中性声音",
            "GUANGXI": "这是我的中性声音",
            "henan": "这是我的中性声音",
            "liaoning": "这是我的中性声音",
            "shaanxi": "这是我的中性声音",
            "shandong": "这是我的中性声音",
            "sichuan": "这是我的中性声音",
            "zh-TW": "這是我的中性聲音",
            "zu-ZA": "Lona izwi lami elingakhethi"
        },
        description: 'The "No Vocal Style" is just used to show you my natural neutral voice.',
        languages: languages,
    },
    advertisement_upbeat: {
        code: 'advertisement-upbeat',
        label: 'Advertisement Upbeat',
        exampleContent: {
            'de-DE': 'Aufregendes neues Produkt, jetzt erhältlich!',
            'en-GB': 'Exciting new product, available now!',
            'en-US': 'Exciting new product, available now!',
            'es-MX': '¡Producto emocionante, disponible ahora!',
            'fr-FR': 'Un produit incroyable ! Diposible dès maintenant !',
            'it-IT': 'Prodotto eccitante, disponibile ora!',
            'ja-JP': '新しいエキサイティングな製品、今すぐ利用可能です！',
            'pt-BR': 'Produto emocionante, disponível agora!',
            'zh-CN': '令人兴奋的新产品，现已上市！',
        },
        description:
            '"Advertisement Upbeat" expresses an excited and high-energy tone for promoting a product or service.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunhaoNeural'],
            },
        },
    },
    affectionate: {
        label: 'Affectionate',
        exampleContent: {
            'de-DE': 'Du bedeutest mir die Welt.',
            'en-GB': 'I have a secret to tell you...',
            'en-US': 'You mean the world to me.',
            'es-MX': 'Tú significas el mundo para mí.',
            'fr-FR': 'Je dois vous dire un secret',
            'it-IT': 'Tu sei il mio mondo.',
            'ja-JP': 'あなたは私にとって世界を意味します。',
            'pt-BR': 'Você significa o mundo para mim.',
            'zh-CN': '你是我的世界。',
        },
        description:
            '"Affectionate" expresses a warm and affectionate tone, with higher pitch and vocal energy. The speaker is in a state of attracting the attention of the listener. The personality of the speaker is often endearing in nature.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaohanNeural', 'XiaomoNeural', 'XiaoxiaoNeural', 'XiaoyiNeural'],
            },
        },
    },
    angry: {
        label: 'Angry',
        exampleContent: {
            'de-DE': 'Ich kann nicht glauben, dass du das getan hast!',
            'en-GB': "I can't believe you did that!",
            'en-US': "I can't believe you did that!",
            'es-MX': '¡No puedo creer que hayas hecho eso!',
            'fr-FR': 'Je suis tellement en colère contre ce gâchis !',
            'it-IT': 'Non posso credere che tu abbia fatto questo!',
            'ja-JP': 'それをやったなんて信じられない！',
            'pt-BR': 'Não posso acreditar que você fez isso!',
            'zh-CN': '我无法相信你那样做了！',
        },
        description: '"Angry" expresses an angry and annoyed tone.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',
                    'XiaomoNeural',
                    'XiaoruiNeural',
                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                    'XiaoyiNeural',
                    'XiaozhenNeural',
                ],
                maleVoices: [
                    'YunfengNeural',
                    'YunjianNeural',
                    'YunxiaNeural',
                    'YunxiNeural',
                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    assistant: {
        label: 'Assistant',
        exampleContent: {
            'de-DE': 'Wie kann ich Ihnen heute helfen?',
            'en-GB': 'How can I assist you today?',
            'en-US': 'How can I assist you today?',
            'es-MX': '¿Cómo puedo ayudarte hoy?',
            'fr-FR': "Comment pourrais-je vous aider aujourd'hui ?",
            'it-IT': 'Come posso aiutarti oggi?',
            'ja-JP': '今日はどうお手伝いできますか？',
            'pt-BR': 'Como posso ajudar você hoje?',
            'zh-CN': '我今天能为您做什么？',
        },
        description: '"Assistant" expresses a warm and relaxed tone for digital assistants.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['JennyNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
                maleVoices: ['YunxiNeural'],
            },
        },
    },
    calm: {
        label: 'Calm',
        exampleContent: {
            'de-DE': 'Atme tief ein und entspanne dich.',
            'en-GB': 'Take a deep breath and relax.',
            'en-US': 'Take a deep breath and relax.',
            'es-MX': 'Respira profundamente y relájate.',
            'fr-FR': 'Respirez profondément et détendez-vous.',
            'it-IT': 'Fai un respiro profondo e rilassati.',
            'ja-JP': '深呼吸してリラックスしてください。',
            'pt-BR': 'Respire fundo e relaxe.',
            'zh-CN': '深呼吸，放松。',
        },
        description:
            '"Calm" expresses a cool, collected, and composed attitude when speaking. Tone, pitch, and prosody are more uniform compared to other types of speech.',
        languages: {
            'pt-BR': {
                label: 'Portuguese (Brazilian)',
                femaleVoices: ['FranciscaNeural'],
            },
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',
                    'XiaomoNeural',
                    'XiaoruiNeural',
                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                ],
                maleVoices: [
                    'XiaohanNeural',
                    'XiaomoNeural',
                    'XiaoruiNeural',
                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                ],
            },
        },
    },
    chat: {
        label: 'Chat',
        exampleContent: {
            'de-DE': 'Hey, was geht ab?',
            'en-GB': "Hey, what's up?",
            'en-US': "Hey, what's up?",
            'es-MX': 'Hola, ¿qué tal?',
            'fr-FR': 'Salut, quoi de neuf ?',
            'it-IT': 'Ehi, cosa c’è?',
            'ja-JP': 'やあ、どうしたの？',
            'pt-BR': 'Oi, tudo bem?',
            'zh-CN': '嘿，怎么了？',
        },
        description: '"Chat" expresses a casual and relaxed tone.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural'],
                maleVoices: ['DavisNeural'],
            },

            'es-MX': {
                label: 'Spanish (Mexican)',
                maleVoices: ['JorgeNeural'],
            },

            'it-IT': {
                label: 'Italian',
                femaleVoices: ['IsabellaNeural'],
            },
            'ja-JP': {
                label: 'Japanese',
                femaleVoices: ['NanamiNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaomengNeural', 'XiaoshuangNeural', 'XiaoxiaoNeural'],
                maleVoices: ['YunxiaNeural'],
            },
        },
    },
    chat_casual: {
        code: 'chat-casual',
        label: 'Chat Casual',
        exampleContent: {
            'de-DE': 'Hey, was geht ab?',
            'en-GB': "Hey, what's up?",
            'en-US': "Hey, what's up?",
            'es-MX': 'Hola, ¿qué tal?',
            'fr-FR': 'Salut, quoi de neuf ?',
            'it-IT': 'Ehi, cosa c’è?',
            'ja-JP': 'やあ、どうしたの？',
            'pt-BR': 'Oi, tudo bem?',
            'zh-CN': '嘿，怎么了？',
        },
        description: '"Chat" expresses a casual and relaxed tone.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
    cheerful: {
        label: 'Cheerful',
        exampleContent: {
            'de-DE': 'Es ist ein wunderschöner Tag!',
            'en-GB': "It's a beautiful day!",
            'en-US': "It's a beautiful day!",
            'es-MX': '¡Es un día hermoso!',
            'fr-FR': 'Quelle belle journée !',
            'it-IT': 'È una giornata bellissima!',
            'ja-JP': '素晴らしい一日だ！',
            'pt-BR': 'É um dia lindo!',
            'zh-CN': '今天是个美好的一天！',
        },
        description: '"Cheerful" expresses a positive and happy tone.',
        languages: {
            'de-DE': {
                label: 'German',
                maleVoices: ['ConradNeural'],
            },
            'en-GB': {
                label: 'English (British)',
                femaleVoices: ['SoniaNeural'],
            },
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },

            'es-MX': {
                label: 'Spanish (Mexican)',
                maleVoices: ['JorgeNeural'],
            },
            'fr-FR': {
                label: 'French',
                femaleVoices: ['DeniseNeural'],
                maleVoices: ['HenriNeural'],
            },
            'it-IT': {
                label: 'Italian',
                femaleVoices: ['IsabellaNeural'],
            },
            'ja-JP': {
                label: 'Japanese',
                femaleVoices: ['NanamiNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',

                    'XiaomoNeural',

                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                    'XiaoyiNeural',
                    'XiaozhenNeural'
                ],
                maleVoices: [

                    'YunfengNeural',

                    'YunjianNeural',
                    'YunxiaNeural',
                    'YunxiNeural',

                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    customer_service: {
        code: 'customerservice',
        label: 'Customer Service',
        exampleContent: {
            'de-DE': 'Wie kann ich Ihnen heute helfen?',
            'en-GB': 'How can I help you today?',
            'en-US': 'How can I help you today?',
            'es-MX': '¿En qué puedo ayudarte hoy?',
            'fr-FR': 'Comment puis-je vous aider aujourd’hui ?',
            'it-IT': 'Come posso aiutarti oggi?',
            'ja-JP': '今日はどうお手伝いできますか？',
            'pt-BR': 'Como posso ajudar você hoje?',
            'zh-CN': '今天我能帮你什么忙？',
        },
        description: '"Customer Service" expresses a friendly and helpful tone for customer support.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural'],
            },

            'ja-JP': {
                label: 'Japanese',
                femaleVoices: ['NanamiNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
                maleVoices: ['YunyangNeural'],
            },
        },
    },
    depressed: {
        label: 'Depressed',
        exampleContent: {
            'de-DE': 'Ich habe einfach keine Lust, etwas zu tun.',
            'en-GB': "I just don't feel like doing anything.",
            'en-US': "I just don't feel like doing anything.",
            'es-MX': 'Simplemente no tengo ganas de hacer nada.',
            'fr-FR': 'Je n’ai pas envie de faire quoi que ce soit.',
            'it-IT': 'Non ho proprio voglia di fare nulla.',
            'ja-JP': '何もしたくないです。',
            'pt-BR': 'Eu simplesmente não tenho vontade de fazer nada.',
            'zh-CN': '我就是不想做任何事情。',
        },
        description: '"Depressed" expresses a melancholic and despondent tone with lower pitch and energy.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaomoNeural',

                    'XiaoxuanNeural',

                ],
                maleVoices: [

                    'YunfengNeural',

                    'YunjianNeural',

                    'YunxiNeural',

                    'YunzeNeural',
                ],
            },
        },
    },
    disgruntled: {
        label: 'Disgruntled',
        exampleContent: {
            'de-DE': 'Das ist nicht das, was ich verlangt habe!',
            'en-GB': 'This is not what I asked for!',
            'en-US': 'This is not what I asked for!',
            'es-MX': '¡Esto no es lo que pedí!',
            'fr-FR': 'Ce n’est pas ce que j’ai demandé !',
            'it-IT': 'Questo non è quello che ho chiesto!',
            'ja-JP': 'これは私が頼んだものと違います！',
            'pt-BR': 'Isso não é o que eu pedi!',
            'zh-CN': '这不是我要的！',
        },
        description:
            '"Disgruntled" expresses a disdainful and complaining tone. Speech of this emotion displays displeasure and contempt.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',

                    'XiaomoNeural',

                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                    'XiaoyiNeural',
                    'XiaozhenNeural',
                ],
                maleVoices: [
                    'YunfengNeural',

                    'YunjianNeural',

                    'YunxiNeural',

                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    documentary_narration: {
        code: 'documentary-narration',
        label: 'Documentary Narration',
        exampleContent: {
            'de-DE': 'Heute erkunden wir die Geheimnisse des Ozeans.',
            'en-GB': 'Today, we explore the mysteries of the ocean.',
            'en-US': 'Today, we explore the mysteries of the ocean.',
            'es-MX': 'Hoy exploramos los misterios del océano.',
            'fr-FR': 'Aujourd’hui, nous explorons les mystères de l’océan.',
            'it-IT': 'Oggi esploriamo i misteri dell’oceano.',
            'ja-JP': '今日は、海の謎を探求します。',
            'pt-BR': 'Hoje, exploramos os mistérios do oceano.',
            'zh-CN': '今天，我们探索海洋的奥秘。',
        },
        description:
            '"Documentary Narration" narrates documentaries in a relaxed, interested, and informative style suitable for dubbing documentaries, expert commentary, and similar content.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunjianNeural', 'YunzeNeural'],
            },
        },
    },
    embarrassed: {
        label: 'Embarrassed',
        exampleContent: {
            'de-DE': 'Ich kann nicht glauben, dass ich das gemacht habe!',
            'en-GB': "I can't believe I did that!",
            'en-US': "I can't believe I did that!",
            'es-MX': 'No puedo creer que haya hecho eso!',
            'fr-FR': 'Je n’arrive pas à croire que j’ai fait ça !',
            'it-IT': 'Non posso credere di aver fatto questo!',
            'ja-JP': '私がそれをしたとは信じられない！',
            'pt-BR': 'Não posso acreditar que fiz isso!',
            'zh-CN': '我简直不敢相信我做了那件事！',
        },
        description:
            '"Embarrassed" expresses an uncertain and hesitant tone when the speaker is feeling uncomfortable.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaohanNeural', 'XiaomoNeural', 'XiaoyiNeural',],
                maleVoices: ['YunxiNeural', 'YunyeNeural'],
            },
        },
    },
    empathetic: {
        label: 'Empathetic',
        exampleContent: {
            'de-DE': 'Ich verstehe, wie du dich fühlst.',
            'en-GB': 'I understand how you feel.',
            'en-US': 'I understand how you feel.',
            'es-MX': 'Entiendo cómo te sientes.',
            'fr-FR': 'Je comprends ce que vous ressentez.',
            'it-IT': 'Capisco come ti senti.',
            'ja-JP': '君の気持ちを理解しています。',
            'pt-BR': 'Eu entendo como você se sente.',
            'zh-CN': '我理解你的感受。',
        },
        description: '"Empathetic" expresses a sense of caring and understanding.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural'],
            },
        },
    },
    envious: {
        label: 'Envious',
        exampleContent: {
            'de-DE': 'Ich wünschte, ich hätte das auch.',
            'en-GB': 'I wish I had that too.',
            'en-US': 'I wish I had that too.',
            'es-MX': 'Ojalá yo también tuviera eso.',
            'fr-FR': "J'aimerais tant en avoir un aussi !",
            'it-IT': 'Vorrei avere anche io quello.',
            'ja-JP': '私もそれが欲しい。',
            'pt-BR': 'Eu queria ter isso também.',
            'zh-CN': '我也希望我有那个。',
        },
        description: '"Envious" expresses a tone of admiration when you desire something that someone else has.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaomoNeural'],
            },
        },
    },
    excited: {
        label: 'Excited',
        exampleContent: {
            'de-DE': 'Ich kann es kaum erwarten, es zu sehen!',
            'en-GB': "I can't wait to see it!",
            'en-US': "I can't wait to see it!",
            'es-MX': '¡No puedo esperar a verlo!',
            'fr-FR': 'J’ai hâte de le voir !',
            'it-IT': 'Non vedo l’ora di vederlo!',
            'ja-JP': 'それを見るのが待ちきれません！',
            'pt-BR': 'Mal posso esperar para ver!',
            'zh-CN': '我迫不及待要看到它！',
        },
        description:
            '"Excited" expresses an upbeat and hopeful tone. It sounds like something great is happening and the speaker is happy about it.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
        },
    },
    fearful: {
        label: 'Fearful',
        exampleContent: {
            'de-DE': 'Ich habe Angst vor der Dunkelheit.',
            'en-GB': "I'm scared of the dark.",
            'en-US': "I'm scared of the dark.",
            'es-MX': 'Tengo miedo a la oscuridad.',
            'fr-FR': 'J’ai peur du noir.',
            'it-IT': 'Ho paura del buio.',
            'ja-JP': '私は暗闇が怖いです。',
            'pt-BR': 'Tenho medo do escuro.',
            'zh-CN': '我害怕黑暗。',
        },
        description:
            '"Fearful" expresses a scared and nervous tone, with higher pitch, higher vocal energy, and faster rate. The speaker is in a state of tension and unease.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',

                    'XiaomoNeural',
                    'XiaoruiNeural',

                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                    'XiaoyiNeural',
                    'XiaozhenNeural',

                ],
                maleVoices: [

                    'YunfengNeural',

                    'YunxiaNeural',
                    'YunxiNeural',

                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    friendly: {
        label: 'Friendly',
        exampleContent: {
            'de-DE': 'Hallo, wie geht es dir heute?',
            'en-GB': 'Hi, how are you doing today?',
            'en-US': 'Hi, how are you doing today?',
            'es-MX': 'Hola, ¿cómo estás hoy?',
            'fr-FR': 'Salut, comment ça va aujourd’hui ?',
            'it-IT': 'Ciao, come stai oggi?',
            'ja-JP': 'こんにちは、今日はどうですか？',
            'pt-BR': 'Oi, como você está hoje?',
            'zh-CN': '嗨，你今天好吗？',
        },
        description: '"Friendly" expresses a pleasant, inviting, and warm tone. It sounds sincere and caring.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
    gentle: {
        label: 'Gentle',
        exampleContent: {
            'de-DE': 'Bitte sei vorsichtig damit.',
            'en-GB': 'Please be careful with that.',
            'en-US': 'Please be careful with that.',
            'es-MX': 'Por favor, ten cuidado con eso.',
            'fr-FR': 'S’il vous plaît, soyez prudent avec ça.',
            'it-IT': 'Per favore, sii delicato con quello.',
            'ja-JP': 'それを扱う時は注意してください。',
            'pt-BR': 'Por favor, tenha cuidado com isso.',
            'zh-CN': '请小心对待。',
        },
        description: '"Gentle" expresses a mild, polite, and pleasant tone, with lower pitch and vocal energy.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaohanNeural', 'XiaomoNeural', 'XiaoxiaoNeural', 'XiaoxuanNeural', 'XiaoyiNeural'],
            },
        },
    },
    hopeful: {
        label: 'Hopeful',
        exampleContent: {
            'de-DE': 'Ich glaube, alles wird sich zum Guten wenden.',
            'en-GB': 'I believe everything will work out.',
            'en-US': 'I believe everything will work out.',
            'es-MX': 'Creo que todo saldrá bien.',
            'fr-FR': 'Je crois que tout va s’arranger.',
            'it-IT': 'Credo che tutto si risolverà.',
            'ja-JP': '私はすべてがうまくいくと信じています。',
            'pt-BR': 'Eu acredito que tudo vai dar certo.',
            'zh-CN': '我相信一切都会好起来的。',
        },
        description:
            '"Hopeful" expresses a warm and yearning tone. It sounds like something good will happen to the speaker.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
        },
    },
    lyrical: {
        label: 'Lyrical',
        exampleContent: {
            'de-DE': 'Lass mich dir ein Lied singen.',
            'en-GB': 'Let me sing you a song.',
            'en-US': 'Let me sing you a song.',
            'es-MX': 'Déjame cantarte una canción.',
            'fr-FR': 'Laissez-moi vous chanter une chanson.',
            'it-IT': 'Lasciami cantarti una canzone.',
            'ja-JP': '私があなたに歌を歌わせてください。',
            'pt-BR': 'Deixe-me cantar uma música para você.',
            'zh-CN': '让我给你唱首歌。',
        },
        description: '"Lyrical" expresses emotions in a melodic and sentimental way.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
    narration_professional: {
        label: 'Narration Professional',
        code: 'narration-professional',
        exampleContent: {
            'de-DE': 'In einer Welt voller Magie und Abenteuer gibt es immer einen Bösewicht...',
            'en-GB': 'In a world of magic and adventure, there is always a villain...',
            'en-US': 'In a world of magic and adventure, there is always a villain...',
            'es-MX': 'En un mundo de magia y aventura, siempre hay un villano...',
            'fr-FR': 'Dans un monde de magie et d’aventure, il y a toujours un méchant quelque part...',
            'it-IT': 'In un mondo di magia e avventura, c’è sempre un cattivo...',
            'ja-JP': '魔法と冒険の世界には、常に悪役がいます...',
            'pt-BR': 'Em um mundo de magia e aventura, sempre há um vilão...',
            'zh-CN': '在一个充满魔力和冒险的世界里，总是有一个恶棍...',
        },
        description: '"Narration Professional" expresses a professional, objective tone for content reading.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunyangNeural'],
            },
        },
    },
    narration_relaxed: {
        label: 'Narration Relaxed',
        code: 'narration-relaxed',
        exampleContent: {
            'de-DE': 'Entspannen Sie sich und hören Sie mir zu...',
            'en-GB': 'Relax and listen to me...',
            'en-US': 'Relax and listen to me...',
            'es-MX': 'Relájate y escúchame...',
            'fr-FR': 'Détendez-vous et écoutez-moi...',
            'it-IT': 'Rilassati e ascoltami...',
            'ja-JP': 'リラックスして私の話を聞いてください...',
            'pt-BR': 'Relaxe e me ouça...',
            'zh-CN': '放松并听我说...',
        },
        description: '"Narration Relaxed" expresses a soothing and melodious tone for content reading.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunjianNeural', 'YunxiNeural'],
            },
        },
    },
    newscast: {
        label: 'Newscast',
        exampleContent: {
            'de-DE': 'Gerade eben aus dem Nachrichtenstudio. Hühner haben jetzt Zähne.',
            'en-GB': 'This just in from the newsroom. Chickens now have teeth.',
            'en-US': 'This just in from the newsroom. Chickens now have teeth.',
            'es-MX': 'Esto acaba de llegar desde la sala de noticias. Los pollos ahora tienen dientes.',
            'fr-FR': "La nouvelle vient d'arriver. Les poules ont maintenant des dents.",
            'it-IT': 'Appena arrivato dalla redazione. I polli hanno ora i denti.',
            'ja-JP': 'ニュースルームからの最新情報です。チキンには今歯が生えています。',
            'pt-BR': 'Acabou de chegar da sala de notícias. Agora, as galinhas têm dentes.',
            'zh-CN': '刚刚从新闻室传来。鸡现在有牙齿了。',
        },
        description: '"Newscast" expresses a formal and professional tone for narrating news.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['JennyNeural'],
                maleVoices: ['GuyNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
                maleVoices: ['YunxiNeural'],
            },
        },
    },
    newscast_casual: {
        label: 'Newscast Casual',
        code: 'newscast-casual',
        exampleContent: {
            'de-DE': 'Eilige Nachrichten. Hühner haben jetzt Zähne.',
            'en-GB': 'Breaking news. Chickens now have teeth.',
            'en-US': 'Breaking news. Chickens now have teeth.',
            'es-MX': 'Noticias de última hora. Los pollos ahora tienen dientes.',
            'fr-FR': 'Aux dernières nouvelles, les poules ont maintenant des dents.',
            'it-IT': 'Notizie dell’ultima ora. I polli hanno ora i denti.',
            'ja-JP': '速報です。チキンには今歯が生えています。',
            'pt-BR': 'Últimas notícias. Agora, as galinhas têm dentes.',
            'zh-CN': '突发新闻。鸡现在有牙齿了。',
        },
        description: '"Newscast Casual" expresses a versatile and casual tone for general news delivery.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunyangNeural'],
            },
        },
    },
    newscast_formal: {
        label: 'Newscast Formal',
        code: 'newscast-formal',
        exampleContent: {
            'de-DE': 'In den heutigen Nachrichten, Hühner mit Zähnen übernehmen die Welt.',
            'en-GB': "In today's news, chickens with teeth invade the world.",
            'en-US': "In today's news, chickens with teeth invade the world.",
            'es-MX': 'En las noticias de hoy, pollos con dientes invaden el mundo.',
            'fr-FR': "Dans les nouvelles d'aujourd'hui, les poules ont des dents, et elles envahissent le monde.",
            'it-IT': 'Nelle notizie di oggi, i polli con i denti invadono il mondo.',
            'ja-JP': '今日のニュースでは、歯を持つ鶏が世界を侵略します。',
            'pt-BR': 'Nas notícias de hoje, galinhas com dentes invadem o mundo.',
            'zh-CN': '在今天的新闻中，有牙齿的鸡入侵了世界。',
        },
        description: '"Newscast Formal" expresses a formal, confident, and authoritative tone for news delivery.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural'],
            },
        },
    },
    poetry_reading: {
        label: 'Poetry Reading',
        code: 'poetry-reading',
        exampleContent: {
            'de-DE': 'Sein oder nicht sein, das ist die Frage...',
            'en-GB': 'To be, or not to be, that is the question...',
            'en-US': 'To be, or not to be, that is the question...',
            'es-MX': 'Ser, o no ser, esa es la cuestión...',
            'fr-FR': 'Être, ou ne pas être, telle est la question...',
            'it-IT': 'Essere, o non essere, questo è il dilemma...',
            'ja-JP': '生きるか死ぬか、それが問題だ...',
            'pt-BR': 'Ser ou não ser, eis a questão...',
            'zh-CN': '生存还是毁灭，这是个问题...',
        },
        description: '"Poetry Reading" expresses an emotional and rhythmic tone while reading a poem.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
    sad: {
        label: 'Sad',
        exampleContent: {
            'de-DE': 'Ich fühle mich so allein...',
            'en-GB': 'I feel so alone...',
            'en-US': 'I feel so alone...',
            'es-MX': 'Me siento tan solo...',
            'fr-FR': 'Je me sens tellement seul...',
            'it-IT': 'Mi sento così solo...',
            'ja-JP': 'とても孤独に感じます...',
            'pt-BR': 'Eu me sinto tão sozinho...',
            'zh-CN': '我感到如此孤独...',
        },
        description: '"Sad" expresses a sorrowful tone.',
        languages: {
            'en-GB': {
                label: 'English (British)',
                femaleVoices: ['SoniaNeural'],
            },
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },

            'fr-FR': {
                label: 'French',
                femaleVoices: ['DeniseNeural'],
                maleVoices: ['HenriNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',

                    'XiaomoNeural',
                    'XiaoruiNeural',

                    'XiaoxiaoNeural',

                    'XiaoyiNeural',
                    'XiaozhenNeural',
                ],
                maleVoices: [
                    'YunfengNeural',

                    'YunjianNeural',
                    'YunxiaNeural',
                    'YunxiNeural',

                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    serious: {
        label: 'Serious',
        exampleContent: {
            'de-DE': 'Das ist kein Witz...',
            'en-GB': 'This is not a joke...',
            'en-US': 'This is not a joke...',
            'es-MX': 'Esto no es una broma...',
            'fr-FR': 'Ce n’est pas une blague...',
            'it-IT': 'Questo non è uno scherzo...',
            'ja-JP': 'これは冗談ではありません...',
            'pt-BR': 'Isto não é uma piada...',
            'zh-CN': '这不是一个笑话...',
        },
        description:
            '"Serious" expresses a strict and commanding tone. Speaker often sounds stiffer and much less relaxed with firm cadence.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: [
                    'XiaohanNeural',

                    'XiaomoNeural',

                    'XiaoxiaoNeural',
                    'XiaoxuanNeural',
                    'XiaoyiNeural',
                    'XiaozhenNeural',
                ],
                maleVoices: [
                    'YunfengNeural',

                    'YunjianNeural',

                    'YunxiNeural',

                    'YunyeNeural',
                    'YunzeNeural',
                ],
            },
        },
    },
    shouting: {
        label: 'Shouting',
        exampleContent: {
            'de-DE': 'Kannst du mich hören?',
            'en-GB': 'Can you hear me?',
            'en-US': 'Can you hear me?',
            'es-MX': '¿Puedes escucharme?',
            'fr-FR': 'Est-ce que vous m’entendez ?',
            'it-IT': 'Mi senti?',
            'ja-JP': '聞こえますか？',
            'pt-BR': 'Você pode me ouvir?',
            'zh-CN': '你能听到我吗？',
        },
        description:
            '"Shouting" expresses a tone that sounds as if the voice is distant or in another location and making an effort to be clearly heard.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
        },
    },
    sorry: {
        label: 'Sorry',
        exampleContent: {
            'de-DE': 'undefined',
            'en-GB': "I'm so sorry. I really apologize for the inconvienence.",
            'en-US': "I'm so sorry. I really apologize for the inconvienence.",
            'es-MX': 'undefined',
            'fr-FR': 'undefined',
            'it-IT': 'undefined',
            'ja-JP': 'undefined',
            'pt-BR': 'undefined',
            'zh-CN': '非常抱歉。我真的为给您带来的不便道歉。',
        },
        description:
            '"Sorry" expresses regret, an apology, or a feeling of sorrow for a mistake, inconvenience, or offense caused to someone else.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
    sports_commentary: {
        label: 'Sports Commentary',
        code: 'sports-commentary',
        exampleContent: {
            'de-DE': 'Und er trifft!',
            'en-GB': 'And he scores!',
            'en-US': 'And he scores!',
            'es-MX': '¡Y anota!',
            'fr-FR': 'Et il marque !',
            'it-IT': 'E segna!',
            'ja-JP': '彼が得点します！',
            'pt-BR': 'E ele marca!',
            'zh-CN': '他进球了！',
        },
        description: '"Sports Commentary" expresses a relaxed and interested tone for broadcasting a sports event.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunjianNeural'],
            },
        },
    },
    sports_commentary_excited: {
        label: 'Sports Commentary Excited',
        code: 'excited',
        exampleContent: {
            'de-DE': 'Was für ein Tor!',
            'en-GB': 'What a goal!',
            'en-US': 'What a goal!',
            'es-MX': '¡Qué golazo!',
            'fr-FR': 'Quel but incroyable !',
            'it-IT': 'Che goal!',
            'ja-JP': 'なんてゴール！',
            'pt-BR': 'Que gol!',
            'zh-CN': '多漂亮的进球！',
        },
        description:
            '"Sports Commentary Excited" expresses an intensive and energetic tone for broadcasting exciting moments in a sports event.',
        languages: {
            'zh-CN': {
                label: 'Chinese',
                maleVoices: ['YunjianNeural'],
            },
        },
    },
    terrified: {
        label: 'Terrified',

        exampleContent: {
            'de-DE':
                'Letzte Nacht hörte ich seltsame Geräusche aus dem Kellerbüro, und ich konnte mich vor Angst nicht bewegen.',
            'en-GB':
                "I heard strange noises coming from the basement office last night, and I couldn't move out of fear.",
            'en-US':
                "I heard strange noises coming from the basement office last night, and I couldn't move out of fear.",
            'es-MX':
                'Anoche escuché extraños ruidos provenientes de la oficina del sótano, y no pude moverme de miedo.',
            'fr-FR':
                "J'ai entendu des bruits étranges venant du bureau du sous-sol la nuit dernière ; j'avais tellement peur que je n'ai pas pu bouger.",
            'it-IT':
                "Ieri sera ho sentito strani rumori provenire dall'ufficio del seminterrato, e non sono riuscito a muovermi per paura.",
            'ja-JP': '昨夜、地下の事務所から奇妙な音が聞こえ、恐怖で動けませんでした。',
            'pt-BR':
                'Ontem à noite, ouvi estranhos ruídos vindos do escritório do porão e não consegui me mexer de medo.',
            'zh-CN': '昨晚我听到了地下办公室传来的奇怪声音，我因恐惧而无法移动。',
        },
        description:
            '"Terrified" expresses a scared tone, with a faster pace and a shakier voice. It sounds like the speaker is in an unsteady and frantic status.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
        },
    },
    unfriendly: {
        label: 'Unfriendly',
        exampleContent: {
            'de-DE': 'Bleib weg von mir!',
            'en-GB': 'Stay away from me!',
            'en-US': 'Stay away from me!',
            'es-MX': '¡Aléjate de mí!',
            'fr-FR': 'Éloignez-vous de moi !',
            'it-IT': 'Stai lontano da me!',
            'ja-JP': '私から離れて！',
            'pt-BR': 'Fique longe de mim!',
            'zh-CN': '离我远点！',
        },
        description:
            '"Unfriendly" expresses a scared tone, with a faster pace and a shakier voice. It sounds like the speaker is in an unsteady and frantic status.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural'],
            },
        },
    },
    whispering: {
        label: 'Whispering',
        exampleContent: {
            'de-DE': 'Ich habe dir ein Geheimnis zu verraten...',
            'en-GB': 'I have a secret to tell you...',
            'en-US': 'I have a secret to tell you...',
            'es-MX': 'Tengo un secreto que contarte...',
            'fr-FR': 'J’ai un secret à vous dire...',
            'it-IT': 'Ho un segreto da dirti...',
            'ja-JP': 'あなたに秘密を教えることがあります...',
            'pt-BR': 'Eu tenho um segredo para te contar...',
            'zh-CN': '我有一个秘密要告诉你...',
        },
        description: '"Whispering" expresses a soft tone that\'s trying to make a quiet and gentle sound.',
        languages: {
            'en-US': {
                label: 'English (American)',
                femaleVoices: ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                maleVoices: ['JasonNeural', 'GuyNeural', 'TonyNeural'],
            },

            'zh-CN': {
                label: 'Chinese',
                femaleVoices: ['XiaoxiaoNeural'],
            },
        },
    },
}

export const facialEmotions = {
    joy: "Joy",
    trust: "Trust",
    fear: "Fear",
    surprise: "Surprise",
    sadness: "Sadness",
    disgust: "Disgust",
    anger: "Anger",
    anticipation: "Anticipation"
}

export const facialEmotionIntensities = [
    {
        Label: "weak",
        Value: 0
    },
    {
        Label: "normal",
        Value: 1
    },
    {
        Label: "strong",
        Value: 2
    }
];

export const uneeqActions = {
    'action_confused': {
        code: 'action_confused',
        label: 'Confusion',
        description: 'looking at hands, not knowing what to do'
    },
    'action_disappointed': {
        code: 'action_disappointed',
        label: 'Disappointment',
        description: 'furrowing brows, shaking head'
    },
    'action_shrug': {
        code: 'action_shrug',
        label: 'Shrugging',
        description: ''
    },
    'action_understandnod': {
        code: 'action_understandnod',
        label: 'Understanding',
        description: 'nodding'
    },
    'action_headaffirmdown': {
        code: 'action_headaffirmdown',
        label: 'Affirming',
        description: 'showing affirmation, head down'
    },
    'action_headaffirmup': {
        code: 'action_headaffirmup',
        label: 'Tilting head up',
        description: 'slightly tilting head up'
    },
    'action_headshakeslow': {
        code: 'action_headshakeslow',
        label: 'Saying no, slowly',
        description: ''
    },
    'action_headshakemedium': {
        code: 'action_headshakemedium',
        label: 'Saying no, faster',
        description: ''
    },
    'action_headshakefast': {
        code: 'action_headshakefast',
        label: 'Saying no, fastest',
        description: ''
    },
    'action_wavehello': {
        code: 'action_wavehello',
        label: 'Waving hello',
        description: 'waving the hand to say hello'
    },
    'action_fingerscrossed': {
        code: 'action_fingerscrossed',
        label: 'Crossing fingers',
        description: 'crossing fingers of both hands'
    },
    'action_hearthands': {
        code: 'action_hearthands',
        label: 'Loving',
        description: 'making a heart hand gesture'
    },
    'action_okhand': {
        code: 'action_okhand',
        label: 'OK',
        description: 'making an OK gesture with the hand'
    },
    'action_peacehand': {
        code: 'action_peacehand',
        label: 'Peace',
        description: 'making an peace gesture with the hand'
    },
    'action_raisehand': {
        code: 'action_raisehand',
        label: 'Raising hand',
        description: ''
    },
    'action_vulcansalute': {
        code: 'action_vulcansalute',
        label: 'Vulcan salute',
        description: ''
    },
    'action_wavebye': {
        code: 'action_wavebye',
        label: 'Goodbye',
        description: 'waving the hand to say goodbye'
    },
    'action_wavesalute': {
        code: 'action_wavesalute',
        label: 'Saluting with two fingers',
        description: 'raising two fingers at head to salute'
    },
    'action_admirenails': {
        code: 'action_admirenails',
        label: 'Looking at nails',
        description: ''
    },
    'action_bow': {
        code: 'action_bow',
        label: 'Bowing',
        description: ''
    },
    'action_callme': {
        code: 'action_callme',
        label: 'Making a call gesture',
        description: ''
    },
    'action_clap': {
        code: 'action_clap',
        label: 'Clapping hands',
        description: ''
    },
    'action_facepalm': {
        code: 'action_facepalm',
        label: 'Ouch',
        description: 'putting the palm of the hand on the forehead'
    },
    'action_fingerguns': {
        code: 'action_fingerguns',
        label: 'Bang bang',
        description: 'pointing both hands as guns'
    },
    'action_fistbump': {
        code: 'action_fistbump',
        label: 'Boxing fist',
        description: ''
    },
    'action_flexbiceps': {
        code: 'action_flexbiceps',
        label: 'Flexing the biceps',
        description: ''
    },
    'action_headnodfast': {
        code: 'action_headnodfast',
        label: 'Saying yes, multiple times, fastest',
        description: 'head nodding multiple times'
    },
    'action_headnodmedium': {
        code: 'action_headnodmedium',
        label: 'Saying yes, multiple times, fast',
        description: 'head nodding multiple times'
    },
    'action_headnodslow': {
        code: 'action_headnodslow',
        label: 'Saying yes, multiple times',
        description: 'head nodding multiple times'
    },
    'action_horns': {
        code: 'action_horns',
        label: 'Horns',
        description: 'making horn gesture with both hands'
    },
    'action_loveyou': {
        code: 'action_loveyou',
        label: 'Saying I love you',
        description: 'saying I love you with three consecutive hand gestures'
    },
    'action_nervous': {
        code: 'action_nervous',
        label: 'Being nervous',
        description: 'tilting head and turning eyes quickly'
    },
    'action_thinking': {
        code: 'action_thinking',
        label: 'Thinking',
        description: 'looking up'
    },
    'action_thumbsdown': {
        code: 'action_thumbsdown',
        label: 'Thumbs down',
        description: 'turning thumbs up and down, and finally down'
    },
    'action_thumbsup': {
        code: 'action_thumbsup',
        label: 'Thumbs up',
        description: ''
    },
    'action_wink': {
        code: 'action_wink',
        label: 'Winking',
        description: ''
    },
    'action_bowformal': {
        code: 'action_bowformal',
        label: 'Bowing, formally',
        description: 'joining hands before bowing'
    }
}

export const uneeqCameras = {
    'camera_face': {
        code: 'camera_face',
        label: 'Close Up',
        description: 'Zoom into a face close up',
        iconName: 'UNEEQ_CAMERA_FACE'
    },
    'camera_shoulders': {
        code: 'camera_shoulders',
        label: 'Medium Close Up',
        description: 'Zoom to show the chest up',
        iconName: 'UNEEQ_CAMERA_SHOULDERS'
    },
    'camera_torso': {
        code: 'camera_torso',
        label: 'Medium Shot',
        description: 'Zoom to show the hips up',
        iconName: 'UNEEQ_CAMERA_TORSO'
    },
    'camera_fullbody': {
        code: 'camera_fullbody',
        label: 'Full Shot',
        description: 'Zoom to show from feet to head',
        iconName: 'UNEEQ_CAMERA_FULLBODY'
    },
}
