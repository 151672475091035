import React from 'react'

import {
    ArrowLeftSVG,
    ArrowRightAlternativeSVG,
    ArrowRightSVG,
    AudioFeedbackSVG,
    AUnderscoreSVG,
    BulbSVG,
    CallSupportSVG,
    ChatAlternativeSVG,
    ChatAlternative2SVG,
    ChatSVG,
    ChevronLeftSVG,
    ChevronRightSVG,
    CircleMinusSVG,
    CirclePlusSVG,
    CogSVG,
    DiamondSVG,
    DocumentSVG,
    DownloadSVG,
    DragSVG,
    EmpathySVG,
    ExpandSVG,
    EyeSVG,
    FeaturesSVG,
    FilledEditSVG,
    FlagFiSVG,
    FlagFrSVG,
    FlagDeSVG,
    FlagEnSVG,
    FlagItSVG,
    GearSVG,
    HeartFeaturesSVG,
    HiddenSVG,
    LaptopSVG,
    CometSpinnerSVG,
    LockSVG,
    LogOffSVG,
    MailSVG,
    MenuSVG,
    MicrophoneSVG,
    PhoneSVG,
    PillsSVG,
    RefreshSVG,
    ResetSVG,
    StreamSVG,
    TextSizeSVG,
    VolumeSVG,
    ZoomInSVG,
    ZoomOutSVG,
    Glasses_0,
    Glasses_1,
    Glasses_2,
    Glasses_3,
    // PNC, name changed on frontend to simplify code
    // In future avoid using special symbols like "&" in code and dialogflow
    _Home,
    _Information,
    _Meet,
    _Post,
    _Repeat,
    _Ticket,
    Food_Not_Healthy,
    Food_Healthy,
    Gender_Female,
    Gender_Male,
    New_Tab,
    UneeqCameraFace,
    UneeqCameraFullbody,
    UneeqCameraShoulders,
    UneeqCameraTorso,
    Union,
    Close,
    Play,
    RadialGradientSVG,
} from '@Icons'

export enum IconVector {
    ARROW_LEFT = 'arrow_left',
    ARROW_RIGHT = 'arrow_right',
    ARROW_RIGHT_ALTERNATIVE = 'arrow_right_alternative',
    AUDIO_FEEDBACK = 'audio_feedback',
    A_UNDERSCORE = 'a_underscore',
    BULB = 'bulb',
    CALL_SUPPORT = 'call_support',
    CHAT = 'chat',
    CHAT_ALTERNATIVE = 'chat_alternative',
    CHAT_ALTERNATIVE_2 = 'chat_alternative_2',
    CHEVRON_LEFT = 'chevron_left',
    CHEVRON_RIGHT = 'chevron_right',
    CIRCLE_MINUS = 'cirlce_minus',
    CIRCLE_PLUS = 'circle_plus',
    COG = 'cog',
    DIAMOND = 'diamond',
    DOCUMENT = 'document',
    DOWNLOAD = 'download',
    DRAG = 'drag',
    EMPATHY = 'empathy',
    EXPAND = 'expand',
    EYE = 'eye',
    FEATURES = 'features',
    FILLED_EDIT = 'filled_edit',
    FLAG_FI = 'flag_fi',
    FLAG_FR = 'flag_fr',
    FLAG_IT = 'flag_it',
    FLAG_DE = 'flag_de',
    FLAG_EN = 'flag_en',
    GEAR = 'gear',
    HEART_FEATURES = 'heart_features',
    HIDDEN = 'hidden',
    LAPTOP = 'laptop',
    COMET_SPINNER = 'comet_spinner',
    LOCK = 'lock',
    LOGOFF = 'log_off',
    MAIL = 'mail',
    MENU = 'menu',
    MICROPHONE = 'microphone',
    PHONE = 'phone',
    PILLS = 'pills',
    REFRESH = 'refresh',
    RESET = 'reset',
    STREAM = 'stream',
    TEXT_SIZE = 'text_size',
    UNEEQ_CAMERA_FACE = 'uneeq_camera_face',
    UNEEQ_CAMERA_FULLBODY = 'uneeq_camera_fullbody',
    UNEEQ_CAMERA_SHOULDERS = 'uneeq_camera_shoulders',
    UNEEQ_CAMERA_TORSO = 'uneeq_camera_torso',
    UNION = 'union',
    VOLUME = 'volume',
    ZOOM_IN = 'zoom_in',
    ZOOM_OUT = 'zoom_out',
    NEW_TAB = 'new_tab',
    PLAY = 'play',
    CLOSE = 'close',
    // TODO: must be removed from base project
    GLASGES_0 = 'glasses_0',
    GLASGES_1 = 'glasses_1',
    GLASGES_2 = 'glasses_2',
    GLASGES_3 = 'glasses_3',
    FOOD_NOT_HEALTHY = 'food_not_healthy',
    FOOD_HEALTHY = 'food_healthy',
    GENDER_FEMALE = 'gender_female',
    GENDER_MALE = 'gender_male',
    _HOME = 'p&c_home',
    _INFORMATION = 'p&c_information',
    _MEET = 'p&c_meet',
    _POST = 'p&c_post',
    _REPEAT = 'p&c_repeat',
    _TICKET = 'p&c_ticket',
    RADIAL_GRADIENT = 'radial_gradient',
}
export interface IconProps {
    vector: IconVector | string
}

/* This components is used to represent an Icon as button. */
export class Icon extends React.Component<IconProps> {
    IconMap: Record<IconVector, React.FC<React.SVGProps<SVGSVGElement | React.ReactNode>>> = {
        [IconVector.ARROW_LEFT]: ArrowLeftSVG,
        [IconVector.ARROW_RIGHT]: ArrowRightSVG,
        [IconVector.ARROW_RIGHT_ALTERNATIVE]: ArrowRightAlternativeSVG,
        [IconVector.AUDIO_FEEDBACK]: AudioFeedbackSVG,
        [IconVector.A_UNDERSCORE]: AUnderscoreSVG,
        [IconVector.BULB]: BulbSVG,
        [IconVector.CALL_SUPPORT]: CallSupportSVG,
        [IconVector.CHAT]: ChatSVG,
        [IconVector.CHAT_ALTERNATIVE]: ChatAlternativeSVG,
        [IconVector.CHAT_ALTERNATIVE_2]: ChatAlternative2SVG,
        [IconVector.CHEVRON_LEFT]: ChevronLeftSVG,
        [IconVector.CHEVRON_RIGHT]: ChevronRightSVG,
        [IconVector.CIRCLE_MINUS]: CircleMinusSVG,
        [IconVector.CIRCLE_PLUS]: CirclePlusSVG,
        [IconVector.COG]: CogSVG,
        [IconVector.DIAMOND]: DiamondSVG,
        [IconVector.DOCUMENT]: DocumentSVG,
        [IconVector.DOWNLOAD]: DownloadSVG,
        [IconVector.DRAG]: DragSVG,
        [IconVector.EMPATHY]: EmpathySVG,
        [IconVector.EXPAND]: ExpandSVG,
        [IconVector.EYE]: EyeSVG,
        [IconVector.FEATURES]: FeaturesSVG,
        [IconVector.FILLED_EDIT]: FilledEditSVG,
        [IconVector.FLAG_FI]: FlagFiSVG,
        [IconVector.FLAG_FR]: FlagFrSVG,
        [IconVector.FLAG_DE]: FlagDeSVG,
        [IconVector.FLAG_EN]: FlagEnSVG,
        [IconVector.FLAG_IT]: FlagItSVG,
        [IconVector.GEAR]: GearSVG,
        [IconVector.HEART_FEATURES]: HeartFeaturesSVG,
        [IconVector.HIDDEN]: HiddenSVG,
        [IconVector.LAPTOP]: LaptopSVG,
        [IconVector.COMET_SPINNER]: CometSpinnerSVG,
        [IconVector.LOCK]: LockSVG,
        [IconVector.LOGOFF]: LogOffSVG,
        [IconVector.MAIL]: MailSVG,
        [IconVector.MENU]: MenuSVG,
        [IconVector.MICROPHONE]: MicrophoneSVG,
        [IconVector.PHONE]: PhoneSVG,
        [IconVector.PILLS]: PillsSVG,
        [IconVector.RESET]: ResetSVG,
        [IconVector.REFRESH]: RefreshSVG,
        [IconVector.STREAM]: StreamSVG,
        [IconVector.TEXT_SIZE]: TextSizeSVG,
        [IconVector.UNEEQ_CAMERA_FACE]: UneeqCameraFace,
        [IconVector.UNEEQ_CAMERA_FULLBODY]: UneeqCameraFullbody,
        [IconVector.UNEEQ_CAMERA_SHOULDERS]: UneeqCameraShoulders,
        [IconVector.UNEEQ_CAMERA_TORSO]: UneeqCameraTorso,
        [IconVector.VOLUME]: VolumeSVG,
        [IconVector.ZOOM_IN]: ZoomInSVG,
        [IconVector.ZOOM_OUT]: ZoomOutSVG,
        [IconVector.GLASGES_0]: Glasses_0,
        [IconVector.GLASGES_1]: Glasses_1,
        [IconVector.GLASGES_2]: Glasses_2,
        [IconVector.GLASGES_3]: Glasses_3,
        [IconVector.FOOD_NOT_HEALTHY]: Food_Not_Healthy,
        [IconVector.FOOD_HEALTHY]: Food_Healthy,
        [IconVector.GENDER_FEMALE]: Gender_Female,
        [IconVector.GENDER_MALE]: Gender_Male,
        [IconVector.NEW_TAB]: New_Tab,
        [IconVector.UNION]: Union,
        [IconVector.CLOSE]: Close,
        [IconVector.PLAY]: Play,
        [IconVector._HOME]: _Home,
        [IconVector._INFORMATION]: _Information,
        [IconVector._MEET]: _Meet,
        [IconVector._POST]: _Post,
        [IconVector._REPEAT]: _Repeat,
        [IconVector._TICKET]: _Ticket,
        [IconVector.RADIAL_GRADIENT]: RadialGradientSVG,
    }

    override render(): React.ReactNode {
        const IconSVG = this.IconMap[this.props.vector.toLowerCase() as keyof IconProps]

        return IconSVG ? (
            <div className="iconElement">
                <IconSVG />
            </div>
        ) : (
            <div className="iconElement">
                <img src={this.props.vector} alt="" />
            </div>
        )
    }
}
