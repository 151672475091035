import { EButtonState } from '../../button/buttonState';
import { vocalStyles } from '../data/constants';
import { Compatibility } from './compatibility';

export class VoiceStyles {
    public getAll(): Array<VoiceStyle> {
        const arrayOfVoiceStyles: Array<VoiceStyle> = Object.keys(vocalStyles).map((code: string) => {
            const label: string = vocalStyles[code].label;
            const description: string = vocalStyles[code].description;

            var voices = [];
            Object.entries(vocalStyles[code].languages).forEach(([languageCode, languageVoices]: [string, any]) => {
                const femaleVoices: Array<string> = languageVoices.femaleVoices;
                const maleVoices: Array<string> = languageVoices.maleVoices;
                const neutralVoices: Array<string> = languageVoices.neutralVoices;
                const femaleChildVoices: Array<string> = languageVoices.femaleChildVoices;
                const vocalStyleI18n: Array<string> = vocalStyles[code].exampleContent;
                if (vocalStyleI18n === undefined) {
                    console.log('vocalStyleI18n is undefined for code: ' + code);
                }
                const content: string | null =
                    vocalStyleI18n && vocalStyleI18n.hasOwnProperty(languageCode) ? vocalStyleI18n[languageCode] : null;
                //const content: string|null = vocalStyles[code].exampleContent[languageCode] ?? null;
                const languageVoicesEntry = {
                    female: femaleVoices,
                    femaleChild: femaleChildVoices,
                    male: maleVoices,
                    neutral: neutralVoices,
                    content: content,
                };
                voices[languageCode] = languageVoicesEntry;
            });
            return new VoiceStyle(code, label, description, voices);
        });
        return arrayOfVoiceStyles;
    }

    public getDefaultVoiceStyle(defaultVoiceStyleCode: string): VoiceStyle | undefined {
        return this.getAll().filter((voiceStyle: VoiceStyle) => voiceStyle.code === defaultVoiceStyleCode)[0];
    }

    public quantity(): number {
        return this.getAll().length;
    }
}

export class VoiceStyle {
    public code: string;
    public label: string;
    public description: string;
    public voices: Array<Array<string>>;
    public state: EButtonState;
    public currentCompatibility: Compatibility;

    /*///
        voices is structured like this:
        [
            "en-US" => [
                "female" => ['AriaNeural', 'JennyNeural', 'NancyNeural', 'JaneNeural', 'SaraNeural'],
                "male" => ['JasonNeural', 'DavisNeural', 'GuyNeural', 'TonyNeural']
                "content" => "You mean the world to me."
            ]
        ]
    ///*/

    constructor(
        code: string,
        label: string,
        description: string,
        voices: Array<Array<string>>,
        state: EButtonState = EButtonState.DEFAULT,
        currentCompatibility: Compatibility = new Compatibility(),
    ) {
        this.code = code;
        this.label = label;
        this.description = description;
        this.voices = voices;
        this.state = state;
        this.currentCompatibility = currentCompatibility;
    }
}
