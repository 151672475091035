import { useEffect, useState } from 'react';
import { DigitalHumanContainer, MainContainer, QuotesShuffle, LoadingBar } from '@Components';
import { t } from 'i18next';
import type * as types from '@Entities/interfaces';
import * as enums from '@Application/entities/enums';

function LoadingController(services: types.IServices) {
    const [containerVisibility, setContainerVisibility] = useState(false);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        services.InterfaceManager.onStageChanged((state) => {
            setContainerVisibility(state === enums.EStage.LOADING);
        });
        services.UneeqManager.onLoadingPercentageChange((percentage) => setPercentage(percentage));
    });
    return containerVisibility ? (
        <DigitalHumanContainer State={enums.EDigitalHumanState.CHAT}>
            <MainContainer
                Name={'LoadingController'}
                Visible={containerVisibility}
                Top={[]}
                Main={[]}
                Bottom={[
                    <QuotesShuffle Quotes={services.ConfigManager.config.content.loadingMessages} Interval={2000} />,
                    <LoadingBar
                        text={`${services.ConfigManager.config.content.AvatarName} ${t(
                            enums.ETranslation.LOADING_PAGE_LOADING_BAR,
                        )}`}
                        percentage={percentage}
                    />,
                ]}
            />
        </DigitalHumanContainer>
    ) : null;
}

export default LoadingController;
