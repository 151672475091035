import type * as types from '@Entities/interfaces'
import type { Observer } from 'rxjs'

export default abstract class UneeqEventObserver implements Observer<unknown> {
    protected services: types.IServices

    constructor(services: types.IServices) {
        this.services = services
    }

    abstract next(value: unknown): void

    abstract complete(): void

    abstract error(err: unknown): void
}
