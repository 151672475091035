import { ButtonElement, IncrementerElement, TextElement, MediaElement } from '@Entities'
import { Expose } from 'class-transformer'
import { v3 } from 'uuid'

export default class Message {
    @Expose()
    id?: string

    @Expose()
    page: string = ''

    @Expose()
    intent: string = ''

    @Expose()
    speech: string = ''

    @Expose()
    element?: TextElement | ButtonElement | IncrementerElement | MediaElement

    @Expose()
    userTranscript?: string

    getGroupId = (): string => v3(this.page + this.intent + this.id, v3.URL)
}
