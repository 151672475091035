import React from 'react';
import { Label } from '@Components';

export interface TextProps {
    Label: string;
    Name?: string;
}

export class Text extends React.Component<TextProps, {}> {
    override render() {
        return (
            <div className={`text ${this.props.Name ? this.props.Name : 'default'}`}>
                <Label Text={this.props.Label} Name={`${this.props.Name ? this.props.Name : null}`} />
            </div>
        );
    }
}
