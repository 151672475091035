import { EButtonState } from '@Components';
import { uneeqCameras } from '../data/constants';

export class Camera {
    public code: string;
    public label: string;
    public description: string;
    public iconName: string | undefined;
    public state: EButtonState;

    constructor(
        code: string,
        label: string,
        description: string,
        iconName: string | undefined,
        state: EButtonState = EButtonState.DEFAULT,
    ) {
        this.code = code;
        this.label = label;
        this.state = state;
        this.iconName = iconName;
        this.description = description;
    }
}

export class Cameras {
    public getAll(): Array<Camera> {
        const arrayOfGestures: Array<Camera> = Object.keys(uneeqCameras).map((code: string) => {
            const label: string = uneeqCameras[code].label;
            const description: string =
                uneeqCameras[code].hasOwnProperty('description') && uneeqCameras[code].description !== undefined
                    ? uneeqCameras[code].description
                    : '';
            const iconName: string | undefined =
                uneeqCameras[code].hasOwnProperty('iconName') && uneeqCameras[code].iconName !== undefined
                    ? uneeqCameras[code].iconName
                    : undefined;
            return new Camera(code, label, description, iconName);
        });
        return arrayOfGestures;
    }
}
