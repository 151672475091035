import * as enums from '@Application/entities/enums'
import { AbstractElement } from '@Entities/abstractions'
import { Expose } from 'class-transformer'

export default class HumanizerElement extends AbstractElement {
    @Expose()
    name: string = 'humanizer'
    public elementType: enums.EElementType = enums.EElementType.HUMANIZER
    public type: enums.EElementType | string = enums.EElementType.HUMANIZER
}
