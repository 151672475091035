import UneeqEventObserver from './uneeqEventObserver'

export default class SpeechTranscriptionObserver extends UneeqEventObserver {
    complete = (): void => console.log('[TranscriptionObserver] - executed')

    error = (err: Error): void => console.error('[TranscriptionObserver]', err)

    next(value: Error): void {
        console.info('[TranscriptionObserver] - executed', value)
    }
}
