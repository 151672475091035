import * as enums from '@Application/entities/enums'
import { UserMessage } from '@Entities'
import I18n from '@Translation'
import { io } from 'socket.io-client'
import ConfigManager from './configManager'
import InterfaceManager from './InterfaceManager'
import type { IUserMessage, Message, UneeqSession } from '@Entities'
import type { DigitalHumanConfig } from '@Entities/models/digitalHumanConfig'
import type { Socket } from 'socket.io-client'

export default class BackendManager {
    private _socket: Socket | undefined
    private config: DigitalHumanConfig = ConfigManager.getInstance().config

    private interfaceManager: InterfaceManager = InterfaceManager.getInstance()

    private readonly url: string

    private session?: UneeqSession

    private constructor() {
        this.url = this.config.backend.Url
    }
    private get socket(): Socket {
        return this._socket!
    }

    private set socket(val: Socket) {
        this._socket = val
    }

    public static getInstance(): BackendManager {
        if (!BackendManager.instance) BackendManager.instance = new BackendManager()
        return BackendManager.instance
    }

    public async getUneeqSession(): Promise<UneeqSession> {
        if (!this.session) this.session = await this.initializeSession()
        return this.session
    }

    // Singleton
    private static instance: BackendManager

    public start(): void {
        this.socket = io(this.url, {
            transports: ['websocket'],
            query: {
                personaId: this.config.backend.PersonaId,
                language: I18n.language,
                env: this.config.backend.Env.id,
            },
            secure: true, // Enable secure connection (SSL)
            rejectUnauthorized: false, // Ignore SSL certificate validation errors
        })
    }

    private initializeSession(): Promise<UneeqSession> {
        console.info('Session Emit: ')
        this.socket.emit('CreateSession')

        return new Promise<UneeqSession>((resolve) => {
            this.socket.on('SessionCreated', (session: UneeqSession) => {
                console.info('Session created: ', session)
                resolve(session)
            })
        })
    }

    private transmitUserMessage(
        userMessage: UserMessage,
        sessionId: string,
        extraParams: {
            direct?: boolean
            noUserActionDelay?: boolean
            useDF?: boolean
        } = {
            direct: false,
            noUserActionDelay: false,
            useDF: true,
        },
    ): void {
        userMessage.sessionId = sessionId
        userMessage.language = I18n.language
        userMessage.direct = extraParams.direct
        userMessage.noUserActionDelay = extraParams.noUserActionDelay

        const emitName = extraParams.useDF ? 'query' : 'speak'

        this.socket.emit(emitName, userMessage)
    }

    public handleUserMessage(userMessage: IUserMessage): void {
        const { messageType, data, sessionId, eventName, text, keyValuePairs, page, actor } = userMessage

        if (!this.interfaceManager.inputBlocked) {
            this.interfaceManager.inputBlocked = true
            switch (messageType) {
                case enums.EMessageType.TEXT:
                    this.sendText(text!, sessionId!, page, actor)
                    break
                case enums.EMessageType.AUDIO:
                    this.sendAudio(data!, sessionId!)
                    break
                case enums.EMessageType.EVENT:
                    this.sendEvent(eventName!, sessionId!, page)
                    break
                case enums.EMessageType.PARAMS:
                    this.sendParams(keyValuePairs!, sessionId!, page)
                    break
                case enums.EMessageType.SPEAK:
                    this.sendSpeak(text!, sessionId!)
                    break
                default:
                    console.log('Tried to send unknown event to backend. Ignoring it', messageType)
                    break
            }
        }
    }

    public sendAudio(data: string, sessionId: string): void {
        const userMessage = new UserMessage()
        userMessage.dataType = enums.EDataType.AUDIO
        userMessage.data = data

        this.transmitUserMessage(userMessage, sessionId)
        console.info('Audio recorded sent to the backend')
    }

    public sendEvent(eventName: string, sessionId: string, page?: string): void {
        const userMessage = new UserMessage()
        userMessage.dataType = enums.EDataType.EVENT
        userMessage.data = eventName
        if (page) userMessage.page = page

        this.transmitUserMessage(userMessage, sessionId)
        console.info(`EventName ${eventName} sent to the backend`)
    }

    public sendText(text: string, session: string, page?: string, actor?: string): void {
        const userMessage = new UserMessage()
        userMessage.dataType = enums.EDataType.TEXT
        userMessage.data = text
        userMessage.page = page
        userMessage.actor = actor

        this.transmitUserMessage(userMessage, session)
        console.info(`Text sent to the backend: ${text}`)
    }

    public sendParams(keyValuePairs: { key: string; value: string }[], sessionId: string, page?: string): void {
        const jsonObject: string | Message = {} as unknown as Message // Type here is incorrect, but I do not understand full conect of this code. Forcing type for now;
        keyValuePairs.forEach((pair) => {
            jsonObject[pair.key] = pair.value
        })
        const userMessage = new UserMessage()
        userMessage.dataType = enums.EDataType.PARAMS
        userMessage.data = jsonObject
        userMessage.page = page

        this.transmitUserMessage(userMessage, sessionId)
        console.info('Params sent to the backend: ', jsonObject)
    }

    public sendSpeak(
        text: string,
        sessionId: string,
        extraParams: {
            direct?: boolean
            noUserActionDelay?: boolean
            useDF?: boolean
        } = {
            direct: false,
            noUserActionDelay: false,
            useDF: true,
        },
    ): void {
        const userMessage = new UserMessage()
        userMessage.dataType = enums.EDataType.TEXT
        userMessage.data = text

        this.transmitUserMessage(userMessage, sessionId, extraParams)
        console.info(`Speak sent to the backend: ${text}`)
    }
}
