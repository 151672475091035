import { useState } from 'react'
import * as types from '@Entities/interfaces'
import * as enums from '@Application/entities/enums'
import { DigitalHumanContainer } from '@Components'
import I18n from '@Translation'
import { InitialScreen } from '@Components'

function InitialController(services: types.IServices) {
    const [language, setLang] = useState(services.SettingManager.lang)
    const [containerVisibility, setContainerVisibility] = useState(true)
    const mode = (
        services.ConfigManager.config.components.initialController?.mode === enums.ELandingPage.VIDEO
            ? enums.ELandingPage.VIDEO
            : enums.ELandingPage.DEFAULT
    )

    services.InterfaceManager.onStageChanged((state) => setContainerVisibility(state === enums.EStage.INITIAL))

    const start = async () => {
        services.BackendManager.start()
        services.InterfaceManager.Stage = enums.EStage.LOADING
    }

    I18n.on('languageChanged', (lang) => setLang(lang))

    return containerVisibility ? (
        <DigitalHumanContainer
            State={
                mode === enums.ELandingPage.VIDEO
                    ? enums.EDigitalHumanState.EXTRA_CONTENT
                    : enums.EDigitalHumanState.CHAT
            }
        >
            <InitialScreen services={services} start={start} mode={mode} lang={language} />
        </DigitalHumanContainer>
    ) : null
}

export default InitialController
