import { useEffect, useRef, useState } from 'react'
import * as enums from '@Application/entities/enums'
import {
    EButtonState,
    DropdownAlignment,
    IconButton,
    IconVector,
    Logo,
    Bar,
    DigitalHumanContainer,
    MainContainer,
    VideoContainer,
    LabelButton,
    IconLabelButton,
    SoundVisualizator,
    Icon,
} from '@Components'
import { CaptionController, MediaGalleryController } from './contentControllers'
import ContentAreaController from './contentControllers/ContentAreaController'
import type * as types from '@Entities/interfaces'
import { MediaElement, Message, UneeqSession } from '@Entities'
import { useMediaQuery } from 'usehooks-ts'
import DropdownController, { DropdownTemplate } from './contentControllers/DropdownController'
import { t } from 'i18next'
import Countdown from 'react-countdown'
import { throttle } from 'lodash'

function DigitalHumanController(services: types.IServices) {
    // Create Refs
    const documentRef = useRef<Document>(document)
    const documentRefObject = documentRef.current
    const htmlRef = useRef<HTMLElement>(document.body.parentElement)
    const localVideoRef = useRef<HTMLDivElement | null>(null)
    const avatarVideoRef = useRef<HTMLDivElement | null>(null)
    const pushToTalkButtonRef = useRef<IconButton | null>(null)
    const chatPanelControlRef = useRef<HTMLButtonElement | null>(null)
    const galleryControlPrev = useRef<IconButton | null>(null)
    const galleryControlNext = useRef<IconButton | null>(null)
    // const historyForwardControlRef = useRef<IconButton|null>(null)
    // const historyPreviousControlRef = useRef<IconButton|null>(null)
    const bottomActionBarRef = useRef<Bar | null>(null)
    const [digitalHumanState, setDigitalHumanState] = useState<enums.EDigitalHumanState>(
        enums.EDigitalHumanState.AVATAR,
    )
    const [containerVisibility, setContainerVisibility] = useState(false)
    const [inputEnabled, setInputEnabled] = useState(services.InterfaceManager.inputEnabled)
    const [arrowState, setArrowState] = useState({ next: false, prev: false })
    const sessionId = services.UneeqManager.getSessionId() as string
    const page = services.ConfigManager.config.modules.dialogFlow?.page
    const isTimerEnabled = services.ConfigManager.config.modules.timer.enabled

    let mousemoveEndTimeout = 0
    const timerRef = useRef() as any
    const countdownTimeTotal = services.ConfigManager.config.modules.timer.countdownTimeInSecondsTotal * 1000
    const countdownTimeWarning = services.ConfigManager.config.modules.timer.countdownTimeInSecondsWarning * 1000
    const [isTimerActive, setIsTimerActive] = useState(false)
    const [countdownTime, setCountdownTime] = useState(Date.now() + countdownTimeTotal)
    const [svScale, setSvScale] = useState<Number>(1)
    const svScaleThrottledRef = useRef(throttle(level => {
        const svScaleLocal = level / 50

        setSvScale(svScaleLocal)
    }, 100))

    const isAvatarProcessingEnabled = services.ConfigManager.config.components.avatarProcessing.enabled
    const [isAvatarProcessing, setIsAvatarProcessing] = useState(true)

    let avatarProcessingTimeout

    const mediaQuery = useMediaQuery('(min-width: 3840px) OR (min-height: 2160px)')

    function toggleChatDigitalHumanState (state: enums.EDigitalHumanState) {
        if (state === enums.EDigitalHumanState.SIDE_PANEL) return

        let newState: enums.EDigitalHumanState
        if (services.InterfaceManager.getCurrentDigitalHumanState() === enums.EDigitalHumanState.CHAT) {
            //Todo: Check if the current record has extra content
            newState = services.HistoryManager.hasExtraContentByLastGroupId()
                ? enums.EDigitalHumanState.EXTRA_CONTENT
                : enums.EDigitalHumanState.AVATAR
        } else {
            newState = enums.EDigitalHumanState.CHAT
        }
        services.InterfaceManager.setDigitalHumanState(newState)
    }

    function measureUserActivity() {
        if (mousemoveEndTimeout) {
            timerRef.current?.stop()
            setIsTimerActive(false)
            window.clearTimeout(mousemoveEndTimeout)
        }

        mousemoveEndTimeout = window.setTimeout(() => {
            setCountdownTime(Date.now() + countdownTimeTotal)
            timerRef.current?.start()
        }, 1000)
    }

    function handleVideoContainerClick() {
        const currentState = services.InterfaceManager.getCurrentDigitalHumanState()
        switch (currentState) {
            case enums.EDigitalHumanState.CHAT:
                toggleChatDigitalHumanState(enums.EDigitalHumanState.CHAT)
                break
            case enums.EDigitalHumanState.FULLSCREEN_PHOTO:
                services.InterfaceManager.toggleMediaGalleryFullscreen()
                break
            case enums.EDigitalHumanState.FULLSCREEN_VIDEO:
                services.InterfaceManager.setDigitalHumanState(
                    services.InterfaceManager.getPreviousDigitalHumanState()!,
                )
                break
            case enums.EDigitalHumanState.MEDIA:
                services.HistoryManager.hasExtraContentByLastGroupId() ||
                services.HistoryManager.hasExtraContentByLastGroupId()
                    ? services.InterfaceManager.setDigitalHumanState(enums.EDigitalHumanState.EXTRA_CONTENT)
                    : services.InterfaceManager.setDigitalHumanState(enums.EDigitalHumanState.AVATAR)
                break
        }
    }

    function handleArrowClick(action: 'next' | 'prev') {
        const actionViable = services.InterfaceManager.galleryHasContent(action)
        switch (action) {
            case 'prev':
                if (!actionViable) console.log('Action unavailable')
                else services.InterfaceManager.changeGalleryIndex(action)
                break
            case 'next':
                if (!actionViable)
                    services.BackendManager.handleUserMessage({
                        messageType: enums.EMessageType.TEXT,
                        text: 'any',
                        sessionId: sessionId,
                    })
                else services.InterfaceManager.changeGalleryIndex(action)
                break
        }
    }

    function assignSpecialState(message: Message) {
        const isMediaElement = message?.element?.elementType === enums.EElementType.MEDIA
        let currentElement: MediaElement
        if (
            digitalHumanState !== enums.EDigitalHumanState.AVATAR &&
            digitalHumanState !== enums.EDigitalHumanState.EXTRA_CONTENT
        ) {
            if (digitalHumanState === enums.EDigitalHumanState.MEDIA) {
                if (isMediaElement) {
                    currentElement = message?.element as MediaElement
                    if (currentElement.media.mediaType !== 'video') return enums.EDigitalHumanState.MEDIA
                }
            } else if (digitalHumanState === enums.EDigitalHumanState.FULLSCREEN_VIDEO) {
                if (isMediaElement) {
                    currentElement = message?.element as MediaElement
                    if (currentElement.media.mediaType === 'video') {
                        services.InterfaceManager.setVideoIndex(services.InterfaceManager.getVideoCount())
                        return enums.EDigitalHumanState.FULLSCREEN_VIDEO
                    }
                }
                services.InterfaceManager.setVideoIndex(-1)
            }
        }
        return enums.EDigitalHumanState.EXTRA_CONTENT
    }

    function handleInputEnabled(value: boolean) {
        services.InterfaceManager.inputEnabled = value
        setInputEnabled(value)
    }

    function startAvatarProcessing(timeout: number = 1000) {
        clearTimeout(avatarProcessingTimeout)

        avatarProcessingTimeout = setTimeout(() => {
            setIsAvatarProcessing(true)
        }, timeout)
    }

    function stopAvatarProcessing() {
        clearTimeout(avatarProcessingTimeout)

        setIsAvatarProcessing(false)
    }

    useEffect(() => {
        const value = mediaQuery ?
            services.ConfigManager.config.theme.rootSize.large :
            services.ConfigManager.config.theme.rootSize.normal

        services.SettingManager.defaultInterfaceSize = value

        htmlRef.current && (htmlRef.current.style.fontSize = services.SettingManager.interfaceSizeStyle)
    }, [mediaQuery])

    useEffect(() => {
        const handleDigitalHumanStateSubscription = services.InterfaceManager.handleDigitalHumanState((state: { current: enums.EDigitalHumanState }) => {
            setDigitalHumanState(state.current)
        })

        const onGalleryChangeSubscription = services.InterfaceManager.onGalleryChange(() => {
            setArrowState({
                next:
                    services.HistoryManager.hasMediaContentByLastGroupId() ||
                    services.InterfaceManager.galleryHasContent('next'),
                prev: services.InterfaceManager.galleryHasContent('prev'),
            })
        })

        const onOutputLevelChangeSubscription = services.AudioMonitorManager.onOutputLevelChange((level: Number) => {
            svScaleThrottledRef.current(level)

            // if (level > 0) {
            //     console.log(`Average audio level [OUTPUT] => ${level}`)
            // }
        })

        // services.AudioMonitorManager.onInputLevelChange((level) => {
        //     if (level > 10) {
        //         console.log(`Average audio level [INPUT] => ${level}`)
        //     }
        // })

        const onStageChangedSubscription = services.InterfaceManager.onStageChanged((state: enums.EStage) => {
            if (state === enums.EStage.LOADING) {
                (async () => {
                    // Get the settings  from the backend
                    const uneeqSession = (await services.BackendManager.getUneeqSession()) as UneeqSession

                    // Initialize the Uneeq with the settings sent by the backend
                    await services.UneeqManager.connect({
                        token: uneeqSession.token,
                        server: uneeqSession.server,
                        avatarId: uneeqSession.avatarId,
                        localVideoContainerElement: localVideoRef.current,
                        avatarVideoContainerElement: avatarVideoRef.current,
                        backgroundUrl: services.ConfigManager.config.components.background.url,
                    })
                })().catch((error) => {
                    console.error(error)
                })
            }
            
            if (state === enums.EStage.DIGITAL_HUMAN) {
                services.AudioRecordManager.registerKeyboardEvents(documentRef)

                if (avatarVideoRef.current){
                    const audioMonitorManager = services.AudioMonitorManager.start();

                    audioMonitorManager?.createOutputSource((avatarVideoRef.current as HTMLDivElement).querySelector('video') as HTMLVideoElement); // For video audio
                    // audioMonitorManager?.createInputSource(); // For microphone
                }

                documentRefObject.addEventListener('mousemove', measureUserActivity)
                documentRefObject.addEventListener('touchmove', measureUserActivity)
                documentRefObject.addEventListener('keydown', measureUserActivity)
    
                timerRef.current?.start()
            } else {
                documentRefObject.removeEventListener('mousemove', measureUserActivity)
                documentRefObject.removeEventListener('touchmove', measureUserActivity)
                documentRefObject.removeEventListener('keydown', measureUserActivity)
            }

            setContainerVisibility(state === enums.EStage.DIGITAL_HUMAN)
        })

        // Monitor the audio record service data changes
        const onDataGeneratedSubscription = services.AudioRecordManager.onDataGenerated(data =>
            services.BackendManager.handleUserMessage({
                messageType: enums.EMessageType.AUDIO,
                data: data.audio.dataURL.split(',')[1],
                sessionId: services.UneeqManager.getSessionId() as string,
            }),
        )

        const onMessageStartSubscription = services.HistoryManager.onMessageStart((message: Message) => {
            // To avoid space bar to trigger the previous button
            // instead push to talk we remove the focus from the active element
            (documentRef.current.activeElement as HTMLElement).blur()

            const currentState = services.InterfaceManager.getCurrentDigitalHumanState()
            //Just to create the right stack we always start in the Avatar and Back to the current.

            stopAvatarProcessing()

            if (!message.element) return

            if (message.element.inputEnabled !== undefined) handleInputEnabled(message.element.inputEnabled)

            //To Avoid jump to extra content when chat is open
            let newState: enums.EDigitalHumanState | undefined = undefined

            if (currentState !== enums.EDigitalHumanState.CHAT) {
                if (message.element.isFirst) {
                    message.element.elementType === enums.EElementType.TEXT
                        ? (newState = enums.EDigitalHumanState.AVATAR)
                        : (newState = assignSpecialState(message))
                } else {
                    if (message.element.elementType !== enums.EElementType.TEXT)
                        newState = assignSpecialState(message)
                }
                if (newState !== undefined) {
                    services.InterfaceManager.setDigitalHumanState(newState)
                }
            }
        })

        const onMessageStopSubscription = services.HistoryManager.onMessageStop((message: Message) => {
            if (!message.element?.isLast) {
                startAvatarProcessing()
            } else {
                stopAvatarProcessing()
            }
        })

        if (pushToTalkButtonRef?.current) {
            services.AudioRecordManager.registerMouseEvents(pushToTalkButtonRef.current?.state.HtmlElement)
        }

        services.AudioRecordManager.registerKeyboardEvents(documentRef)

        return () => {
            handleDigitalHumanStateSubscription.unsubscribe()
            onGalleryChangeSubscription.unsubscribe()
            onOutputLevelChangeSubscription.unsubscribe()
            onStageChangedSubscription.unsubscribe()
            onDataGeneratedSubscription.unsubscribe()
            onMessageStartSubscription.unsubscribe()
            onMessageStopSubscription.unsubscribe()

            clearTimeout(avatarProcessingTimeout)
        }
    })

    return (
        <>
            {isTimerEnabled && (
                <Countdown
                    date={countdownTime}
                    ref={timerRef}
                    autoStart={false}
                    renderer={({ seconds }) => {
                        return (
                            <div className={`timer${isTimerActive ? ' timer-show' : ''}`}>
                                <h5 className="timer-title">{t(enums.ETranslation.TIMER_INACTIVITY_WARNING_SHORT)}</h5>
                                <div className="timer-clock">{seconds}</div>
                            </div>
                        )
                    }}
                    onTick={(timeDelta) => {
                        if (timeDelta.total > countdownTimeWarning) {
                            setIsTimerActive(false)
                        } else if (timeDelta.total === countdownTimeWarning) {
                            setIsTimerActive(false)

                            services.BackendManager.sendSpeak(
                                t(enums.ETranslation.TIMER_INACTIVITY_WARNING_LONG),
                                sessionId,
                                {
                                    noUserActionDelay: true,
                                    useDF: false,
                                },
                            )
                        } else {
                            setIsTimerActive(true)
                        }
                    }}
                    onStart={(timeDelta) => {
                        setIsTimerActive(timeDelta.total < countdownTimeWarning)
                    }}
                    onStop={(timeDelta) => {
                        setIsTimerActive(timeDelta.total < countdownTimeWarning)
                    }}
                    onComplete={() => {
                        window.location.reload()
                    }}
                />
            )}
            <DigitalHumanContainer State={digitalHumanState} Hidden={!containerVisibility}>
                <VideoContainer
                    LocalVideoRef={localVideoRef}
                    AvatarVideoRef={avatarVideoRef}
                    OnClick={handleVideoContainerClick}
                />
                <MainContainer
                    Name={'DigitalHumanController'}
                    Visible={containerVisibility}
                    Top={[
                        services.ConfigManager.config.components.logo.enabled && <Logo />,
                        <Bar
                            Name="topActionBar"
                            Left={[
                                <IconButton
                                    State={EButtonState.DEFAULT}
                                    Name={'ChatPanelControlTop'}
                                    Ref={chatPanelControlRef}
                                    IconVector={IconVector.CHAT}
                                    OnClick={() => toggleChatDigitalHumanState(enums.EDigitalHumanState.CHAT)}
                                />,
                            ]}
                            Center={[
                                services.ConfigManager.config.components.buttonWantToTry.enabled && services.ConfigManager.config.backend.PersonaId != '6' && (
                                    <LabelButton
                                        Label={'wantToTry'}
                                        Name={'editorial'}
                                        State={EButtonState.DEFAULT}
                                        OnClick={() =>
                                            services.BackendManager.handleUserMessage({
                                                messageType: enums.EMessageType.EVENT,
                                                eventName: enums.EMessageEventName.PROJECT_IDEA_1,
                                                page: page,
                                                sessionId: sessionId,
                                                actor: 'simulated',
                                            })
                                        }
                                    />
                                ),
                            ]}
                            Right={[
                                services.ConfigManager.config.components.buttonWantToTry.enabled && services.ConfigManager.config.backend.PersonaId == '6' && (
                                    <IconLabelButton
                                        IconVector={IconVector.BULB}
                                        Label={'wantToTry'}
                                        Name={'editorial projectIdea'}
                                        State={EButtonState.DEFAULT}
                                        OnClick={() =>
                                            services.BackendManager.handleUserMessage({
                                                messageType: enums.EMessageType.EVENT,
                                                eventName: enums.EMessageEventName.PROJECT_IDEA_1,
                                                page: page,
                                                sessionId: sessionId,
                                                actor: 'simulated',
                                            })
                                        }
                                    />
                                ),
                                <DropdownController
                                    Services={services}
                                    Icon={IconVector[services.ConfigManager.config.components.dropdown.icon]}
                                    IconName="SettingControl"
                                    Alignment={DropdownAlignment.BOTTOM_LEFT}
                                    Template={
                                        DropdownTemplate[services.ConfigManager.config.components.dropdown.template]
                                    }
                                />,
                            ]}
                        />,
                    ]}
                    Main={[
                        <ContentAreaController Services={services} timerRef={timerRef} startAvatarProcessing={startAvatarProcessing} />,
                        <MediaGalleryController />,
                        <CaptionController Services={services} />,
                    ]}
                    Bottom={[
                        <Bar
                            Name="bottomActionBar"
                            ref={bottomActionBarRef}
                            // Left={[
                            //     <IconButton State={ButtonState.BLOCKED} ref={historyPreviousControlRef}  Name="HistoryPreviousControl"  IconVector={IconVector.ARROW_LEFT} OnClick={() => historyPreviousControl()} />
                            // ]}
                            Left={[
                                <IconButton
                                    ref={galleryControlPrev}
                                    State={arrowState.prev ? EButtonState.DEFAULT : EButtonState.BLOCKED}
                                    Name={`GalleryControl prev`}
                                    IconVector={IconVector.ARROW_LEFT}
                                    OnClick={() => handleArrowClick('prev')}
                                />,
                            ]}
                            Center={[
                                (
                                    services.ConfigManager.config.components.soundVisualizator.enabled ?
                                    (
                                        <SoundVisualizator scale={svScale}>
                                            {services.ConfigManager.config.components.microphone.enabled &&
                                                <IconButton
                                                    State={EButtonState.DEFAULT}
                                                    Name={`PushToTalkControl${inputEnabled ? '' : ' hidden'}`}
                                                    IconVector={IconVector.MICROPHONE}
                                                    ref={pushToTalkButtonRef}
                                                />
                                            }
                                        </SoundVisualizator>
                                    ) :
                                    (
                                        services.ConfigManager.config.components.microphone.enabled &&
                                        (
                                            <IconButton
                                                State={EButtonState.DEFAULT}
                                                Name={`PushToTalkControl${inputEnabled ? '' : ' hidden'}`}
                                                IconVector={IconVector.MICROPHONE}
                                                ref={pushToTalkButtonRef}
                                            />
                                        )
                                    )
                                ),
                                (
                                    isAvatarProcessingEnabled && isAvatarProcessing &&
                                    (
                                        <div className='rotate360-infinity square-05'>
                                            <Icon vector={IconVector.COMET_SPINNER} />
                                        </div>
                                    )
                                ),
                                <IconButton
                                    Name={'ChatPanelControlBottom'}
                                    Ref={chatPanelControlRef}
                                    IconVector={IconVector.CHAT}
                                    OnClick={() => toggleChatDigitalHumanState(enums.EDigitalHumanState.CHAT)}
                                />,
                            ]}
                            Right={[
                                <IconButton
                                    ref={galleryControlNext}
                                    State={arrowState.next ? EButtonState.DEFAULT : EButtonState.BLOCKED}
                                    Name={`GalleryControl next`}
                                    IconVector={IconVector.ARROW_RIGHT}
                                    OnClick={() => handleArrowClick('next')}
                                />,
                            ]}
                            // Right={[
                            //     <IconButton State={ButtonState.BLOCKED} ref={historyForwardControlRef} Name={'HistoryForwardControl'} IconVector={IconVector.ARROW_RIGHT} OnClick={() => historyForwardControl()} />
                            // ]}
                        />,
                    ]}
                />
            </DigitalHumanContainer>
        </>
    )
}

export default DigitalHumanController
