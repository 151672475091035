// eslint-disable-next-line import/prefer-default-export
export enum EEventName {
    CLICK = 'click',
    MOUSE_DOWN = 'mousedown',
    MOUSE_UP = 'mouseup',
    MOUSE_MOVE = 'mousemove',
    KEY_DOWN = 'keydown',
    KEY_UP = 'keyup',
    INPUT = 'input',
    CHANGE = 'change',
    SUBMIT = 'submit',
    FOCUS = 'focus',
    BLUR = 'blur',
    TOUCH_START = 'touchstart',
    TOUCH_END = 'touchend',
    TOUCH_MOVE = 'touchmove',
    TOUCH_CANCEL = 'touchcancel',
}
