// eslint-disable-next-line import/prefer-default-export
export enum EMessageEventName {
    VALUES = 'Values',
    FEATURES = 'Features',
    TUTORIAL = 'Tutorial',
    TRANSFER_TO_VALUES = 'Transfer_to_Values',
    TRANSFER_TO_FEATURES = 'Transfer_to_Features',
    TRANSFER_TO_TUTORIAL = 'Transfer_to_Tutorial',
    PROJECT_IDEA_1 = 'Project_Idea_1',
}
