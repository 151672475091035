import * as enums from '@Application/entities/enums';
import { t } from 'i18next';
import React, { RefObject } from 'react';

export interface TextInputProps {
    State: TextInputState;
    Value?: string | number;
    Placeholder?: string;
    Pattern?: Patterns;
    Size?: number;
    Name?: string;
    Handler?: Function;
    AutoFocus?: Boolean;
    OnFocus?: Function;
    Ref?: RefObject<HTMLInputElement>;
}

export enum TextInputState {
    DISABLED = 'disabled',
    ACTIVE = 'active',
    HIDDEN = 'hidden',
    BLOCKED = 'blocked',
}

export enum TextInputWidth {
    FILL = 'fill',
    HUG = 'hug',
}

export enum Patterns {
    NUMBER = '^[0-9]*$',
}

export class TextInput extends React.Component<
    TextInputProps,
    {
        State: TextInputState;
        placeholder: string;
        Value: string;
        HtmlRef: RefObject<HTMLInputElement>;
        disabled: boolean;
    }
> {
    constructor(props: TextInputProps) {
        super(props);
        this.state = {
            State: props.State,
            placeholder: props.Placeholder ? props.Placeholder : '',
            HtmlRef: this.props.Ref || React.createRef(),
            Value: '',
            disabled: false,
        };
    }

    override componentDidMount(): void {
        if (!this.props.Placeholder && !this.props.Size) {
            this.setState({
                ...this.state,
                placeholder: t(enums.ETranslation.TEXTINPUT_ACTIVE as enums.ETranslation),
            });
        }
        this.props.AutoFocus && this.state.HtmlRef.current?.focus();
    }

    override componentDidUpdate(): void {
        if (this.state.State !== TextInputState.BLOCKED) this.props.AutoFocus && this.state.HtmlRef.current?.focus();
    }

    updateInput(value: string) {
        this.setState({ ...this.state, Value: value });
    }

    handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (this.state.State === TextInputState.BLOCKED) return;
        if (!this.props.Pattern || e.target.value.match(this.props.Pattern)) {
            this.updateInput(e.target.value);
            if (this.props.Handler) this.props.Handler(e.target.value);
        }
    };

    override render() {
        return (
            <input
                type="text"
                className={`textInput ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                size={this.props.Size ? this.props.Size : -1}
                ref={this.state.HtmlRef}
                disabled={this.state.disabled}
                placeholder={this.state.placeholder}
                value={this.state.Value}
                onFocus={() => this.props.OnFocus && this.props.OnFocus()}
                onChange={(e) => this.handleInput(e)}
            />
        );
    }
}
