import * as enums from '@Application/entities/enums'
import { InterfaceManager } from '@Application/services'
import {
    Bubble,
    EBubbleState,
    IconLabelButton,
    Incrementer,
    Label,
    LabelButton,
    Humanizer,
    EButtonState,
} from '../../../../visual/components'
import type {
    BubbleProps,
    IconLabelButtonProps,
    IncrementerProps,
    LabelButtonProps,
    LabelProps,
    HumanizerProps,
} from '../../../../visual/components'
import type {
    ButtonElement,
    IMediaElement,
    IncrementerElement,
    TextElement,
    HumanizerElement,
    MediaElement,
} from '@Entities'

interface IContentElementValue {
    [enums.EElementType.TEXT]: TextElement
    [enums.EElementType.MEDIA]: MediaElement
    [enums.EElementType.BUTTON]: ButtonElement
    [enums.EElementType.HUMANIZER]: HumanizerElement
    [enums.EElementType.INCREMENTER]: IncrementerElement
}

export interface IContentElementComponents {
    [enums.EElementType.TEXT]: typeof Bubble | typeof Label
    [enums.EElementType.MEDIA]: typeof Bubble
    [enums.EElementType.BUTTON]: typeof IconLabelButton | typeof LabelButton
    [enums.EElementType.HUMANIZER]: typeof Humanizer
    [enums.EElementType.INCREMENTER]: typeof Incrementer
}

interface IContentElementProps {
    [enums.EElementType.TEXT]: BubbleProps | LabelProps
    [enums.EElementType.MEDIA]: BubbleProps
    [enums.EElementType.BUTTON]: IconLabelButtonProps | LabelButtonProps
    [enums.EElementType.HUMANIZER]: HumanizerProps & { [key: string]: unknown }
    [enums.EElementType.INCREMENTER]: IncrementerProps
}

export interface IContentElement<T extends enums.EElementType> {
    element: IContentElementValue[T]
    type: T
    component: IContentElementComponents[T]
    props: IContentElementProps[T]
}

export default class ContentElementFactory {
    static interfaceManager = InterfaceManager.getInstance()

    static createBubble(element: TextElement, actor: enums.EActor): IContentElement<enums.EElementType.TEXT> {
        return {
            element,
            component: Bubble,
            type: enums.EElementType.TEXT,
            props: {
                Name: element.id,
                Classname: 'bubble digitalHuman',
                key: element.id,
                Actor: actor,
                State: EBubbleState.ACTIVE,
                Label: element.label,
            } as BubbleProps,
        }
    }

    static createHumanizer(
        element: HumanizerElement,
        defaultValues?: HumanizerProps,
    ): IContentElement<enums.EElementType.HUMANIZER> {
        if (defaultValues) {
            const {
                inputEnabled,
                defaultVoiceStyleCode,
                defaultLanguageCode,
                defaultGenderCode,
                defaultVocalCharacterCode,
                defaultAvailableLanguageQty,
                defaultAvailableGendersQty,
                defaultAvailableVoiceStylesQty,
                defaultAvailableVocalCharactersQty,
                defaultCameraCode,
                defaultContentMode,
                defaultVoiceStyleIntensity,
                defaultFacialEmotionIntensity,
            } = defaultValues

            return {
                element,
                component: Humanizer,
                type: enums.EElementType.HUMANIZER,
                props: {
                    key: element.id,
                    Name: element.id,
                    inputEnabled,
                    defaultVoiceStyleCode,
                    defaultLanguageCode,
                    defaultGenderCode,
                    defaultVocalCharacterCode,
                    defaultAvailableLanguageQty,
                    defaultAvailableGendersQty,
                    defaultAvailableVoiceStylesQty,
                    defaultAvailableVocalCharactersQty,
                    defaultCameraCode,
                    defaultContentMode,
                    defaultVoiceStyleIntensity,
                    defaultFacialEmotionIntensity,
                },
            }
        }
        return {
            element,
            component: Humanizer,
            type: enums.EElementType.HUMANIZER,
            props: {
                key: element.id,
                Name: element.id,
            },
        }
    }

    static createMediaBubble(
        element: MediaElement,
        loadedMedia?: IMediaElement,
        id?: number,
    ): IContentElement<enums.EElementType.MEDIA> {
        return {
            element,
            component: Bubble,
            type: enums.EElementType.MEDIA,
            props: {
                id,
                Media: loadedMedia,
                Classname: 'media bubble digitalHuman',
                type: element.media.mediaType,
                Name: element.id,
                key: element.id,
                Actor: enums.EActor.AVATAR,
                State: EBubbleState.ACTIVE,
                Label: element.label,
                Persist: element.persist,
            } as BubbleProps,
        }
    }

    static createLabel(type: string, element: TextElement): IContentElement<enums.EElementType.TEXT> {
        return {
            element,
            component: Label,
            type: enums.EElementType.TEXT,
            props: {
                Name: type,
                key: `${element.id}_${type}`,
                Text: element.label,
            } as LabelProps,
        }
    }

    static createIncrementer(element: IncrementerElement): IContentElement<enums.EElementType.INCREMENTER> {
        return {
            element,
            component: Incrementer,
            type: enums.EElementType.INCREMENTER,
            props: {
                key: element.id,
                Name: 'editorial',
                Label: element.label,
                DefaultValue: Number(element.defaultValue),
                From: Number(element.from),
                To: Number(element.to),
                Interval: Number(element.interval),
            } as IncrementerProps,
        }
    }

    static createButton(element: ButtonElement, action: () => void): IContentElement<enums.EElementType.BUTTON> {
        const basicProps = {
            key: element.id,
            Name: 'editorial',
            State: this.interfaceManager.inputBlocked ? EButtonState.BLOCKED : EButtonState.DEFAULT,
            LinkProps: element.href ? { Href: element.href, Target: element.target, Name: 'editorial' } : undefined,
            Label: element.label,
            OnClick: () => action(),
        } as LabelButtonProps | IconLabelButtonProps

        if (element.icon) {
            return {
                element,
                component: IconLabelButton,
                type: enums.EElementType.BUTTON,
                props: { ...basicProps, IconVector: element.icon } as IconLabelButtonProps,
            }
        }
        return {
            element,
            component: LabelButton,
            type: enums.EElementType.BUTTON,
            props: { ...basicProps } as LabelButtonProps,
        }
    }

    static createTitle(element: TextElement, contentContainer: string): IContentElement<enums.EElementType.TEXT> {
        return ContentElementFactory.createLabel(`${contentContainer}Title`, element)
    }

    static createSubTitle(element: TextElement, contentContainer: string): IContentElement<enums.EElementType.TEXT> {
        return ContentElementFactory.createLabel(`${contentContainer}Subtitle`, element)
    }
}
