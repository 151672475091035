import { TransformComponent, TransformWrapper, ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { IDimensions } from '@Application/entities';
import * as enums from '@Application/entities/enums';
import PhotoControls from './PhotoControls';
import { InterfaceManager } from '@Application/services';
import { Background } from '../background/Background';

export function MediaGallery({ currentImage, mode, controlsEnabled }) {
    const interfaceManager = InterfaceManager.getInstance();
    const [Zoom, setZoom] = useState<number>(0);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [containerDimensions, setContainerDimensions] = useState<IDimensions>({ width: 0, height: 0 });
    const [digitalHumanState, setDigitalHumanState] = useState<enums.EDigitalHumanState>(
        enums.EDigitalHumanState.MEDIA,
    );
    const handleFullscreen = () => {
        interfaceManager.toggleMediaGalleryFullscreen();
        setZoom(0);
    };
    useEffect(() => {
        const digitalHumanStateHandler = interfaceManager.handleDigitalHumanState((state) => {
            if (
                state.current === enums.EDigitalHumanState.FULLSCREEN_PHOTO ||
                state.current === enums.EDigitalHumanState.MEDIA
            ) {
                setDigitalHumanState(state.current);
            }
        });
        return () => {
            digitalHumanStateHandler.unsubscribe();
        };
    }, []);

    const ref = useRef<ReactZoomPanPinchContentRef>(null);

    const scaleUp = true;
    const zoomFactor = 5;
    function setZoomState(zoom: enums.EZoomAction) {
        switch (zoom) {
            case enums.EZoomAction.INCREASE: {
                console.log('Zooming in');
                setZoom((Zoom) => ~~Zoom + 1);
                break;
            }
            case enums.EZoomAction.DECREASE: {
                console.log('Zooming out');
                setZoom((Zoom) => ~~Zoom - 1);
                break;
            }
            case enums.EZoomAction.RESET: {
                console.log('Resetting zoom');
                setZoom(0);
                break;
            }
        }
    }
    const imageScale = useMemo(() => {
        if (containerDimensions.width === 0 || containerDimensions.height === 0 || !currentImage) return 0;
        if (digitalHumanState === enums.EDigitalHumanState.FULLSCREEN_PHOTO) {
            return Math.max(
                window.innerHeight / currentImage?.naturalDimensions.height,
                window.innerWidth / currentImage?.naturalDimensions.width,
            );
        }
        const scale = Math.min(
            containerDimensions.width / currentImage?.naturalDimensions.width,
            containerDimensions.height / currentImage?.naturalDimensions.height,
        );
        return scaleUp ? scale : Math.max(scale, 1);
    }, [scaleUp, containerDimensions, currentImage, digitalHumanState]);

    const handleResize = useCallback(() => {
        if (container !== null) {
            const rect = container.getBoundingClientRect();
            setContainerDimensions({ width: rect.width, height: rect.height });
        } else {
            setContainerDimensions({ width: 0, height: 0 });
        }
    }, [container]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize, currentImage, mode]);

    useEffect(() => {
        if (ref.current) {
            ref.current.centerView(imageScale, 0, 'easeOut');
        }
    }, [currentImage, containerDimensions, imageScale]);

    const clickHandler = () => {
        if (!interfaceManager.getMediaGalleryState().open) {
            interfaceManager.openMediaGallery(currentImage.source);
        }
    };
    useLayoutEffect(() => {
        const ref2 = document.querySelector('.react-transform-wrapper');
        if (ref2) {
            ref2.addEventListener('click', clickHandler);
        }
        return () => {
            ref2?.removeEventListener('click', clickHandler);
        };
    });
    return (
        <div className="mediaGallery" ref={(el: HTMLDivElement | null) => setContainer(el)}>
            <Background stage={enums.EStage.DIGITAL_HUMAN} />
            <div className="overlay" />
            <TransformWrapper
                initialScale={imageScale}
                minScale={imageScale}
                maxScale={imageScale * zoomFactor}
                centerOnInit={true}
                wheel={{ disabled: true }}
                alignmentAnimation={{ disabled: false, animationType: 'easeOut', sizeX: 0, sizeY: 0 }}
                zoomAnimation={{ disabled: true }}
                panning={{ velocityDisabled: true }}
                doubleClick={{ disabled: true }}
                ref={ref}
            >
                {({ zoomIn, zoomOut }) => (
                    <>
                        {controlsEnabled && (
                            <PhotoControls
                                ZoomIn={zoomIn}
                                SetZoom={setZoomState}
                                ZoomOut={zoomOut}
                                Zoom={Zoom}
                                FullscreenHandler={handleFullscreen}
                            />
                        )}

                        <TransformComponent
                            contentStyle={{
                                cursor: !controlsEnabled ? 'pointer' : 'default',
                            }}
                            wrapperStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {imageScale > 0 && (
                                <img
                                    alt={currentImage.alt || 'gallery content'}
                                    src={`${currentImage.source}`}
                                    width={currentImage.naturalDimensions.width}
                                    height={currentImage.naturalDimensions.height}
                                />
                            )}
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
        </div>
    );
}
