import React from 'react';
import { InputSlider } from '@Components';
import { SettingManager } from '@Services';
import { InputSliderItem } from '@Components/input/inputSlider/InputSlider';

export interface InterfaceSizeProps {
    SettingManager: SettingManager;
}

export interface InterfaceSizeStates {}

export class InterfaceSize extends React.Component<InterfaceSizeProps, InterfaceSizeStates> {
    override render(): React.ReactNode {
        const setInterfaceSize = (value: number) => {
            this.props.SettingManager.interfaceSize = value;
            document.body.parentElement && (document.body.parentElement.style.fontSize = this.props.SettingManager.interfaceSizeStyle)
        }

        const sliderItems = [
            {
                Label: '50%',
                Value: -2,
            },
            {
                Label: '75%',
                Value: -1,
            },
            {
                Label: '100%',
                Value: 0,
            },
            {
                Label: '125%',
                Value: 1,
            },
            {
                Label: '150%',
                Value: 2,
            },
        ]

        sliderItems.forEach((item: InputSliderItem) => {
            item.IsActive = this.props.SettingManager.interfaceSize === item.Value
        });

        return (
            <InputSlider
                Items={sliderItems}
                OnChangeHandler={(activeItem) => {
                    setInterfaceSize(activeItem.Value);
                }}
                OnClickPrevHandler={(activeItem) => {
                    setInterfaceSize(activeItem.Value);
                }}
                OnClickNextHandler={(activeItem) => {
                    setInterfaceSize(activeItem.Value);
                }}
            />
        );
    }
}
