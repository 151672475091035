import * as enums from '@Application/entities/enums'
import { Expose } from 'class-transformer'
import type * as types from '@Entities/interfaces'

export default abstract class AbstractElement implements types.IElement {
    abstract elementType: enums.EElementType
    abstract type: string
    abstract name: string
    @Expose()
    id: string = ''

    @Expose()
    state: enums.EPlayableState = enums.EPlayableState.PENDING

    @Expose()
    isFirst: boolean = false

    @Expose()
    isLast: boolean = false

    @Expose()
    inputEnabled: boolean | undefined = undefined

    @Expose()
    noUserActionDelay: boolean = false

    @Expose()
    poll: types.IPoll = {
        id: undefined,
        question: undefined,
        answer: undefined,
        desc: undefined,
        download: undefined,
    }

    @Expose()
    delay?: number

    @Expose()
    mute?: boolean
}
