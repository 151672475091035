import type { RefObject } from 'react'
import * as enums from '@Application/entities/enums'
// import { AudioMonitorManager } from '@Application/services'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc'
import { filter, fromEvent, identity, Subject } from 'rxjs'
import ConfigManager from './configManager'
import InterfaceManager from './InterfaceManager'
import type * as types from '@Entities/interfaces'
import type { OperatorFunction, Subscription } from 'rxjs'
export default class AudioRecordManager {
    mediaStream?: MediaStream
    private recorder?: RecordRTC
    private dataObservable = new Subject<types.IAudioRecordData>()
    private interfaceManager = InterfaceManager.getInstance()
    private configManager = ConfigManager.getInstance()

    startRecordWhen = (
        ref: RefObject<HTMLElement | Document>,
        eventName: enums.EEventName[],
        pipe: OperatorFunction<Event, Event> = identity,
    ): void => {
        if (!this.interfaceManager.inputBlocked)
            eventName.forEach((e) => this.registerEvent(ref, e, pipe, () => this.startRecording()))
    }

    stopRecordWhen = (
        ref: RefObject<HTMLElement | Document>,
        eventName: enums.EEventName[],
        pipe: OperatorFunction<Event, Event> = identity,
    ): void => {
        if (!this.interfaceManager.inputBlocked)
            eventName.forEach((e) => this.registerEvent(ref, e, pipe, () => this.stopRecording()))
    }

    // Request access to the microphone
    async getDevice(): Promise<MediaStream> {
        if (!this.mediaStream) this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })

        if (!this.mediaStream) throw new Error('No microphone found')

        return this.mediaStream
    }
    public static getInstance(): AudioRecordManager {
        if (!AudioRecordManager.instance) AudioRecordManager.instance = new AudioRecordManager()
        return AudioRecordManager.instance
    }

    private async getRecorder(): Promise<RecordRTC> {
        if (!this.recorder) {
            this.recorder = new RecordRTC((await this.getDevice()).clone(), {
                type: 'audio',
                mimeType: 'audio/webm',
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                disableLogs: true,
            })
            // AudioMonitorManager.startInputMonitor()
        }
        return this.recorder
    }

    private static instance: AudioRecordManager

    onDataGenerated(callback: (nextPendingRecord: types.IAudioRecordData) => void): Subscription {
        return this.dataObservable.subscribe(callback)
    }

    private registerEvent(
        ref: RefObject<HTMLElement | Document>,
        eventName: enums.EEventName,
        pipe: OperatorFunction<Event, Event>,
        callback: () => void,
    ): void {
        if (!ref.current) throw new Error('ref is not defined')

        fromEvent(ref.current, eventName)
            .pipe(pipe)
            .subscribe({
                next: (_event: Event) => {
                    callback()
                },
                error: (err: Error) => console.error(`Observer got an error: ${JSON.stringify(err)}`),
            })
    }

    private startRecording(): void {
        this.getRecorder()
            .then((recorder) => {
                recorder.state !== 'recording' && recorder.startRecording()
            })
            .catch((err) => {
                console.log('Err', 'Could not get recorder', err)
            })
    }

    private async stopRecording(): Promise<{ audio: { type: string; dataURL: string } }> {
        return new Promise((_resolve, _reject) => {
            this.getRecorder()
                .then((r) => {
                    r.stopRecording(() => {
                        this.recorder?.getDataURL((data) => {
                            this.dataObservable.next({
                                audio: {
                                    type: 'audio/wav',
                                    dataURL: data,
                                },
                            })
                            this.recorder?.destroy()
                            this.recorder = undefined
                        })
                    })
                })
                .catch((err) => {
                    console.log('Err', 'Cannot get recorder', err)
                })
        })
    }

    public registerKeyboardEvents(ref: RefObject<HTMLElement | Document>): void {
        // Monitor Keyboard (Space)
        const spaceKeyDownPipe: OperatorFunction<Event, Event> = (event) => {
            return event.pipe(
                filter((e: Event) => {
                    // If it is a keyboard event and space is pressed not repeated
                    const isKeyboardEventAndSpaceNotRepeated =
                        e instanceof KeyboardEvent && e.code === 'Space' && !e.repeat
                    // If it is a keyboard e but is not triggered by a text input
                    const isNotTextInput = !(e.target instanceof HTMLInputElement && e.target.type === 'text')
                    return (
                        isKeyboardEventAndSpaceNotRepeated &&
                        isNotTextInput &&
                        !this.interfaceManager.inputBlocked &&
                        this.interfaceManager.inputEnabled &&
                        this.configManager.config.components.microphone.enabled
                    )
                }),
            )
        }

        this.startRecordWhen(ref, [enums.EEventName.KEY_DOWN], spaceKeyDownPipe)
        this.stopRecordWhen(ref, [enums.EEventName.KEY_UP], spaceKeyDownPipe)
    }

    public registerMouseEvents(ref: RefObject<HTMLElement | Document> | undefined): void {
        if (!ref) throw new Error('ref is not defined')
        const mouseDownPipe: OperatorFunction<Event, Event> = (event) => {
            return event.pipe(
                filter((_event: Event) => {
                    return !this.interfaceManager.inputBlocked
                }),
            )
        }

        this.startRecordWhen(ref, [enums.EEventName.MOUSE_DOWN, enums.EEventName.TOUCH_START], mouseDownPipe)
        this.stopRecordWhen(ref, [enums.EEventName.MOUSE_UP, enums.EEventName.TOUCH_END], mouseDownPipe)
    }
}
