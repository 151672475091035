import * as enums from '@Application/entities/enums'
import type * as types from '@Entities/interfaces'
import { Subject } from 'rxjs'
import ConfigManager from './configManager'

export default class InterfaceManager {
    private configManager = ConfigManager.getInstance()
    private _DigitalHumanStateObservable = new Subject<{
        previous: enums.EDigitalHumanState
        current: enums.EDigitalHumanState
    }>()
    private _StageObservable: Subject<enums.EStage> = new Subject<enums.EStage>()
    private _ModalObservable: Subject<types.IModal> = new Subject<types.IModal>()
    private _ImageGalleryObservable: Subject<types.IImageGallery> = new Subject<types.IImageGallery>()
    private _VideoIndexObservable: Subject<number> = new Subject<number>()
    private _InputBlockedObservable: Subject<boolean> = new Subject<boolean>()
    private _InputEnabledObservable: Subject<boolean> = new Subject<boolean>()
    private _InputBlocked: boolean = false
    private _InputEnabled: boolean = this.configManager.config.features.input.enabled ?? true
    private _VideoGallery: types.IVideoGallery = {
        currentIndex: -1,
        videoCount: 0,
        originalState: enums.EDigitalHumanState.AVATAR,
    }
    private _ImageGallery: types.IImageGallery = {
        open: false,
        media: [],
        currentIndex: -1,
        originalState: enums.EDigitalHumanState.EXTRA_CONTENT,
    }
    private _DigitalHumanState = [enums.EDigitalHumanState.AVATAR]
    private _Stage: enums.EStage = enums.EStage.INITIAL
    private _Modal: types.IModal = { open: false, template: '', closeWithBackground: false }
    private _InputBlockQueued: boolean = false
    handleDigitalHumanState(
        callback: (props: { previous: enums.EDigitalHumanState; current: enums.EDigitalHumanState }) => void,
    ) {
        return this._DigitalHumanStateObservable.subscribe(callback)
    }

    verifyDigitalHumanState = () => {
        this._DigitalHumanStateObservable.next({
            previous: this.getPreviousDigitalHumanState()!,
            current: this.getCurrentDigitalHumanState()!,
        })
    }

    changePreviousDigitalHumanState = (value: enums.EDigitalHumanState) => {
        if (this._DigitalHumanState.length < 2) return
        this._DigitalHumanState[this._DigitalHumanState.length - 2] = value
    }

    galleryHasContent(action: 'prev' | 'next'): boolean {
        return action === 'prev'
            ? this._ImageGallery.currentIndex > 0
            : this._ImageGallery.currentIndex < this._ImageGallery.media.length - 1
    }

    changeGalleryIndex(action: 'prev' | 'next') {
        if (action === 'prev') {
            if (this._ImageGallery.currentIndex > 0) {
                this._ImageGallery.currentIndex--
                this._ImageGalleryObservable.next(this._ImageGallery)
            }
        } else {
            if (this._ImageGallery.currentIndex < this._ImageGallery.media.length - 1) {
                this._ImageGallery.currentIndex++
                this._ImageGalleryObservable.next(this._ImageGallery)
            }
        }
    }

    openMediaGallery = (value: string) => {
        this._ImageGallery = {
            ...this._ImageGallery,
            open: true,
            originalState: this.getCurrentDigitalHumanState(),
            currentIndex: this._ImageGallery.media.findIndex((element) => element.source === value),
        }
        this._ImageGalleryObservable.next(this._ImageGallery)
        this.setDigitalHumanState(enums.EDigitalHumanState.MEDIA)
    }

    toggleMediaGalleryFullscreen = () => {
        let state =
            this._DigitalHumanState[this._DigitalHumanState.length - 1] === enums.EDigitalHumanState.MEDIA
                ? enums.EDigitalHumanState.FULLSCREEN_PHOTO
                : enums.EDigitalHumanState.MEDIA
        this._DigitalHumanStateObservable.next({ previous: this.getPreviousDigitalHumanState()!, current: state })
        this._DigitalHumanState.push(state)
        this._ImageGalleryObservable.next(this._ImageGallery)
    }

    closeMediaGallery = () => {
        this._ImageGallery.open = false
        this._ImageGalleryObservable.next(this._ImageGallery)
        this.setDigitalHumanState(this._ImageGallery.originalState)
    }

    addNewMediaGalleryElement = (element: types.IMediaElement) => {
        if (this._ImageGallery.media.findIndex((mediaElement) => mediaElement.source === element.source) === -1) {
            this._ImageGallery.media.push(element)
            if (this.DigitalHumanState[1] !== enums.EDigitalHumanState.MEDIA) {
                this._ImageGallery.currentIndex = this._ImageGallery.media.length - 1
            }
            this._ImageGalleryObservable.next(this._ImageGallery)
        }
    }

    onStageChanged(callback: (state: enums.EStage) => void) {
        return this._StageObservable.subscribe(callback)
    }

    onInputBlockedChange(callback: (state: boolean) => void) {
        return this._InputBlockedObservable.subscribe(callback)
    }

    onModalChange(callback: (state: types.IModal) => void) {
        return this._ModalObservable.subscribe(callback)
    }

    onGalleryChange(callback: (state: types.IImageGallery) => void) {
        return this._ImageGalleryObservable.subscribe((gallery) => {
            callback(gallery)
        })
    }

    onInputEnabledChange(callback: (state: boolean) => void) {
        return this._InputEnabledObservable.subscribe(callback)
    }

    getPreviousDigitalHumanState(): enums.EDigitalHumanState | undefined {
        if (this._DigitalHumanState.length === 1) return enums.EDigitalHumanState.AVATAR
        return this._DigitalHumanState[this._DigitalHumanState.length - 2]
    }

    getMediaGalleryState(): types.IImageGallery {
        return this._ImageGallery
    }

    getStateStack(): enums.EDigitalHumanState[] {
        return this._DigitalHumanState
    }

    getModalState(): types.IModal {
        return this._Modal
    }

    getCurrentDigitalHumanState(): enums.EDigitalHumanState | undefined {
        return this._DigitalHumanState[this._DigitalHumanState.length - 1]
    }

    get inputEnabled(): boolean {
        return this._InputEnabled
    }

    get inputBlocked(): boolean {
        return this._InputBlocked
    }

    get DigitalHumanState(): enums.EDigitalHumanState[] {
        return this._DigitalHumanState
    }

    get Stage(): enums.EStage {
        return this._Stage
    }

    get Modal(): types.IModal {
        return this._Modal
    }

    set Modal(value: types.IModal) {
        this._Modal = value
        this._ModalObservable.next(value)
    }

    set MediaGallery(value: types.IImageGallery) {
        this._ImageGallery = value
        this._ImageGalleryObservable.next(value)
    }

    set Stage(value: enums.EStage) {
        this._StageObservable.next(value)
        this._Stage = value
    }

    set inputBlockQueued(value: boolean) {
        this._InputBlockQueued = value
    }

    get inputBlockQueued(): boolean {
        return this._InputBlockQueued
    }

    setVideoIndex(value: number) {
        this._VideoGallery = { ...this._VideoGallery, currentIndex: value }
        this._VideoIndexObservable.next(value)
    }

    onVideoIndexChange(callback: (state: number) => void) {
        return this._VideoIndexObservable.subscribe(callback)
    }

    set inputEnabled(value: boolean) {
        this._InputEnabled = value
        this._InputEnabledObservable.next(value)
    }

    addNewVideo = () => {
        this._VideoGallery = { ...this._VideoGallery, videoCount: (this._VideoGallery.videoCount += 1) }
    }

    getVideoCount = () => {
        return this._VideoGallery.videoCount
    }

    setModal = (value: types.IModal) => {
        this._Modal = value
        this._ModalObservable.next(value)
    }

    set inputBlocked(value: boolean) {
        this._InputBlocked = value
        this._InputBlockedObservable.next(value)
    }

    setDigitalHumanState = (value: enums.EDigitalHumanState) => {
        this._DigitalHumanStateObservable.next({
            previous: this._DigitalHumanState[this._DigitalHumanState.length - 1]!,
            current: value,
        })
        this._DigitalHumanState.push(value)
    }

    setMedia = (value: string) => {
        this._ImageGallery.currentIndex = this._ImageGallery.media.findIndex((element) => element.source === value)
        this._ImageGalleryObservable.next(this._ImageGallery)
        if (this.getCurrentDigitalHumanState() !== enums.EDigitalHumanState.MEDIA) {
            this.setDigitalHumanState(enums.EDigitalHumanState.MEDIA)
        }
    }

    private static instance: InterfaceManager

    public static getInstance(): InterfaceManager {
        if (!InterfaceManager.instance) InterfaceManager.instance = new InterfaceManager()
        return InterfaceManager.instance
    }

    private constructor() {}
}
