import React, { MouseEventHandler } from 'react';
import { EButtonState, Group, GroupAlignment, IconVector } from '@Components';
import IconLabelButton from '../../button/editorialButton/iconLabelButton/IconLabelButton';
import LabelButton from '../../button/editorialButton/labelButton/LabelButton';
import I18n from '@Translation';
import * as enums from '@Application/entities/enums';

export interface ButtonProps {
    Icon?: IconVector;
    Label: string;
    IsActive?: boolean;
    OnClick?: MouseEventHandler;
}

export interface LanguageGroupProps {
    Name?: string;
    className?: string;
    Languages: ButtonProps[];
}

export interface LanguageGroupState {
    Languages: LanguageGroupProps['Languages'];
    currentLanguage: string;
}

export class LanguageGroup extends React.Component<LanguageGroupProps, LanguageGroupState, ButtonProps> {
    constructor(props: { Languages: { IsActive: boolean; Label: keyof typeof enums.ELanguages }[] }) {
        super(props);
        this.state = {
            Languages: props.Languages?.map((language) => {
                language.IsActive = enums.ELanguages[language.Label] === I18n.language;
                return language;
            }),
            currentLanguage: I18n.language,
        };

        I18n.on('languageChanged', (newLanguage) => {
            this.setState({
                Languages: this.state.Languages.map((language) => {
                    language.IsActive = enums.ELanguages[language.Label] === newLanguage;
                    return language;
                }),
                currentLanguage: newLanguage,
            });
        });
    }

    override render(): React.ReactNode {
        return (
            this.state.Languages && (
                <Group
                    key={this.state.currentLanguage}
                    alignment={GroupAlignment.right}
                    expands={true}
                    hasMargins={false}
                    isWrapped={false}
                    className={'languageGroup' + `${this.props.className ? ' ' + this.props.className : ''}`}
                    HtmlRef={React.createRef<HTMLInputElement>()}
                >
                    {this.state.Languages.map((item: ButtonProps) =>
                        item.Icon ? (
                            <IconLabelButton
                                State={item.IsActive ? EButtonState.ACTIVE : EButtonState.DEFAULT}
                                IconVector={item.Icon}
                                Label={item.Label}
                                key={item.Label}
                                OnClick={item.OnClick}
                            />
                        ) : (
                            <LabelButton
                                State={item.IsActive ? EButtonState.ACTIVE : EButtonState.DEFAULT}
                                key={item.Label}
                                Label={item.Label}
                                OnClick={item.OnClick}
                            />
                        ),
                    )}
                </Group>
            )
        );
    }
}
