import React from 'react';
import { IconButton, IconVector } from '../index';

export interface DropdownProps {
    Icon: IconVector;
    IconName?: string;
    Name?: string;
    State?: DropdownState;
    Alignment?: DropdownAlignment;
    OnClickBeforeOpen?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
    OnClickAfterOpen?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
    children?: any;
}

export interface DropdownStates {
    State: DropdownState;
}

export enum DropdownState {
    OPEN = 'open',
    CLOSE = 'close',
}

export enum DropdownAlignment {
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
}

export class Dropdown extends React.Component<DropdownProps, DropdownStates> {
    constructor(props: DropdownProps) {
        super(props);
        this.state = {
            State: this.props.State || DropdownState.CLOSE,
        };
    }

    changeState = (state: DropdownState) => {
        this.setState({
            State: state,
        });
    };

    stateToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.OnClickBeforeOpen && this.props.OnClickBeforeOpen(e);
        this.changeState(this.state.State === DropdownState.OPEN ? DropdownState.CLOSE : DropdownState.OPEN);
        this.props.OnClickAfterOpen && this.props.OnClickAfterOpen(e);
    };

    override render(): React.ReactNode {
        const isOpened = this.state.State === DropdownState.OPEN;
        const opened = isOpened ? '' : undefined;
        const alignment = this.props.Alignment || DropdownAlignment.BOTTOM_RIGHT;
        const name = this.props.Name || '';

        return (
            <>
                <div className={`dropdown ${name}`} data-opened={opened} data-alignment={alignment}>
                    <div className={`dropdown-trigger`} onClick={(e) => this.stateToggle(e)}>
                        <IconButton IconVector={this.props.Icon} Name={this.props.IconName || undefined} />
                    </div>
                    <div className={`dropdown-container`}>{this.props.children}</div>
                </div>
                {isOpened && <div className={`dropdown-layer`} onClick={() => this.changeState(DropdownState.CLOSE)} />}
            </>
        );
    }
}
