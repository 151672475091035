import * as enums from '@Application/entities/enums'
import { DigitalHumanConfig } from '@Entities/models/digitalHumanConfig'
import { plainToInstance } from 'class-transformer'

export default class ConfigManager {
    public config: DigitalHumanConfig

    private constructor() {
        const loadFrom = enums.EConfigFormat.FILE
        this.config = this.load(loadFrom)

        console.info(`Config Loaded from ${loadFrom}`, [
            {
                ...this.config.application,
                ...this.config.backend,
                ...this.config.content,
            },
        ])
    }
    public static getInstance(): ConfigManager {
        if (!ConfigManager.instance) ConfigManager.instance = new ConfigManager()
        return ConfigManager.instance
    }
    // Singleton
    private static instance: ConfigManager

    public load(format: enums.EConfigFormat): DigitalHumanConfig {
        switch (format) {
            case enums.EConfigFormat.ENVIRONMENT:
                return this.loadFromEnvironment()
            case enums.EConfigFormat.FILE:
                return this.loadFromFile()
            default:
                throw new Error('Config not found!')
        }
    }

    private loadFromEnvironment(): DigitalHumanConfig {
        console.info('Loading config from environment')
        return new DigitalHumanConfig()
    }

    private loadFromFile(): DigitalHumanConfig {
        const configPath = `${process.env.PUBLIC_URL}/config.json`
        const xhr = new XMLHttpRequest()

        // The third parameter 'false' makes the request synchronous.
        // It was forced sync mode to block the application, since config is crucial for the application.
        xhr.open('GET', configPath, false)
        xhr.send()
        return plainToInstance(DigitalHumanConfig, JSON.parse(xhr.responseText))
    }
}
