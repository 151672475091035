import { Highlight, LinkContainer, LinkProps, Icon, IconVector, Label, EButtonState } from '@Components';
import * as enums from '@Application/entities/enums';
import React, { MouseEventHandler } from 'react';

export interface IconLabelButtonProps {
    Name?: string;
    State: EButtonState;
    IconVector: IconVector | string;
    Label: enums.ETranslation | string;
    LinkProps?: LinkProps;
    OnClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface IconLabelButtonStates {
    PreviousState: EButtonState;
    State: EButtonState;
    Highlighted: boolean;
    LinkProps?: LinkProps;
    Ref: React.RefObject<HTMLButtonElement>;
}

export default class IconLabelButton extends React.Component<IconLabelButtonProps, IconLabelButtonStates> {
    constructor(props: IconLabelButtonProps) {
        super(props);
        this.state = {
            PreviousState: this.props.State,
            State: this.props.State,
            Highlighted: false,
            Ref: React.createRef(),
        };
    }

    override componentDidMount() {
        this.setState({
            PreviousState: EButtonState.DEFAULT,
            State: this.props.State,
        });
    }

    override componentDidUpdate(prevProps: Readonly<IconLabelButtonProps>) {
        if (this.props !== prevProps) {
            this.setState({
                PreviousState: this.props.State,
                State: this.props.State,
            });
        }
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            PreviousState: this.state.State,
            State: EButtonState.HOVER,
        });
    };

    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.state.State === EButtonState.BLOCKED) return;
        this.setState({
            State: this.state.PreviousState,
        });
    };

    override render() {
        return (
            <Highlight
                Highlighted={this.state.Highlighted}
                Name="box"
                Child={
                    <LinkContainer
                        Name={'LabelButtonLink'}
                        Href={this.state.LinkProps?.Href}
                        Target={this.state.LinkProps?.Target}
                        Child={
                            <button
                                ref={this.state.Ref}
                                className={`iconLabelButton ${this.state.State} basic ${
                                    this.props.Name ? this.props.Name : ''
                                }`}
                                onClick={this.props.OnClick}
                                onMouseEnter={this.onHover}
                                onMouseLeave={this.onLeave}
                                disabled={this.state.State === EButtonState.BLOCKED}
                            >
                                <div className="container">
                                    <Label Name={`${this.props.Name ? this.props.Name : ''}`} Text={this.props.Label} />
                                    <div className="asset">
                                        <Icon vector={this.props.IconVector} />
                                    </div>
                                </div>
                            </button>
                        }
                    />
                }
            />
        );
    }
}
