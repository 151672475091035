import { Label } from '@Components';
import React from 'react';

export interface CaptionProps {
    State: CaptionState;
    AvatarMessage: string;
    CaptionlessLayout?: boolean;
    Name?: string;
}

export enum CaptionState {
    DISABLED = 'disabled',
    TRANSPARENT = 'translucent',
    SOLID = 'solid',
    ACCESSIBILITY = 'accessibility',
    OFF = 'off',
}

export class Caption extends React.Component<CaptionProps, {}> {
    override render(): React.ReactNode {
        if (this.props.State === CaptionState.DISABLED || !this.props.AvatarMessage || this.props.CaptionlessLayout)
            return null;

        return (
            <div className={`caption ${this.props.Name || ''}`} data-mode={`${this.props.State}`}>
                <div className={`caption-text`}>
                    <Label Text={this.props.AvatarMessage} />
                </div>
            </div>
        );
    }
}
