import { useEffect, useRef, useState } from 'react'
// import * as enums from '@Application/entities/enums'
// import { Highlight } from '../highlight/Highlight'
import VideoPlayer from '../videoPlayer/VideoPlayer'
import { InterfaceManager } from '@Application/services'
import { IMediaElement } from '@Application/entities'

export interface MediaPreviewProps {
    Media: IMediaElement
    Highlighted?: boolean
    id?: number
}

function MediaPreview({ Media: { source, naturalDimensions, orientation, type }, ...props }: MediaPreviewProps) {
    const interfaceManager = InterfaceManager.getInstance()
    const mediaReference = useRef<any>()
    // const [highlighted, setHighlighted] = useState(props.Highlighted || false)
    const [extreme, setExtreme] = useState(false)
    useEffect(() => {
        const ratio = naturalDimensions!.width / naturalDimensions!.height
        const height = mediaReference.current.offsetWidth / ratio
        const currentFontSize = window.getComputedStyle(mediaReference.current).getPropertyValue('font-size')
        setExtreme(height < 2 * parseInt(currentFontSize))
    }, [naturalDimensions])

    // useEffect(() => {
    //     if (props.Highlighted !== undefined) {
    //         setHighlighted(props.Highlighted)
    //     }
    // }, [props.Highlighted])
    return (
        <div className={`mediaPreview ${type} ${orientation} ${extreme ? 'extreme' : ''}`} ref={mediaReference}>
            {/* {type === enums.EMediaType.ICON ? (
                <Highlight
                    Name="box"
                    Highlighted={highlighted}
                    Child={
                        <img
                            src={source}
                            alt={`Media ${type}`}
                            onClick={() => {
                                interfaceManager.openMediaGallery(source)
                            }}
                        />
                    }
                />
            ) :  */}
            {props.id !== undefined ? (
                <VideoPlayer source={source} id={props.id} orientation={orientation} />
            ) : (
                <img
                    src={source}
                    alt={`Media ${type}`}
                    onClick={() => {
                        interfaceManager.openMediaGallery(source)
                    }}
                />
            )}
        </div>
    )
}

export default MediaPreview
