import { EButtonState } from '../../button/buttonState';
import { languages } from '../data/constants';
import { Compatibility } from './compatibility';

export class Languages {
    public getAll(): Array<Language> {
        const arrayOfLanguages: Array<Language> = Object.keys(languages)
            .map((code: string) => {
                const label: string = languages[code].label;
                const hasFemale: boolean =
                    languages[code].hasOwnProperty('femaleVoices') && languages[code]['femaleVoices'].length > 0;
                const hasFemaleChild: boolean =
                    languages[code].hasOwnProperty('femaleChildVoices') &&
                    languages[code]['femaleChildVoices'].length > 0;
                const hasMale: boolean =
                    languages[code].hasOwnProperty('maleVoices') && languages[code]['maleVoices'].length > 0;
                const hasNeutral: boolean =
                    languages[code].hasOwnProperty('neutralVoices') && languages[code]['neutralVoices'].length > 0;
                return new Language(code, label, hasFemale, hasFemaleChild, hasMale, hasNeutral);
            })
            .sort((a: Language, b: Language) => a.label.localeCompare(b.label));
        return arrayOfLanguages;
    }

    public getDefaultLanguage(defaultLanguageCode: string): Language | undefined {
        return this.getAll().filter((language: Language) => language.code === defaultLanguageCode)[0];
    }

    public quantity(): number {
        return this.getAll().length;
    }
}

export class Language {
    public code: string;
    public label: string;
    public female: boolean;
    public male: boolean;
    public neutral: boolean;
    public femaleChild: boolean;
    public state: EButtonState;
    public currentCompatibility: Compatibility;

    constructor(
        languageCode: string,
        languageLabel: string,
        female: boolean,
        femaleChild: boolean,
        male: boolean,
        neutral: boolean,
        state: EButtonState = EButtonState.DEFAULT,
        currentCompatibility: Compatibility = new Compatibility(),
    ) {
        this.code = languageCode;
        this.label = languageLabel;
        this.female = female;
        this.male = male;
        this.femaleChild = femaleChild;
        this.neutral = neutral;
        this.state = state;
        this.currentCompatibility = currentCompatibility;
    }
}
